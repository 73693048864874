import React from 'react'

//styles
import './SingleCardInformation.css'
import Spiner from '../Spiner/Spiner'

interface Props {
    title: string,
    value: string| number,
    subValue : string| number,
    style?: React.CSSProperties
    loading?: boolean
    elementClass?:string
}

const DoubleCardInformation = ({title, value, style, loading, subValue, elementClass}:Props) => {
    
  return (
    <div className={'SingeCardInformation ' + (elementClass && elementClass)} style={style}>
        <span className='SingeCardInformation-title'>{title}</span>

        <Spiner minHeight={15} loading ={loading}>
            <div className='doubleCardInformation-value'>
                <span className='SingeCardInformation-value'>{value}</span>
                <span className='SingeCardInformation-value'>{subValue}</span>
            </div>

          
        </Spiner>
    </div>
  )
}

export default DoubleCardInformation