import { Steps } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import CreateAddSensorQty from '../CreateAddSensorQty/CreateAddSensorQty';
import { Sensor } from '../../Models/Sensor';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import { getApikeysReseller, getAvailableSensors, postOrganization } from '../../Api/Reseller';
import CreateOrganization from './CreateOrganization';
import { Apikey } from '../../Models/Apikey';

//transalate
import { useTranslation, Trans } from 'react-i18next';

export type FieldType = {
  nombre?: string;
  descripcion?: number;
  token?:string;
  cantidad_sensores?:number;
  correo?:string
};

interface Props{
  step?:number
  next?: () => void,
  prev?: () => void,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>

}


const CreateClientComponent = ({step, next, prev, setOpen}:Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [organization, setOrganization] = React.useState<FieldType>({});
  const [sensors, setSensors] = React.useState<Sensor[]>([]);
  const [apikeys, setApikeys] = React.useState<Apikey[]>([]);
  const [selectedSensors, setSelectedSensors] = React.useState<Sensor[]>([]);
  const [messageResponse, setMessageResponse] = React.useState<string>("");

  const [correo, setCorreo] = React.useState<string>("");

  const { t } = useTranslation();

  const {sendError} = useContext(PromiseSesionContext);




  const onFinishClient = (values: any) => {
    setOrganization(values)
    setCorreo(values?.correo)
    next!() 
  };

  const onFinishSensors = async(values: any) => {
    try{
      setLoading(true)
      setSelectedSensors(values)
      let token = organization.token || ""
      delete organization.token 
      const res = await postOrganization(organization, values.map((sensor:Sensor)=>{return sensor.numero_de_serie}), token, correo)
      setMessageResponse(res.message)
      next!()
      setLoading(false)

    }
    catch(err:any){
      sendError({type: "ERROR", data:{message:err?.response?.data?.message || t('organizacion.errorCrear')}})
      prev!()
      setLoading(false)
    }
  };

  const back = () => {
    prev!()
  };

  const getData = async () => {
    try{
      setLoading(true)
      const res = await getAvailableSensors()
      const res2 = await getApikeysReseller()

      
      if(res && res2) setLoading(false)
      setSensors(res)
      setApikeys(res2)
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('basico.errorRegistros')}})
      setOpen!(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <div>
      <Spiner loading={loading}>

        {step===0 ? <CreateOrganization apikeys={apikeys} defaultValues={organization} onFinishMaster={onFinishClient}></CreateOrganization> : step===1 ? <CreateAddSensorQty notRequiredSensors  maxSensors={organization.cantidad_sensores || undefined} sensors={sensors} selectedSensors={selectedSensors} onFinishMaster={onFinishSensors} back={back} /> : <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{setOpen!(false)}} />} 
      </Spiner>
    </div>
  )
}

export default CreateClientComponent