import { stringToDate } from "../../Functions/date";
import { DataGraph, SensorData } from "../../Models/DataGraph";

export const BarToolbox = {
    feature: {
      saveAsImage: {
        name: "Chart",
        show: true,
        title: "Save as Image",
        // name: 'Results Over Time', // need to set dynamic
        type: "jpeg",
        backgroundColor: "#FFFFFF",
        pixelRatio: 2
      },
      dataZoom: {
        show: true,
        title: {
          zoom: "Zoom",
          back: "Restore Zoom"
        }
      },
      dataView: {
        show: true,
        title: "View Data",
        readOnly: true,
        lang: ["View Data", "Close", "Refresh"]
      },
      magicType: {
        show: true,
        title: {
          line: "Line Chart",
          bar: "Bar Chart",
          tiled: "Tiled Chart"
        },
        type: ["line", "bar", "tiled"]
      },
      restore: { show: true, title: "Restore" }
    }
  };
  
  export const PieToolbox = {
    feature: {
      saveAsImage: {
        name: "Chart",
        show: true,
        title: "Save as Image",
        // name: 'Share of Topics', // need to set dynamic
        type: "jpeg",
        backgroundColor: "#FFFFFF",
        pixelRatio: 2
      },
      dataView: {
        show: true,
        readOnly: true,
        title: "View Data",
        lang: ["View Data", "Close", "Refresh"]
      },
      restore: { show: true, title: "Restore" }
    }
  };
  
  export function getBarOptions(data:DataGraph, labels:any, max?:number, min?:number) {
    let sensorData = data.sensorData
    const temperatures = sensorData.map((item: SensorData) => item.temperature);
    const maxTemperature = Math.round(Math.max(...temperatures));
    const minTemperature = Math.round(Math.min(...temperatures));
    return {
        
      tooltip: {
        show: true
      },
    //   toolbox: BarToolbox,
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: sensorData.map((item:SensorData) => stringToDate(item.timestamp)),
        axisLine: { onZero: false }
      },
      yAxis: {
        type: "value",
        // min: 5,
        // max: 10,
        min: (min|| min===0) ? (min - 20) > minTemperature ? minTemperature : (min - 20) : -40,
        max: (max|| max===0) ? (max + 20) < maxTemperature ? maxTemperature : (max + 20) : 50,
        axisLabel: {
            formatter: '{value} °C'
        }
      },
    //   series: data,
    dataZoom: [
      {
        type: 'slider',
        xAxisIndex: 0,
        filterMode: 'none'
      },
      {
        type: 'slider',
        yAxisIndex: 0,
        filterMode: 'none'
      },
      {
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none'
      },
      {
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none'
      }
    ],
    series: [
        {
            type: 'line',
            data: sensorData.map(item => item.temperature),
            markArea: {
                silent: true,
                data: [
                    [
                        {
                            yAxis: (min|| min===0) ? min - 0.01 : 0,
                            itemStyle: {
                                color: 'rgba(0, 0, 255, 0.1)' // Color para el rango por debajo del ideal
                            }
                        },
                        {
                            yAxis: (min|| min===0) ? (min - 20 < minTemperature ? min - 20 : minTemperature) : 0
                        }
                    ],
                    [
                        {
                            yAxis: (max|| max===0) ,

                            itemStyle: {
                                color: 'rgba(0, 255, 0, 0.1)' // Color para el rango ideal
                            }
                        },
                        {
                            yAxis: (min|| min===0)
                        }
                    ],
                    [
                        {
                            yAxis: (max|| max===0) ? max + 0.01 : 0,

                            itemStyle: {
                                color: 'rgba(255, 0, 0, 0.1)' // Color para el rango por encima del ideal
                            }
                        },
                        {
                            yAxis: (max|| max===0) ? (max + 20 > maxTemperature ? max +20 : maxTemperature +5) :0

                        }
                    ]
                ]
            }
        }
    ],
      legend: {
        y: "bottom",
        show: true
      }
    };
  }
  
  export function getPieOptions(data:any) {
    return {
      tooltip: {
        show: true
      },
      toolbox: PieToolbox,
      series: {
        type: "pie",
        data: data,
        label: {
          position: "outer",
          alignTo: "none",
          bleedMargin: 5
        },
        top: "10%",
        bottom: "10%"
      },
      legend: {
        top: "bottom",
        show: true
      }
    };
  }
  
  export const WordCloudOptions = {
    type: "wordCloud",
    shape: "circle",
    sizeRange: [12, 35],
    rotationRange: [0, 0],
    width: "100%",
    height: "100%",
    top: "10%",
    bottom: "10%",
    drawOutOfBound: false,
    gridSize: 8,
    textStyle: {
      normal: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // Color can be a callback function or a color string
        color: function () {
          // Random color
          return (
            "rgb(" +
            [
              Math.round(Math.random() * 160),
              Math.round(Math.random() * 160),
              Math.round(Math.random() * 160)
            ].join(",") +
            ")"
          );
        }
      },
      emphasis: {
        shadowBlur: 1,
        shadowColor: "#333"
      }
    }
  };
  