import { Modal } from 'antd'
import React, { useState } from 'react'

//styles
import './ModalContent.css'

//models
import { MenuItem } from '../../Models/MenuItem'
import ModalContentStepHeader from './ModalContentStepHeader'


interface Props{
    menuItem:MenuItem
    open:boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    isNotModal?:boolean,
    record?: any

  }

const ModalContent = ({menuItem, open, setOpen, isNotModal, record}:Props) => {
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  

  const next = () => {
    setStep(step + 1);
  };

  const prev = () => {
    setStep(step===0 ? step : step!-1);
  };

  const componentChild = React.cloneElement(menuItem.component, {step: step, setStep: setStep, loading: loading, setLoading: setLoading, setOpen: setOpen, next: next, prev: prev, record: record}) 

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
    {isNotModal ? 
      <> 
      {(menuItem.type=="MULTICONTENT" || menuItem.type=="MULTILABEL") && <ModalContentStepHeader step={step} setStep={setStep} items={menuItem.items} />}
      {/* <HeaderMenu onClose={handleCancel} title={""}  /> */}
      <div className='ModalContent-body'>
        {componentChild}
      </div>
      </>:
      
      <Modal destroyOnClose={true} afterClose={()=>{setStep(0); menuItem.onConfirm()}} className='ModalContent' width={600} onCancel={handleCancel}  footer={false}  open={open}>
      {(menuItem.type=="MULTICONTENT" || menuItem.type=="MULTILABEL") && <ModalContentStepHeader step={step} setStep={setStep} items={menuItem.items} />}
      {/* <HeaderMenu onClose={handleCancel} title={""}  /> */}
      <div className='ModalContent-body'>
        {componentChild}
      </div>
    </Modal>}

    </>

  )
}

export default ModalContent