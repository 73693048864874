

export const ENRESELLER = {
    revendedor: {
        titulo:"Reseller management",
        noRevendedores:"There are no resellers",
        crearTab1:"Information",
        crearTab2:"Sensors",
        crearTab3:"Detail",
        crear:"Create Reseller",
        errorCarga:"Error loading resellers",
        numeroSerie:"Serial number",

        errorCargaSensores:"Error loading sensors",
        errorCrear:"Error creating reseller",

        placeHolderNombre:"Reseller name", 
        placeHolderDescripcion:"Reseller Description", 
        placeHolderCantidad:"Amount", 
        placeHolderCorreo:"example@dominio.com", 

      }
}

export const ESRESELLER = {
    revendedor: {
        titulo:"Gestión de resellers",
        noRevendedores:"No hay revendedores",
        crearTab1:"Información",
        crearTab2:"Sensores",
        crearTab3:"Detalle",
        crear:"Crear Revendedor",
        errorCarga:"Error al cargar los revendedores",
        numeroSerie:"Numero de serie",

        errorCargaSensores:"Error al cargar los sensores",
        errorCrear:"Error al crear el revendedor",

        placeHolderNombre:"Nombre del revendedor", 
        placeHolderDescripcion:"Descripción del revendedor", 
        placeHolderCantidad:"Cantidad", 
        placeHolderCorreo:"ejemplo@dominio.com", 

      }
}