

export const ENBASIC = {
    basico: {
        crear:"Create",
        siguiente:"Next",
        asignar:"Assign",
        sensores:"Sensors",
        sensor:"Sensor",
        revendedor:"Reseller",
        estatus:"Status",
        detalle:"Detail",
        cargar:"Upload",
        agregar:"Add",
        enviar:"Send",
        acciones:"Actions", 
        maximoSensores:"Maximum number of sensors",
        serial:"Serial",
        asignado:"Assigned",
        sinAsignar:"Unassigned",
        todos:"All",
        nombre:"Name",
        nombres:"Names",
        subir:"Upload",
        fechaVencimiento:"Expiration date",
        token:"Token",
        apikey:"Apikey",
        buscar:"Search",
        vencimiento:"Expiration",
        informacion:"Information",
        nombreError:"You must enter a name",
        descripcion:"Description",
        descripcionError:"You must enter a Description",
        correo:"Email",
        correoError:"You must enter an email",
        numeroSensores:"Number of sensores",
        cargarArchivo:"Upload a file",
        solicitudEnviada:"Request successfully submitted",
        mensajeArchivo1:"Click or drop a file in the area",
        mensajeArchivo2:"Supports only one file and it must be .json",
        buscarPorRevendedor:"Search by reseller",
        gestionPerfil:"Profile management",
        perfil:"Profile",
        guardar:"Save",
        apellidos:"Last names",
        apellido:"Last name",
        usuario:"User",
        clave:"Password",
        usuarios:"Users",
        notificaciones:"Notifications",
        gestionUsuarios:"User management",
        tipo:"Type",
        administrador:"Administration",
        organizacion:"Organization",
        organizaciones:"Organizations",
        servicio:"Service",
        unica:"Only",
        sensoresAsignados:"Assigned sensors",
        sensoresNoAsignados:"Unassigned sensors",
        asignarSensores:"Assigned sensors",
        asignarSensor:"Assign sensors",
        actualizacionCorrecta:"Successful update",
        cargarSensores:"Load sensors",     
        cantidad:"Quantity",
        removerSensor:"Remove sensor",
        remover:"Remove",
        aceptar:"Accept",
        cancelar:"Cancel",
        regresar:"Go back",
        bateria:"Batery",
        ubicacion:"Location",
        sucursal:"Subsidiary",
        direccion:"Direction",
        errorDireccion:"You must enter a location",
        modo:"Mode",
        fijo:"Fixed",
        movil:"Mobile",
        quitarSensor:"Remove sensor",
        seleccionarSensor:"Select sensor",
        configuracion:"Configuration",
        historico:"Historical",    
        minutos:"Minutes",  
        minuto:"Minute",  
        inicio:"Beginning",
        fin:"End",
        nombreTarea:"Task name",
        tareaCompletada:"Task complete",
        activa:"Active",
        alarma:"Alarm",
        alarmas:"Alarm",
        conAlarma:"Alarmed",
        sinAlarma:"Without alarm",
        fechaInicio:"Start date",
        generarReporte:"Generate report",
        reporteSensor:"Sensor Report",
        verReporte:"See report",
        descargarCSV:"Download CSV",
        descargarPDF:"Download PDF",
        reporte:"Report",
        fecha:"Date",
        temperatura:"Temperature",
        modelo:"Model",
        observaciones:"Observations",
        firmaRevisor:"reviewer's signature",
        pagina:"Page",
        reporteGenerado:"Report generated on",
        activos:"Asset",
        verDetalle:"See more",
        grafico:"Graph",
        asignados:"Assigned",
        titulo:"Title",
        borrar:"Delete",
        cerrar:"Close",


        seleccionRevendedor:"Select a reseller",
        
        max20:"Maximum 20 chapters",
        max30:"Maximum 30 chapters",
        max1:"the value must be greater than or equal to 1",
        max50:"Maximum 50 chapters",
        max40:"Maximum 40 chapters",
        formato:"It must be of the indicated format",

        errorCantidad:"You must enter an amount",
        errorCargaSensores:"Error loading sensors",
        errorArchivo:"Error uploading file",
        errorSolicitud:"Error creating request",
        errorArchivoJson:"Only .json files are supported",
        errorFormArchivo:"You must upload a file",
        errorApikey:"You must write an Apikey",
        errorFecha:"You must select a date",
        errorNombre:"You must enter a name",
        errorApellidos:"You must write your last name",
        errorActualizarDatos:"Error updating your data",
        errorObtenerDatos:"Error getting data",
        errorUsuarios:"Error getting users",
        errorRegistros:"Error loading logs",
        errorAsignar:"Error assigning sensors",
        errorOrganizaciones:"Error getting organizations",
        errorTransaccion:"Error when completing the transaction",
        errorModo:"Select a mode",
        errorCrearTarea:"Error creating task",
        errorSensor:"Select a sensor",
        errorAssetLocation:"the asset has no records",
        
        
        mensajeEliminarSensor:"Are you sure you want to remove the sensor?",
  
        noOrganizaciones:"There are no organizations",


        placeHolderNombreBuscar:"Search by name",
        placeHolderApikey:"ionAjfmnSnvXXZEfgDG",
        placeHolderFecha:"Due date",
        placeHolderToken:"Select a token",

        telefono:"Phone",
        max12:"Maximum 12 characters",
        errorTelefono:"You must enter a phone number",
        errorUsuario:"You must enter a username",
        errorClave:"You must enter a password",

        archivo:"File",
        errorPrefijo:"You must enter a prefix",
        finalizar:"Complete",
        eliminar:"Delete",
        correos:"Emails",
        segundos:"Seconds",
        icono:"Icon",
        telefonos:"Phone",
        visto:"seen in",
        seconds:"Seconds",
        sensors:"Sensors",
        rol:"Rol",
        errorRol:"You must enter a rol",
        recuperarContrasena:"Recover password",
        recuperarCorreo:"You have been recived an email to reset your password. ",
        correctoCambio:"Cambio de contrasena realizado"


      }
}

export const ESBASIC = {
    basico: {
        crear:"Crear",
        siguiente:"Siguiente",
        asignar:"Asignar",
        sensores:"Sensores",
        sensor:"Sensor",
        revendedor:"Revendedor",
        estatus:"Estatus",
        detalle:"Detalle",
        cargar:"Subir",
        agregar:"Agregar",
        enviar:"Enviar",
        acciones:"Acciones", 
        maximoSensores:"Maximo de sensores",
        serial:"Serial",
        asignado:"Asignado",
        sinAsignar:"Sin asignar",
        todos:"Todos",
        nombre:"Nombre",
        nombres:"Nombres",
        subir:"Cargar",
        fechaVencimiento:"Fecha de vencimiento",
        token:"Token",
        apikey:"Apikey",
        buscar:"Buscar",
        vencimiento:"Vencimiento",
        informacion:"Información",
        nombreError:"Debe ingresar un nombre",
        descripcion:"Descripción",
        descripcionError:"Debe escribir una descripción",
        correo:"Correo",
        correoError:"Debe ingresar un correo",
        numeroSensores:"Número de sensores",
        cargarArchivo:"Cargar archivo",
        solicitudEnviada:"Solicitud enviada correctamente",
        mensajeArchivo1:"Haga click o suelte un archivo en el área",
        mensajeArchivo2:"Soporta un único archivo y debe ser .csv",
        buscarPorRevendedor:"Buscar por revendedor",
        gestionPerfil:"Gestión del perfil",
        perfil:"Perfil",
        guardar:"Guardar",
        apellidos:"Apellidos",
        apellido:"Apellido",
        usuario:"Usuario",
        clave:"Contrasena",
        usuarios:"Usuarios",
        notificaciones:"Notificaciones",
        gestionUsuarios:"Gestión de usuarios",
        tipo:"Tipo",
        administrador:"Administración",
        organizacion:"Organización",
        organizaciones:"Organizaciones",
        servicio:"Servicio",
        unica:"Unica",
        sensoresAsignados:"Sensores asignados",
        sensoresNoAsignados:"Sensores sin asignar",
        asignarSensores:"Asignar sensores",
        asignarSensor:"Asignar sensor",
        actualizacionCorrecta:"Actualización realizada correctamente",
        cargarSensores:"Cargar sensores",     
        cantidad:"Cantidad",
        removerSensor:"Remover sensor",
        remover:"Remover",
        aceptar:"Aceptar",
        cancelar:"Cancelar",
        regresar:"Regresar",
        bateria:"Bateria",
        ubicacion:"Ubicación",
        sucursal:"Sucursal",
        direccion:"Dirección",
        errorDireccion:"Debe escribir dirección",
        modo:"Modo",
        fijo:"Fijo",
        movil:"Movil",
        quitarSensor:"Quitar sensor",
        seleccionarSensor:"Seleccionar sensor",
        configuracion:"Configuración",
        historico:"Historico",    
        minutos:"Minutos",  
        minuto:"Minuto",  
        inicio:"Inicio",
        fin:"Fin",
        nombreTarea:"Nombre de tarea",
        tareaCompletada:"Tarea completada",
        activa:"Activa",
        alarma:"Alarma",
        conAlarma:"Con alarma",
        sinAlarma:"Sin alarma",
        fechaInicio:"Fecha de inicio",
        generarReporte:"Generar Reporte",
        reporteSensor:"Reporte del sensor",
        verReporte:"Ver reporte",
        descargarCSV:"Descargar CSV",
        descargarPDF:"Descargar PDF",
        reporte:"Reporte",
        fecha:"Fecha",
        temperatura:"Temperatura",
        modelo:"Modelo",
        observaciones:"Observaciones",
        firmaRevisor:"Firma del revisor",
        pagina:"Pagina",
        reporteGenerado:"Reporte generado el",
        activos:"Activos",
        verDetalle:"Ver más detalle",
        grafico:"Grafico",
        asignados:"Asignados",
        titulo:"Titulo",
        borrar:"Borrar",
        cerrar:"Cerar",
        alarmas:"Alarmas",


        seleccionRevendedor:"Seleccione un revendedor",
        
        max20:"Maximo 20 caracteres",
        max30:"Maximo 30 caracteres",
        max1:"el valor debe ser mayor o igual a 1",
        max50:"Maximo 50 caracteres",
        max40:"Maximo 40 caracteres",
        formato:"Debe ser del formato indicado",

        errorCantidad:"Debe ingresar una cantidad",
        errorCargaSensores:"Error al cargar los sensores",
        errorArchivo:"Error al subir archivo",
        errorSolicitud:"Error al crear la solicitud",
        errorArchivoJson:"Solo se admiten archivos .json",
        errorFormArchivo:"Debe subir un archivo",
        errorApikey:"Debe escribir un Apikey",
        errorFecha:"Debe seleccionar una fecha",
        errorNombre:"Debe escribir un nombre",
        errorApellidos:"Debe escribir sus apellidos",
        errorActualizarDatos:"Error al actualizar sus datos",
        errorObtenerDatos:"Error al obtener los datos",
        errorUsuarios:"Error al obtener los usuarios",
        errorRegistros:"Error al cargar los registros",
        errorAsignar:"Error al asignar sensores",
        errorOrganizaciones:"Error al obtener las organizaciones",
        errorTransaccion:"Error al realizar la transacción",
        errorModo:"Seleccione un modo",
        errorCrearTarea:"Error al crear la tarea",
        errorSensor:"Seleccione un sensor",
        errorAssetLocation:"El activo no tiene registros",
        
        
        mensajeEliminarSensor:"¿Seguro que desea quitar el sensor?",
  
        noOrganizaciones:"No hay organizaciones",


        placeHolderNombreBuscar:"Buscar por nombre",
        placeHolderApikey:"ionAjfmnSnvXXZEfgDG",
        placeHolderFecha:"Fecha de vencimiento",
        placeHolderToken:"Seleccione un token",

        telefono:"Telefono",
        max12:"Maximo 12 caracteres",
        errorTelefono:"Debe ingresar un número de teléfono",
        errorUsuario:"Debe ingresar un usuario",
        errorClave:"Debe ingresar una clave",
        archivo:"Archivo",
        errorPrefijo:"Debe ingresar un prefijo",
        finalizar:"Finalizar",
        eliminar:"Eliminar",
        correos:"Correos",
        segundos:"Segundos",
        icono:"Icono",
        telefonos:"Teléfonos",
        visto:"Visto en",
        seconds:"Segundos",
        sensors:"Sensores",
        rol:"Rol",

        errorRol:"El rol es requerido",
        recuperarContrasena:"Recuperar contraseña",
        recuperarCorreo:"Se le envio un correo para restablecer la contraseña ",
        correctoCambio:"Cambio de contrasena realizado"



      }
}