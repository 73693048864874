import React, { useContext, useEffect } from 'react'
import { Sensor } from '../../Models/Sensor'
import Table, { ColumnsType } from 'antd/es/table';
import { sensorName } from '../../DataTest/Sensors';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { DeleteOutlined } from '@ant-design/icons';
import { MenuItem } from '../../Models/MenuItem';
import Menu from '../Menu/Menu';
import ValidateSensors from '../ValidateSensors/ValidateSensors';
//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    sensors: Sensor[]
    rol: string,
    notShowActions?: boolean,
    asignedFuction?: (sensor: Sensor) => string
  }

const TableValidate = ({sensors, rol, asignedFuction, notShowActions}:Props) => {
  const { t } = useTranslation();
  const {sesion, sendSesion } = useContext(PromiseSesionContext);


    const columns: ColumnsType<Sensor> = [
          {
            title: t('basico.serial'),
            dataIndex: 'numero_de_serie',
            key: 'numero_de_serie',
          },
          {
            title: t('basico.estatus'),
            key: 'asignado',
            render: (_, record) =>{ 
              return <>{record.id_sensor_zebra ? t('basico.asignar') :  t('basico.sinAsignar')}</>},
          }
        ];

        
  return (
    <Table columns={columns} dataSource={sensors} pagination={false} />
  )
}

export default TableValidate