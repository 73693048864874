import { Empty } from 'antd'
import React from 'react'

//styles
import './NoData.css'

interface Props {
    data: any[] | undefined | null
    text: string
    children: React.ReactNode
}

const NoData = ({data, text,  children}:Props) => {
  return (
    <>
    {
        data && data.length > 0 ? children : (<div className='NoData-Component'>  <Empty description={text}  /> </div>)
    }
    
    </>
  )
}

export default NoData