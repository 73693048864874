
export class Node {
    id!: number
    name!:string
    type!:string
    color!:string
    relations!: Node[] 
    priority!: number

    constructor(id:number,name:string, type:string, relations:Node[], priority: number = 0, color:string = "white"){
        this.id = id
        this.name = name
        this.type = type
        this.relations = relations
        this.priority = priority
        this.color = color
      }

}