

export const sensorName = (sensor:string) =>{
    if(sensor==="DLJ230203893"){
        return "Nevera"
    }

    if(sensor==="DLJ230204003"){
        return "Servidor 1"
    }

    if(sensor==="DLJ230204014"){
        return "Servidor 2"
    }

    if(sensor==="DLJ230204015"){
        return "Helados"
    }

    if(sensor==="DLJ230204047"){
        return "Verduras"
    }

    return "-"

}  