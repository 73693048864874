import { deleteApi, getApi, patchApi, postApi } from "./Api";
import { FieldType } from "../Components/CreateReseller/CreateResellerComponent";
import { ResponseContent } from "../Models/ResponseContent";
import { Sensor } from "../Models/Sensor";
import { User } from "../Models/User";
import { Apikey } from "../Models/Apikey";

const generateKey = async <T>(objectArray: Promise<Sensor[]>): Promise<Sensor[]> => {
    // Código de la función
    let response = await objectArray;
    for (let index = 0; index < response.length; index++) {
        response[index].key = (index+1).toString()
    }
    return response;
  };

export const getAvailableSensors = async () => {
    try{
        let sensors = await getApi<Sensor[]>('thermotrack/reseller/sensors/unassigned')
        for (let index = 0; index < sensors.length; index++) {
            sensors[index].key = (index+1).toString()
            
        }
        return await sensors
    }
    catch(error){
        throw error;
    }
}

export const getApikeysReseller = async () => {
    try{
        return await getApi<any>('thermotrack/reseller/getTokensApikeysInReseller')
        
    }
    catch(error){
        throw error;
    }
}

export const getOrganizations = async () => {
    try{
        return await getApi<any>('thermotrack/reseller/organizations')
    }
    catch(error){
        throw error;
    }
}

export const getOrganizationsById = async (id:number) => {
    try{
        return await getApi<Sensor[]>(`thermotrack/reseller/${id}/organization`)
    }
    catch(error){
        throw error;
    }
}

export const getValidateSensor = async (id:number) => {
    try{
        return await getApi<ResponseContent>(`thermotrack/reseller/${id}/verifiedSensor`)
    }
    catch(error){
        throw error;
    }
}

export const getApiKeysReseller = async () => {
    try{
        return await getApi<Apikey[]>('thermotrack/reseller/getApikeysInReseller')
    }
    catch(error){
        throw error;
    }
}

export const postOrganization = async (createOrganizationDTO:FieldType, sensors:Sensor[], token:string, correo:string) => {
    try{
        return await postApi<ResponseContent>('thermotrack/reseller/addOrganizationAndSensors', {createOrganizationDTO, sensors, token, correo})
    }
    catch(error){
        throw error;
    }
}

export const postAddSensors = async (id:number, sensors:Sensor[]) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/reseller/${id}/addSensors`, {sensors})

    }
    catch(error){
        throw error;
    }
}

export const postChangeRole = async (userId:number, obj:any) => {
    try{
        return await patchApi<ResponseContent>(`thermotrack/reseller/${userId}/changeRoleUser`, obj)

    }
    catch(error){
        throw error;
    }
}

export const getSensors = async () => {
    try{
        return await generateKey( getApi<Sensor[]>('thermotrack/reseller/sensors'))
    }
    catch(error){
        throw error;
    }
}

export const postAddUser = async (obj: object) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/reseller/addUsers`, obj)
    }
    catch(error){
        throw error;
    }
}

export const postCreateArrayRequest = async (obj: object) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/requests/createRequestWithArray`, obj)
    }
    catch(error){
        throw error;
    }
}

export const getUsers= async () => {
    try{
        return await getApi<User[]>('thermotrack/reseller/users')
    }
    catch(error){
        throw error;
    }
}

export const postValidateSensor = async (obj: object) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/reseller/validateToken`, obj)
    }
    catch(error){
        throw error;
    }
}

export const postSensorsFileReport = async (obj: FormData) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/requests/createRequestWithFile`, obj)
    }
    catch(error){
        throw error;
    }
}

export const patchUpdateApikey= async (token:string, body:object) => {
    try{
        return await patchApi<ResponseContent>(`thermotrack/reseller/${token}/updateApikey`, body)
    }
    catch(error){
        throw error;
    }
}

export const patchBlockUser= async ( userId:number) => {
    try{
        return await patchApi<any>(`thermotrack/reseller/user/${userId}/blocked`, {})
    }
    catch(error){
        throw error;
    }
}

export const removeSensorFromOrganization= async (serial:string) => {
    try{
        return await deleteApi<ResponseContent>(`thermotrack/reseller/${serial}/removeSensor`)
    }
    catch(error){
        throw error;
    }
}


