

export const ENLOGIN = {
    login: {
        usuario: 'User',
        usuarioError:"Enter a user",
        clave: 'Passsword',
        claveError:"You must enter a password",
        ingresar:"Sign in",
        errorConexion:"Connection error",
        errorClave:"Incorrect user or password",

        errorComprobar:"Error al comprobar el usuario",
        errorCrear:"Error al crear usuario",
        mensaje1:"Completa el registro para acceder al sistema",
        errorToken:"Password change expired, request new one"
      }
}

export const ESLOGIN = {
    login: {
        usuario: 'Usuario',
        usuarioError:"Ingrese un usuario",
        clave: 'Clave',
        claveError:"Ingrese una contraseña",
        ingresar:"Ingresar",
        errorConexion:"Error de conexión",
        errorClave:"Usuario o contraseña incorrectos",
        errorToken:"Cambio de contraseña expirado, solicite uno nuevo"

      }
}