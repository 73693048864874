import React, { useContext } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import Spiner from '../Spiner/Spiner'
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess'
import AddNotificationForm from './AddNotificationForm'
import { postNotifications } from '../../Api/Subsidiary'
import { useTranslation } from 'react-i18next'

interface Props{
    step?:number
    setStep?: React.Dispatch<React.SetStateAction<number>>
    next?: () => void,
    prev?: () => void,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>

  }


const AddNotifications = ({step, setStep, next, prev, setOpen}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [messageResponse, setMessageResponse] = React.useState<string>("");

    const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);

    const onFinish = async (values: any) => {
        try{
            setLoading(true)

            const res = await postNotifications(values)
            // const res = await postSensorsFileReport(formData)
            setMessageResponse(t('basico.solicitudEnviada'))
  
            next!()
            setLoading(false)
        }
        catch(e:any){

            sendError({type: "ERROR", data:{message: e?.response?.data?.message  || t('basico.errorTransaccion')}})
            setLoading(false)

        }
      };

  return (
    <div >
      <Spiner loading={loading}>
        {step===0 ? <AddNotificationForm onFinishMaster={onFinish} />
        : <FinishedSuccess titulo={t('basico.solicitudEnviada')} onFunction={()=>{setOpen!(false)}} />} 
        {/* : step===1 ? <AssignUser onFinishMaster={onFinishClientUser} />  */}
      </Spiner>
    </div>
  )
}

export default AddNotifications