

export const ENUSUARIO = {
    usuario: {


        errorCrear:"Error creating user",

        creadoExitoso:"User created successfully",

        placeHolderNombre:"User name",
        placeHolderApellido:"User last name",
        placeHolderUsuario:"Login username",
        placeHolderClave:"Access password",
        changeRol:"Change rol",
        updatedUser:"User updated"

      }
}

export const ESUSUARIO = {
    usuario: {
        errorCrear:"Error al crear usuario",

        creadoExitoso:"Usuario creado correctamente",

        placeHolderNombre:"Nombre de usuario",
        placeHolderApellido:"Apellido del usuario ",
        placeHolderUsuario:"Usuario de ingreso",
        placeHolderClave:"Clave de acceso",
        changeRol:"Cambiar rol",
        updatedUser:"Usuario actualizado"




      }
}