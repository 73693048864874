

export const ENAPIKEY = {
    apikey: {
        titulo:"APIKey Management",

        exito:"Assigned apikey",
        editarApikey:"Edit apikey",
        apikeyActualizada:"Updated Apikey",

        errorCarga:"Error loading apikeys",
        errorRevendedor:"Error loading resellers",
        error:"Error assigning",
        errorDatos:"General data error",
        errorActualizar:"Error updating apikey"



      }
}

export const ESAPIKEY = {
    apikey: {
        titulo:"Gestión de apikeys",

        exito:"Apikey asignada",
        editarApikey:"Editar apikey",
        apikeyActualizada:"Apikey Actualizada",

        errorCarga:"Error al cargar apikeys",
        errorRevendedor:"Error al cargar revendedores",
        error:"Error al asignar",
        errorDatos:"Error al generar los datos",
        errorActualizar:"Error al actualizar el apikey"

      }
}