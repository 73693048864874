import React, { useContext, useEffect, useState } from 'react'
import { Alarm } from '../../Models/Alarm'
import SkeletonAlert from '../SkeletonComponents/SkeletonAlert'
import NoData from '../NoData/NoData'
import { Alert } from 'antd'
import { getAlertsByAsset } from '../../Api/Subsidiary'
import { getAlertsByAsset as getAlertsByAssetOrganization } from '../../Api/Organization'

import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { useParams } from 'react-router-dom'

interface Props {
    assetId: number
}


const AlertAsset = ({assetId}:Props) => {
    const [loading, setLoading] = useState(false)
    const [alarms, setAlarms] = useState<Alarm[]>([])
    const params = useParams();

    const { sendError, sesion } = useContext(PromiseSesionContext);


    const getData = async () => {

        try{
            setLoading(true)
            const res = sesion.context.rol==="Sucursal" ? await getAlertsByAsset(assetId) : await getAlertsByAssetOrganization(assetId, Number(params.idSucursal) || 0)
            setAlarms(res)

            setLoading(false)
            }
            catch(err){
              sendError({type: "ERROR", data:{message:"Error al obtener los datos del activo"}})
              setLoading(false)
        
        }
    }

    useEffect(() => {
        getData()

    }, [assetId]);
    
  return (
    <SkeletonAlert loading={loading}> 
    {/* <Spiner loading={loading}> */}
        <NoData data={alarms} text="No hay alarmas"> 
            <div style={{display:"flex", flexDirection:"column", flexWrap:"wrap", gap:"10px"}}>
            {
                alarms.map((alarm, index)=>{
                    let date= new Date(alarm.occurred) //2023-09-11T15:45:38Z
                    return(
                        <Alert message={` Alarma del sensor ${alarm.sensor_name.split("_")[1]} a una temperatura ${alarm.temperature} a fecha de ${date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear()}`} type="error" />
                    )
                })
            }
            </div>
        </NoData>
    {/* </Spiner> */}
    </SkeletonAlert>
  )
}

export default AlertAsset