

export const taskColors =(status:string) =>{
    if(status==="SENSOR_TASK_STATUS_ACTIVE"){
        return "green"
    }

    if(status==="SENSOR_TASK_STATUS_ACTIVE_WITH_ALARM"){
        return "red"
    }

    if(status==="SENSOR_TASK_STATUS_REQUIRED"){
        return "geekblue"
    }

    if(status==="SENSOR_TASK_STATUS_COMPLETED"){
        return "blue"
    }

    if(status==="SENSOR_TASK_STATUS_START_PENDING"){
        return "orange"
    }

    if(status==="SENSOR_TASK_STATUS_STOP_PENDING"){
        return "orange"
    }

    return ""
}

export const taskColors2 =(status:string) =>{
    if(status==="TASK_STATUS_ACTIVE"){
        return "green"
    }

    if(status==="TASK_STATUS_ACTIVE_WITH_ALARM"){
        return "red"
    }

    if(status==="TASK_STATUS_SENSOR_REQUIRED"){
        return "geekblue"
    }

    if(status==="TASK_STATUS_SENSOR_COMPLETED"){
        return "blue"
    }

    if(status==="TASK_STATUS_START_PENDING"){
        return "orange"
    }

    if(status==="TASK_STATUS_STOP_PENDING"){
        return "orange"
    }

    return ""
}

export const sensorColors =(status:string) =>{
    if(status==="SENSOR_STATUS_ACTIVE"){
        return "green"
    }

    if(status==="SENSOR_STATUS_STOPPED"){
        return "orange"

    }

    if(status==="SENSOR_TASK_STATUS_REQUIRED"){
        return "geekblue"
    }

    if(status==="SENSOR_TASK_STATUS_COMPLETED"){
        return "blue"
    }

    if(status==="SENSOR_STATUS_ACTIVE_WITH_ALARM"){
        return "red"
    }

    if(status==="SENSOR_TASK_STATUS_STOP_PENDING"){
        return "orange"
    }

    return ""
}