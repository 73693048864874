import React, { useRef, useState } from 'react'
import { Site } from '../../Models/Site'
import Table, { ColumnsType } from 'antd/es/table';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { RightOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';

//transalate
import { useTranslation, Trans } from 'react-i18next';

//api
import { Button, Input, InputRef, Space } from 'antd';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';


interface Props{
    findAction:(id: string | number) => void
    sites:Site[]
    getFunction: () => void
}

type DataIndex = keyof Site;


const TableSite = ({sites, getFunction, findAction}:Props) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Site> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('basico.buscar')}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t('basico.eliminar')}
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t('basico.cerrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        text
      ) : (
        text
      ),
  });

  
    const columns: ColumnsType<Site> = [
        {
            title: t('basico.nombre'),
            dataIndex: 'nombre',
            key: 'nombre',
            ...getColumnSearchProps('nombre'),
        },    
        {
            title: t('basico.descripcion'),
            dataIndex: 'descripcion',
            key: 'descripcion',
            ...getColumnSearchProps('descripcion')
        },
        {
            title: t('basico.direccion'),
            dataIndex: 'direccion',
            key: 'direcciom',
            ...getColumnSearchProps('direccion')

        },
    
 
        {
            title: t('basico.sensoresAsignados'),
            key: 'action',
            render: (_, record) => {
              return <ButtonIcon icon={<TagOutlined />} qty={record.sensor.length} />
            },
          },
        {
          title: t('basico.detalle'),
          key: 'action',
          render: (_, record) => {
            
            return <Button type="text" onClick={()=>{findAction(record.id)}}>
            <RightOutlined />
            </Button>
          },
        },
      ];

  return (
    <Table style={{width:"100%"}} columns={columns} dataSource={sites} pagination={false} />

  )
}

export default TableSite