import React, { useContext, useEffect } from 'react'

//Components
import LoginForm from '../../Components/LoginForm/LoginForm'
import { useNavigate } from 'react-router-dom';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import RecoverForm from '../../Components/LoginForm/RecoverForm';

const Login = () => {
  const navigate = useNavigate();
  const [recover, setRecover] = React.useState<string>("login");

  const {sesion, sendSesion} = useContext(PromiseSesionContext);

  useEffect(() => {
    if (sesion.value==="authorized") {
      navigate("/");
    }



  }, [sesion.value]);
  return (
    <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%"}}>
        {recover==="login" ?<LoginForm setRecover={setRecover}></LoginForm> : <RecoverForm setRecover={setRecover} />}
    </div>
  )
}

export default Login