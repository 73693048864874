import React, { useState } from 'react'

import { getAvailableSensors, postAddSensors } from '../../Api/Reseller';

//transalate
import { useTranslation, Trans } from 'react-i18next';

//Models
import { Organization } from '../../Models/Organization'


import ElementCard from './ElementCard'
import { MenuItem } from '../../Models/MenuItem'
import { PlusOutlined } from '@ant-design/icons'
import EditSensorsComponent from '../EditSensors/EditSensorsComponent'
import { useNavigate } from 'react-router-dom';

import { BsCheck2All } from 'react-icons/bs';


interface Props{
    organization: Organization,   
    propsStyles?: React.CSSProperties
    notShowMenu?: boolean
    onGetData?: () => void
    elementClass?: string 

}

const ElementCardOrganization = ({organization, propsStyles, notShowMenu, onGetData, elementClass }:Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [menuItems] = useState<MenuItem[]>([
        {
          label: <span>{t('sensor.asignarSensores')}</span>,
          title: t('sensor.asignarSensores'),
          key: '1',
          icon: <PlusOutlined />,
          component: <EditSensorsComponent type='reseller' getFunction={getAvailableSensors} id={organization.id} executeFunction={postAddSensors}  />,
          type: 'CONTENT',
          items: [],
          size: 'small',
          onConfirm: () => {onGetData!()},
    
        },
        // {
        //   label: <span>Editar Organizacion</span>,
        //   title: 'Editar Organizacion',
        //   key: '2',
        //   icon: <BsSliders />,
        //   component: <UpdateOrganization organization={organization} />,
        //   type: 'CONTENT',
        //   items: [],
        //   size: 'small',
        //   onConfirm: () => {},
    
        // },
        {
          label: <span>{t('sensor.validarSensor')}</span>,
          title: t('sensor.validarSensor'),
          key: '3',
          icon: <BsCheck2All />,
          component: <></>,
          type: 'REDIRECT',
          items: [],
          size: 'small',
          onConfirm: () => {navigate("/Organization/"+organization.id)},
    
        },
        // {
        //   label: <span>Validar</span>,
        //   key: '0',
        //   title: 'Eliminar Cliente',
        //   icon: <DeleteOutlined />,
        //   component: <ValidateSensors id={organization.id} />,
        //   type: 'CONFIRM',
        //   items: [],
        //   size: 'small',
        //   onConfirm: () => {},
    
        // },
      ]);


      
  return (
    <ElementCard elementClass={elementClass} sensors={organization.sensor.length} propsStyles={propsStyles} menuItems={notShowMenu ? undefined :menuItems} title={organization.nombre} description={organization.descripcion} image='/image/organizacion.png' status='' dontShowStatus/>
  )
}

export default ElementCardOrganization