import React from 'react'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  email:string
  pages:number
  actualPage:number
}

const ReportFooter = ({email, pages, actualPage}:Props) => {
  const { t } = useTranslation();
  const date = new Date();

  return (
    <div className='ReportFooter'>

        <div className='ReportFooter-info'>
            <div className='ReportHeader-titleText'>
                <span>{t('basico.reporteGenerado')}: </span>
                <span>{date.toDateString()}</span>
            </div>

            {/* <div className='ReportHeader-titleText'>
                <span>por:</span>
                <span>{email}</span>
            </div> */}
        </div>

        <span className='ReportFooter-page'>{t('basico.pagina')}: {actualPage} de {pages}</span>

    </div>
  )
}

export default ReportFooter