import React from 'react'

//styles
import './BatteryIndicator.css'

interface Props {
    battery: number
}

const BatteryIndicator = ({battery}:Props) => {
    const color:string = (battery > 50 ? "#b7eb8f" : battery > 20 ? "#ffd591" : "#ffa39e")

    let styleBattery:React.CSSProperties={
        border: ("1px solid "+ color),
    }

    let styleSubBattery:React.CSSProperties={
        backgroundColor: color,
        width:(battery+"%"),
        height:"8px"
    }

    let styleBatteryEnd:React.CSSProperties={
        backgroundColor: color,
    }

    let styleBatteryText:React.CSSProperties={
        color: color,
    }

  return (
    <div className='BatteryIndicator-Container'>

        <div className='BatteryIndicator' style={styleBattery}>
            <div style={styleSubBattery}></div>

            <div className='BatteryIndicator-End' style={styleBatteryEnd}></div>
        </div>

        <span style={styleBatteryText}>{battery} %</span>

    </div>

  )
}

export default BatteryIndicator