import { User } from "../Models/User";
import { getApi, patchApi, postApi, postApiFiles } from "./Api";


export const getProfile = async () => {
    try{
        return await getApi<User>('thermotrack/user/profile')
    }
    catch(error){
        throw error;
    }
}

export const postProfile = async (user:any) => {
    try{
        return await patchApi<any>('thermotrack/user/profile/update', user)
    }
    catch(error){
        throw error;
    }
}