import React, { useContext, useEffect } from 'react'
import CreateAddSensorQty from '../CreateAddSensorQty/CreateAddSensorQty';
import FinishedUserGenerate from '../FinishedUserGenerate/FinishedUserGenerate';
import CreateReseller from './CreateReseller';
import CreateClient from '../CreateClient/CreateClient';
import { Sensor } from '../../Models/Sensor';
import { message } from 'antd';
//transalate
import { useTranslation, Trans } from 'react-i18next';

//api
import { getAvailableSensors, postReseller } from '../../Api/Supplier';
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import ErrorHandler from '../../ErrorHandler/ErrorHandler';
import { renderMessage } from '../../Functions/error';
import { PromiseSesionContext } from '../../Machines/SesionMachine';

export type FieldType = {
  nombre?: string;
  descripcion?: number;
  cantidad_sensores?:number;
};

interface Props{
    step?:number
    next?: () => void,
    prev?: () => void,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  }

const CreateResellerComponent = ({step, next, prev, setOpen}:Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [reseller, setReseller] = React.useState<FieldType>({});
    const [sensors, setSensors] = React.useState<Sensor[]>([]);
    const [selectedSensors, setSelectedSensors] = React.useState<Sensor[]>([]);
    const [messageResponse, setMessageResponse] = React.useState<string>("");
    const [correo, setCorreo] = React.useState<string>("");
    const {sendError} = useContext(PromiseSesionContext);

    const { t } = useTranslation();


  const onFinishClient = (values: any) => {
    setReseller(values)
    setCorreo(values.correo)
    delete values.correo
    next!()
    
  };

  const onFinishSensors = async(values: any[]) => {
    try{
      setLoading(true)
      setSelectedSensors(values)
      const res = await postReseller(reseller, values.map((sensor)=>{return sensor.numero_de_serie}), correo)
      setMessageResponse(res.message)
      next!()
      setLoading(false)

    }
    catch(err:any){
      prev!()
      sendError({type: "ERROR", data:{message:err?.response?.data?.message || t('revendedor.errorCrear')}})
      setLoading(false)
    }


  };

  const back = () => {
    prev!()
  };

  const getData = async () => {
    try{
      setLoading(true)
      const res = await getAvailableSensors()
      if(res) setLoading(false)
      setSensors(res)
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('revendedor.errorCargaSensores')}})
      setOpen!(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, []);


  return (
    <div>
      <Spiner loading={loading}>
        {step===0 ? <CreateClient defaultValues={reseller}  onFinishMaster={onFinishClient}/> : step===1 ? <CreateAddSensorQty maxSensors={reseller.cantidad_sensores || undefined} sensors={sensors} selectedSensors={selectedSensors} autoAsign onFinishMaster={onFinishSensors} back={back} notRequiredSensors /> : <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{setOpen!(false)}} />} 
      </Spiner>
    </div>
  )
}

export default CreateResellerComponent