import { Button, Form, Result } from 'antd'
import React from 'react'
import Spiner from '../Spiner/Spiner';

//style
import "./FinishedSuccess.css"
import { useTranslation } from 'react-i18next';

interface Props{
  loading?:boolean
  titulo?:string
  status?: "success" | "error" | "info" | "warning" 
  onFunction?: () => void
}

const FinishedSuccess = ({loading, titulo, onFunction, status="info"}:Props) => {
    const { t } = useTranslation();
    const onFinish = (values: any) => {
    // onFinishMaster(values)
    };
  
    const onFinishFailed = (errorInfo: any) => {
    };
  return (
    <div>
      <Spiner loading={loading}>

        <Result
        className='Select-Text-Style'
        status={ status}
        title={titulo}
        />

        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
          <Button type="primary" onClick={(e)=>{e.stopPropagation(); onFunction!()}}>
            {t('basico.finalizar')}
          </Button>
        </div>

        </Spiner>
    </div>

  )
}

export default FinishedSuccess