import React, { useContext, useEffect, useState } from 'react'

import CsvDownloader from 'react-csv-downloader';
  import ReportDetail from './ReportDetail';
  import { PDFExport } from '@progress/kendo-react-pdf';
  import ReportTemps from './ReportTemps';
import { Button, DatePicker, TimePicker } from 'antd';
import { PromiseSesionContext } from '../Machines/SesionMachine';
import { getDataReport, getDataReportByData } from '../Api/Subsidiary';
import Spiner from '../Components/Spiner/Spiner';
import { getReportDetail, getReportDetailByDate, getReportDownload } from '../Api/Organization';
import { useParams } from 'react-router-dom';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

//transalate
import { useTranslation, Trans } from 'react-i18next';
import { Label } from 'recharts';

const { RangePicker } = DatePicker;

interface Props {
  idTask:string
  serialSensor?:string
  setSize?: React.Dispatch<React.SetStateAction<string>>
}

const ReportView = ({idTask, serialSensor, setSize}:Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [totalPages, setTotalPages] = useState<number>(1);

  const [subElements, setSubElements]= useState<any[]>([]);
  const [csvRegisters, setCsvRegisters]= useState<any[]>([]);

  const [status, setStatus] = useState<"error"|""|"warning"> ("")

  const [from, setFrom] = useState<string> ("")
  const [to, setTo] = useState<string> ("")


  const [datesSelected, setDatesSelected] = useState<boolean>(false)

  const params = useParams();



  const pdfExportComponent = React.useRef<PDFExport>(null);
  const { error, sendError, sesion } = useContext(PromiseSesionContext);

  const n= 120

  function fechasEnRango(fecha1: Date, fecha2: Date): boolean {
    const milisegundosPorDia = 1000 * 3600 * 24;
    const diferenciaEnMilisegundos = Math.abs(fecha2.getTime() - fecha1.getTime());
    const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / milisegundosPorDia);
    console.log("diferencia en dias ", diferenciaEnDias)
    return diferenciaEnDias <= 30;
}

  const generateCsvReport= (registers:any) =>{
    let newRegisters = []
     for (let index = 0; index < registers.length; index++) {
      const element = registers[index];

      newRegisters.push({Temperatura:element.temperature, Fecha:element.timestamp, Sensor:serialSensor})
      
     }


     setCsvRegisters(newRegisters)
  }

  const generateSubArrays = (registers:any)=>{
    if(registers.length===0) return
    generateCsvReport(registers)
    const subArrays = [];
    let nb = n
    let ni =0

  for (let i = 0; i < registers.length/n; i ++) {
    const slice = registers.slice(ni, nb);
    subArrays.push(slice);

    ni = nb 
    nb = nb + n
  }
  setTotalPages(subArrays.length + 1)
  console.log("subarrats ", subArrays)
  setSubElements(subArrays)

  }

  const getData = async () =>{
    try {
      setLoading(true)
      // const res = sesion.context.rol==="Sucursal" ? await getDataReport(idTask) : await getReportDetail(idTask, params.idSucursal || 0)
      // const res2 =  await  getReportDownload(idTask, params.idSucursal || 0, from, to)
      // const blob = new Blob([res2 ], { type: 'application/pdf' });

      // var reader = new FileReader();
    //   reader.onload = function() {
    //     // alert(reader.result);
    //     const linkSource = `${reader.result}`;
    //     const downloadLink = document.createElement("a");
    //     const fileName = "file.pdf";

    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    // }
    // reader.readAsDataURL(blob);
      
      // let link = window.URL.createObjectURL(blob)
      // let url =  window.URL.createObjectURL(blob)
      // window.open(url);


      // download(blob, "archivo","application/pdf")
      const res = sesion.context.rol==="Sucursal" ? await getDataReportByData(idTask, from, to) : await  getReportDetailByDate(idTask, params.idSucursal || 0, from, to)

      res.temperatureDetails.reverse()
      setData(res)
      generateSubArrays(res?.temperatureDetails)

      setLoading(false)
      
    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('activo.errorReporte')}})
      setLoading(false)

    }
  }

  useEffect(() => {
    if(from!="" && to!=""){
      console.log("fechas", from , to)

      let date1 = new Date(from)
  
      let date2 = new Date(to)

      var now_utc = date1.toUTCString().substr(0, 25);


      console.log("fechas", date1, date2, now_utc)
      console.log(new Date(now_utc));
      console.log(date1.toISOString());
      if(fechasEnRango(date1, date2)){
        setStatus("")
        getData()
      }
      else{
        setStatus("error")

      }
    }
    }, [from, to]);

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
      // Can not select days before today and today


      const startDate = dayjs().subtract(1, 'month').startOf('day');
      const endDate = dayjs().endOf('day');

      return current && (current > endDate);
    };

  return (
    <>
      {
        datesSelected ?

        <>
          <Spiner loading={loading} >
            <div className='ReportView-container scrollbar-component'>

              {data && <PDFExport forcePageBreak=".page-break" ref={pdfExportComponent} fileName={`IDsense reporte ${serialSensor}`}>
                {/* For details see:
                      http://www.telerik.com/kendo-react-ui/components/drawing/drawing-dom/#toc-dimensions-and-css-units */}
                <div style={{ width: "900px", }}>
                  <ReportDetail pages={totalPages} data={data} />
                  
                  
                  {subElements.map((x:any, index:number) => <><div className="page-break" /><ReportTemps actualPage={index+2} pages={totalPages} registers={x} data={data} /> </>)}
                </div>
              </PDFExport>}
            </div>
          </Spiner>

                {!loading &&
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px", gap:"8px"}}>
                {csvRegisters.length > 0 &&
                <CsvDownloader filename={`IDsense ${t('basico.reporte')} ${serialSensor}`} datas={csvRegisters} columns={["Fecha","Temperatura",t('basico.sensor')]}>
                  <Button type="primary">
                      {t('basico.descargarCSV')}
                    </Button>
                  </CsvDownloader>}

                  <Button type="primary"          
                  onClick={() => {
                      if (pdfExportComponent.current) {
                        pdfExportComponent.current.save();
                      }
                    }}>
                    {t('basico.descargarPDF')}
                  </Button>
                </div>}
        </>


        : <>
            <div style={{padding:"10px 0", display:"flex", flexDirection:"column", gap:"5px"}}>

              <RangePicker
                showTime
                status={status}
                placeholder={[t('basico.inicio'), t('basico.fin')]}
                onCalendarChange={(v)=>{
                  console.log("dateee ", v)
                  if(v?.[0]){
                    let date1 = `${v?.[0].year()}-${v?.[0].month()+1 < 10 ?"0": ""}${v?.[0].month()+1}-${v?.[0].date()<10 ?"0": ""}${v?.[0].date()}T${v?.[0].hour()<10 ?"0": ""}${v?.[0].hour()}:${v?.[0].minute()<10 ?"0": ""}${v?.[0].minute()}:${v?.[0].second()<10 ?"0": ""}${v?.[0].second()}`
                    let isoDate = new Date(date1).toISOString();
                    setFrom(isoDate)
                  }
                  if(v?.[1]){

                    let date2 = `${v?.[1].year()}-${v?.[1].month()+1 < 10 ?"0": ""}${v?.[1].month()+1}-${v?.[1].date()<10 ?"0": ""}${v?.[1].date()}T${v?.[1].hour()<10 ?"0": ""}${v?.[1].hour()}:${v?.[1].minute()<10 ?"0": ""}${v?.[1].minute()}:${v?.[1].second()<10 ?"0": ""}${v?.[1].second()}`
                    let isoDate2 = new Date(date2).toISOString();
                    setTo(isoDate2)
                  }
                }}
                 disabledDate={disabledDate} />

                 {/* <div>
                  <TimePicker.RangePicker style={{width:"100%"}} />
                 </div> */}

                 {status=="error" &&
                 <span style={{width:"100%", color:"red", fontSize:"12px"}}>
                  {t('activo.mensajeReporte')}
                 </span>}

                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px", gap:"8px"}}>
                  <Button type="primary"  
                  style={{marginLeft:"10px"}}  
                  disabled={from=="" || to=="" || status=="error"}      
                  onClick={() => {
                      if (from!="" && to!="" && status!="error") {
                        setDatesSelected(true)
                        setSize!("large")
                      }
                    }}>
                    {t('basico.verReporte')}
                  </Button>
                  </div>
            </div>
         </>
      }







    </>
  )
}

export default ReportView