import { Alert, Badge, Button, Descriptions, DescriptionsProps } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Sensor } from '../../Models/Sensor'
import { Task } from '../../Models/Task'
import { Alarm } from '../../Models/Alarm'
import { Margin, Resolution, usePDF } from 'react-to-pdf';
import Spiner from '../Spiner/Spiner'


interface Props {
  taskID: string
}

const TaskCompleteDetail = ({taskID}:Props) => {
    const [loading, setLoading] = useState(false)
    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
    const [items, setItems] = useState<DescriptionsProps['items']>([]);

    const generateItems = (task:Task, alarms:Alarm[]) => {
      let result = addItemsByTask(task)
      result.push(addItemsByAlarms(alarms))
      setItems(result)
      setLoading(false)

    }

    const addItemsByAlarms = (alarms:Alarm[]) => {
      let results:DescriptionsProps['items'] = items
      let elements:any= []

      alarms.forEach((alarm, index)=>{
        let date= new Date(alarm.occurred) //2023-09-11T15:45:38Z
        elements.push(
          <Alert message={` Alarma del sensor ${alarm.sensor_name.split("_")[1]} a una temperatura ${alarm.temperature} a fecha de ${date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear()}`} type="error" />
        )
      })

      const item = {
        key: '10',
        label: 'Alarmas',
        children: (
          <>
            {elements}
          </>
        ),
      }

      return item

    }

    const addItemsByTask =(task:Task) => {
      const newItems:DescriptionsProps['items'] = [
        {
          key: '1',
          label: 'Nombre',
          children: task.taskDetails.name,
        },
        {
          key: '2',
          label: 'Temperatura Alta',
          children: task.taskDetails.alarm_high_temp,
        },
        {
          key: '3',
          label: 'Temperatura baja',
          children: task.taskDetails.alarm_low_temp,
        },
        {
          key: '4',
          label: 'Creada',
          children: task.taskDetails.created,
        },
            
        {
          key: '6',
          label: 'Estatus',
          children: <Badge status={task.alarm_count===0 ? "success" : "error"} text={task.alarm_count===0 ? "OK" : `Error (${task.alarm_count} Alarmas)`} />,
          span: 3,
        }]

        return newItems
      
    }

    const getData = async() => {
      setLoading(true)
      let config = { headers: {'apikey': process.env.REACT_APP_KEY}}

      let responseTask = await axios.get(`https://api.zebra.com/v2/environmental/tasks/${taskID}`, config)
      .then(res => {
          const data = res.data.task
          return data
          // return addItemsByTask(data.task)


      }).catch(err => {
          setLoading(false)
      })

      let responseAlarm = await axios.get(`https://api.zebra.com/v2/environmental/tasks/${taskID}/alarms?page.size=10&sort_order=SORT_ORDER_ASC`, config)
        .then(res => {
            const data = res.data.sensors_alarms
            addItemsByAlarms(data)
            return data

        }).catch(err => {
            setLoading(false)
        })

      if(responseAlarm && responseTask){
        generateItems(responseTask, responseAlarm)
      }
    }

    useEffect(() => {
      getData()

    }, []);

    const options= {
      // default is `save`
      method: 'open',
      // default is Resolution.MEDIUM = 3, which should be enough, higher values
      // increases the image quality but also the size of the PDF, so be careful
      // using values higher than 10 when having multiple pages generated, it
      // might cause the page to crash or hang.
      resolution: Resolution.HIGH,
      page: {
         // margin is in MM, default is Margin.NONE = 0
         margin: Margin.SMALL,
         // default is 'A4'
         format: 'letter',
         // default is 'portrait'
         orientation: 'landscape',
      },
      canvas: {
         // default is 'image/jpeg' for better size performance
         mimeType: 'image/png',
         qualityRatio: 1
      },
      // Customize any value passed to the jsPDF instance and html2canvas
      // function. You probably will not need this and things can break, 
      // so use with caution.
      overrides: {
         // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
         pdf: {
            compress: true
         },
         // see https://html2canvas.hertzen.com/configuration for more options
         canvas: {
            useCORS: true
         }
      },
   };

  return (
    <Spiner loading={loading}>

    <div >
      <div ref={targetRef}>
        <Descriptions  bordered items={items} />
      </div>

      <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
        <Button onClick={() => toPDF({method:"open"})} style={{marginTop:"10px"}} type='primary'>Imprimir</Button>
      </div>


    </div>
    </Spiner>

  )
}

export default TaskCompleteDetail