import React, { useEffect, useState } from 'react'

import './DataGraph.css'

//models
import { Graph } from '../../Models/Graph'
import { Node } from '../../Models/Node'
import Spiner from '../Spiner/Spiner'
import { useNavigate } from 'react-router-dom'
// import Spiner from '../Spiner/Spiner'


interface Props{
  data:Graph,
  colors:string[],
  changeData: (type:string, name:string, save?:boolean) => void,
  initialCenterValue?:string,
  isTwoLevels?: boolean,
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  dontShowChildText?: boolean
  nonSelected?: boolean
}

const DataGraph = ({data, changeData, colors, initialCenterValue, isTwoLevels, loading, setLoading, dontShowChildText, nonSelected}:Props) => {
  const [hideGraph,setHideGraph]= useState<boolean>(false)
  const [hideNodeSons,setHideNodeSons]= useState<boolean>(false)
  const navigate = useNavigate();

  // const [loading, setLoading]= useState<boolean>(false)
  const [colorsGraphRelations, setColorsGraphRelations]= useState<any[]>([])
  const [center, setCenter]= useState<string>(initialCenterValue || "")

  const CX = 610;
  const CY = 610;
  const RM = 300;

  const mr=30

    useEffect(() => {
      setColorsGraphRelations(createColorsNodes(data))
    }, []);

      const createCoordinates = (x:number, y:number, r:number ,numberItems:number) => {
        const angle = 360/numberItems
        let coordinates = []
        for (let index = 0; index < numberItems; index++) {
          const xRest = Math.sin( (index * angle) * Math.PI/180) * r + x ;  
          const yRest = Math.cos( (index * angle)  * Math.PI/180) * r + y 
          coordinates.push({x:xRest,y:yRest, angle:index * angle})
        }
        return coordinates
      }

      const createLine = (x1:number, y1:number, x2:number, y2:number, color:string, type:string) => {
        
        // mid-point of line:
        var mpx = (x2 + x1) * 0.5;
        var mpy = (y2 + y1) * 0.5;

        // angle of perpendicular to line:
        let theta
        let randomNumberDirection= Math.floor(Math.random() * 9) + 1;

        if(randomNumberDirection <= 5){
          theta = Math.atan2 (x2 - x1, y2 - y1) - Math.PI / 4;
        }
        else{
          theta = Math.atan2(y2 - y1, x2 - x1) - Math.PI / 2;
        }

        // distance of control point from mid-point of line:
        let randomNumber= Math.floor(Math.random() * 9) + 1;
        var offset = randomNumber <= 4 ? 30 : randomNumber<= 7 ? 40 : 50;

        var c1x = mpx + offset * Math.cos(theta);
    		var c1y = mpy + offset * Math.sin(theta);

        var curve = "M" + x1 + " " + y1 + " Q " + c1x + " " + c1y + " " + x2 + " " + y2;
    
        return <path type={type} className={(hideGraph ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} id={"graph,"+ x1+ ", "+ y1+ ", "+ x2+ ", "+ y2+ " ,"+ type} d={curve} stroke={color} strokeWidth="0.5" strokeLinecap="round" fill="transparent"></path>

      }

      const uniqueNodesSize = (list:Node[], byValue: "name" | "type") => list.reduce((resultSet, item) =>
          resultSet.add(item[byValue]),
        new Set).size;

      const uniqueNodesElements = (list:Node[], byValue: "name" | "type") => list.reduce((resultSet, item) =>
        resultSet.add(item[byValue]),
      new Set);

      const createColorsNodes = (data:Graph) => {
        const sonElements:Node[] = []
        for (let index = 0; index < data.nodes.length; index++) {
          const element = data.nodes[index];
          sonElements.push(... element.relations)
        }
        const elements= uniqueNodesElements(sonElements, "type")

        const returningColors:any[] = []
        let selectedColor= 0

        elements.forEach((element, index) => {
          returningColors.push({type:element, color:colors[selectedColor]})
          ++selectedColor
        });

        return returningColors
      }

      const countAllSonData = (data:Graph) => {
        const sonElements:Node[] = []
        for (let index = 0; index < data.nodes.length; index++) {
          const element = data.nodes[index];
          sonElements.push(... element.relations)
        }
        return uniqueNodesSize(sonElements, "name")
      }

      const cleanPathsColor = (paths:SVGPathElement[] ) => {
        for (let index = 0; index < paths.length; index++) {
          const element= paths[index];

          const color = colorsGraphRelations.find((color)=> element.id.includes(color.type))?.color
          element.setAttribute("stroke",( color || "green"))
          element.setAttribute("strokeWidth","0.5")
        }
      }

      const cleanCircleColor = (paths:SVGPathElement[] ) => {
        for (let index = 0; index < paths.length; index++) {
          const element = paths[index];
          element.setAttribute("fill","#fff")

        }
      }

      const getPaths = () => {
        let paths = document.getElementsByTagName("path")
        let pathsArray = Array.from(paths)
        let pathsArrayFiltered = pathsArray.filter((path)=>path.id.includes("graph"))
        return pathsArrayFiltered
      }

      const getCircles = () => {
        let circles = document.getElementsByTagName("circle")
        let circlesArray = Array.from(circles)
        let circlesArrayFiltered = circlesArray.filter((circle)=>circle.id.includes("animatedCircles"))
        return circlesArrayFiltered
      }

      const selectPathsAndCircleSons = (x:number, y:number) => {

        //Get paths
        let pathsArrayFiltered = getPaths()
        let pathsSelected = pathsArrayFiltered.filter((path)=>path.id.includes(","+x+", "+ y))

        //get Circles
        let circlesArrayFiltered = getCircles()

        //clean paths and circles
        cleanPathsColor(pathsArrayFiltered)
        cleanCircleColor(circlesArrayFiltered)

        for (let index = 0; index < pathsSelected.length; index++) {

          // Elemento path 
          const element = pathsSelected[index];

          // obtencion de coordenadas para el nodo hijo
          let ret = element.id.replace('graph,'+x+", "+ y+", ",'');
          ret = ret.replace(" ","")
          let corTargetCircle = ret.split(',');
        
          // Obtencion de el elemento circle con las coordenadas obtenidas
          let circleSelected = circlesArrayFiltered.filter((circle)=>circle.id.includes(corTargetCircle[0]+", "+ corTargetCircle[1]))
          const circleSelectedOne= circleSelected[0]

          circleSelectedOne.setAttribute('fill',"red")

          element.setAttribute("stroke","red")
          element.setAttribute("strokeWidth","1.5")
        }
      }


      const generateTextFather = (x:number, y:number, angle:number, name:any) => {
        const rotate:boolean = angle >180 ? true : false

        return <g className={(hideGraph ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} transform={"translate(" + x + " "+y+") rotate("+(rotate ? -180 : 0)+")"}>
        <foreignObject x={rotate ? "20" : "35"} y={angle ==0 ? "0": angle==180 ? "-40" : "-11"} width="200px" height="50px">
          <div className="grafoBusqueda-cTexto"><span className={rotate ? 'grafoBusqueda-Texto' : ""}>{name}</span></div>
        </foreignObject></g>

      }

      const generateTextSon = (x:number, y:number, angle:number, name:any) => {
        const numberAngle = (angle-90>0 ? angle-90 : ((angle-90)*-1))
        const angleString:string= (angle-90>0?"-" : " ") + (numberAngle)

        return <g className={(hideGraph ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} transform={"translate(" + x + " "+y+") rotate("+(angleString)+")"}>
        <foreignObject x="24" y="-15" width="200px" height="100px">
          <div className="grafoBusqueda-cTexto"><span className={numberAngle >100 ? 'grafoBusqueda-Texto' : "grafoBusqueda-TextoN"}>{name}</span></div>
        </foreignObject>
      </g>
      
      }

      const generateNodeSon = (x:number, y:number, nodeSize:number, name:string, type:string, color?:string) => {
        let randomNumberDirection= Math.floor(Math.random() * 1000) + 1;
        if(nonSelected){
          return <circle id={'animatedCircles '+ x+", "+ y+ " "+ randomNumberDirection + " " + type} key={'animatedCircles '+ x+", "+ y+ " "+ randomNumberDirection + " " + type} fill={color} strokeWidth={"2px"} stroke={"#000"} className={'grafoBusqueda-circleClick grafoBusqueda-circleW ' + (hideNodeSons ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} cx={x} cy={y} r={nodeSize} ></circle>

        }
        return <circle onClick={()=>{sonSelected(x,y, type, name)}} id={'animatedCircles '+ x+", "+ y+ " "+ randomNumberDirection + " " + type} key={'animatedCircles '+ x+", "+ y+ " "+ randomNumberDirection + " " + type} fill={color} strokeWidth={"2px"} stroke={"#000"} className={'grafoBusqueda-circleClick grafoBusqueda-circleW ' + (hideNodeSons ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} cx={x} cy={y} r={nodeSize} ></circle>
      }

      const generateNodeFather = (x:number, y:number, nodeSize:number, id?:number) => {
        // return <circle className={'grafoBusqueda-circleClick grafoBusqueda-circleBlue ' + (hideGraph ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} key={"circleFather"+x+y} onClick={()=>{selectPathsAndCircleSons(x, y)}} cx={x} cy={y} r={nodeSize}  />
        return <circle className={'grafoBusqueda-circleClick grafoBusqueda-circleBlue ' + (hideGraph ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} key={"circleFather"+x+y} onClick={()=>{redirectGraph(id || 0)}} cx={x} cy={y} r={nodeSize}  />

      }

      const calculateNodeSizeByPerimeter = (perimeter:number, size:number) => {
        return (((perimeter/size)/2)-3)
      }

      //Funcion que en base a un perimetro, numero de nodos (size) y el tamano de los nodos (nodeSize) calcula si el tamano de los nodos es mayor al perimetro
      //true si es mayor, false si es menor
      const calculeteExcessSize = (perimeter:number, size:number, nodeSize:number) => {
        if(size*nodeSize*2 > perimeter)return true
        return false
      }

      const printGraph = (x:number, y:number, r:number) => {
        const smallCircle:number = r - 250

        //calculo de perimetro de las circunsferencias
        const perimeterFather = 2 * Math.PI * smallCircle
        const perimeterSon = 2 * Math.PI *  r

        //Tamano medio de los nodos
        const mediaNodeSize = mr

        //Numero de nodos
        const sizeFather = data.nodes.length
        const sizeSon = countAllSonData(data)

        //Create colors
        const colorsGraph = createColorsNodes(data)

        //Tamano de los nodos
        const nodoSizeFather = calculeteExcessSize(perimeterFather, sizeFather, mediaNodeSize) ? calculateNodeSizeByPerimeter(perimeterFather, sizeFather) : mediaNodeSize
        const nodoSizeSon =  calculeteExcessSize(perimeterSon, sizeSon, mediaNodeSize) ? calculateNodeSizeByPerimeter(perimeterSon, sizeSon) : mediaNodeSize

        //Creacion de coordenadas
        let coordinates = createCoordinates(x,y, smallCircle, sizeFather)

        if(sizeFather===1 && isTwoLevels){
          coordinates = [{x:CX,y:CY, angle:0}]
        }
        else{
          coordinates = createCoordinates(x,y, smallCircle, sizeFather)
        }

        const coordinatesSon = createCoordinates(x,y, r, sizeSon)

        //Nodos hijos
        let childNodes=[]

        let usedCordinateSon = 0

        let nodesCircles=[] 
        let nodesLines = []
        let nodesText = []

        //circulos exteriores
        nodesCircles.push(<circle className={(hideNodeSons ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} cx={x} cy={y} r={r} fill="transparent"  stroke="black" strokeDasharray="2 3"  />)
        if(!isTwoLevels) nodesCircles.push(<circle cx={x} cy={y} r={smallCircle} fill="#fff" stroke="black" fillOpacity={"0.7"} strokeDasharray="2 3"  />)


        for (let index = 0; index < data.nodes.length; index++) {
          const element = coordinates[index];
          
          nodesCircles.push(generateNodeFather(element.x, element.y, nodoSizeFather, data.nodes[index].id))

          nodesText.push(sizeFather<=10 ?  generateTextFather(element.x, element.y, element.angle, data.nodes[index].name) : generateTextSon(element.x, element.y, element.angle, data.nodes[index].name))

          for (let indexSon = 0; indexSon < data.nodes[index].relations.length; indexSon++) {
            const elementSon = coordinatesSon[usedCordinateSon];
            const existElementSon = childNodes.find((node)=>node.name == data.nodes[index].relations[indexSon].name)

            const colorSonRelation= colorsGraph.find((color)=>color.type == data.nodes[index].relations[indexSon].type).color
            if(existElementSon){
              nodesLines.push(createLine(element.x, element.y,existElementSon.x, existElementSon.y, colorSonRelation, data.nodes[index].relations[indexSon].type))
            }
            else{

              usedCordinateSon++
              nodesCircles.push(generateNodeSon(elementSon.x, elementSon.y, nodoSizeSon, data.nodes[index].relations[indexSon].name, data.nodes[index].relations[indexSon].type, data.nodes[index].relations[indexSon].color))
              nodesLines.push(createLine(element.x, element.y,elementSon.x, elementSon.y, colorSonRelation, data.nodes[index].relations[indexSon].type))
              nodesText.push(generateTextSon(elementSon.x, elementSon.y, elementSon.angle, data.nodes[index].relations[indexSon].name))
  
              const savedNode ={
                name: data.nodes[index].relations[indexSon].name,
                node: data.nodes[index].relations[indexSon],
                x: elementSon.x,
                y: elementSon.y,
              }
              
              childNodes.push(savedNode)
           
            }
          }
        }    
        return [...nodesLines, ...nodesCircles, ...nodesText]
      }

    const redirectGraph = (id:number)=>{
      navigate("/Asset/"+id.toString())
    }

    const sonSelected = (x:number, y:number, type:string, name:string) => {
      setHideGraph(true)
      setCenter(type)

      //Get paths
      let pathsArrayFiltered = getPaths()

      //Get Circles
      let circlesArrayFiltered = getCircles()

      //clean paths and circles
      cleanPathsColor(pathsArrayFiltered)
      cleanCircleColor(circlesArrayFiltered)

      let circlesSelected = circlesArrayFiltered.filter((circle)=>circle.id.includes(" " + x + ", " + y))
      const element = circlesSelected[0];
      element.setAttribute("cx",CX.toString())
      element.setAttribute("cy",CY.toString())

      setTimeout(function () {
        // changeData()
        setHideNodeSons(true)
      }, 500);

      setTimeout(function () {
        // changeData()
        setLoading(true)
        element.setAttribute("cx",x.toString())
        element.setAttribute("cy",y.toString())
      }, 1000);

      setTimeout(function () {

        changeData(type, name, true)
      }, 1500);

      setTimeout(function () {
        setLoading(false)
        setHideGraph(false)
        setHideNodeSons(false)
      }, 2500);

    }

    const testMoveCirclesCenter = (x:number, y:number) => {


      let paths = document.getElementsByTagName("circle")
      let pathsArray = Array.from(paths)

      let pathsArrayFiltered = pathsArray.filter((path)=>path.id.includes("animatedCircles"))


      for (let index = 0; index < pathsArrayFiltered.length; index++) {
        const element = pathsArrayFiltered[index];
        element.setAttribute("cx",x.toString())
        element.setAttribute("cy",y.toString())
      }


      setTimeout(function () {
        // changeData()
      }, 1000);
    }


  return (
    <>
    <svg
    className='grafoBusqueda-grafo'
    viewBox="0 0 1291 1191"
    fill="none">
      { printGraph(CX, CY , 400)}

      {loading && <foreignObject x={CX-100} y={CY -80} width="200px" height="200px">
        <Spiner loading></Spiner>
      </foreignObject>}

      {/* {!isTwoLevels &&
       <g className={(hideGraph ? "grafoBusqueda-noneactiveElements" : "grafoBusqueda-activeElements")} transform={"translate(" + (CX-(400-250)) + " "+CY+")"}>
        <foreignObject   width={ ((400-250)*2)+"px"} height="50px">
          <div className="grafoBusqueda-centerText" ><span className={false ? 'grafoBusqueda-Texto' : ""}>{center}</span></div>
        </foreignObject></g>} */}
    </svg>
    </>
  )
}

export default DataGraph



