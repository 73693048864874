
export const dateToString = (date:Date) =>{
    try{
        return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours() < 10 ? ("0"+date.getHours()) :date.getHours()}:${date.getMinutes()<10 ? ("0"+date.getMinutes()) :date.getMinutes()}`
    }
    catch{
        return `Fecha no valida`
    }
}

export const stringToDate = (inDate:string) =>{
    try{
        let date = new Date(inDate)
        return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours() < 10 ? ("0"+date.getHours()) :date.getHours()}:${date.getMinutes()<10 ? ("0"+date.getMinutes()) :date.getMinutes()}`
    }
    catch{
        return `Fecha no valida`
    }

}
