import React, { useState } from 'react'


import { MenuItem } from '../../Models/MenuItem'
import { Button, Menu, Modal } from 'antd'
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'

interface Props{
    menuItem:MenuItem
    open:boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    updateSize?: boolean


}

const ModalMenuItem = ({menuItem, open, setOpen, updateSize}:Props) => {
  const [size, setSize] = useState<string>("normal")


    const closeModal= ()=>{
        setOpen(false)
    }

  const componentChild = React.cloneElement(menuItem.component, {closeModal:closeModal}) 

  return (
    <>
        {
        <Modal width={size==="normal" ? undefined : 1000} open={open} onCancel={()=>{setOpen(false)}} title={menuItem.title} footer={false} afterClose={()=>{menuItem.onConfirm()}} destroyOnClose >
            {componentChild}

            {updateSize && <div style={{display:"flex", flexDirection:"row", flexWrap:"nowrap", justifyContent:"end"}}> <Button onClick={()=>{setSize(size==="normal" ? 'big' : "normal")}} type='primary' style={{padding:"0", marginTop:"5px"}}> {size==="normal" ? <ArrowsAltOutlined style={{fontSize:"15px"}} /> :<ShrinkOutlined style={{fontSize:"15px"}} /> } </Button></div>}

        </Modal>
        }

    </>
  )
}

export default ModalMenuItem