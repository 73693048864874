import React from 'react'

interface Props {
    text:string
}

const ReportTempCard = ({text}:Props) => {
  return (
    <div className='ReportTempCard'>
        {text}
    </div>
  )
}

export default ReportTempCard