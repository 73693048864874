import { DotChartOutlined } from '@ant-design/icons'
import { Skeleton } from 'antd'
import React from 'react'

interface Props {
    loading:boolean
    children?: React.ReactNode
    style?: React.CSSProperties
}

const SkeletonGraph = ({loading, children, style}:Props) => {
  return (
    <>
        {loading ? 
        <div style={{
          width:"100%", 
          display:"flex",
          flexDirection:"row",
          flexWrap:"nowrap",
          justifyContent:"center",
          alignItems:"center",
          paddingTop:"30px"}}>
          <Skeleton.Node style={style} active>
              <DotChartOutlined style={{ fontSize: 80, color: '#bfbfbf' }} />
          </Skeleton.Node>
        </div>
        : children}

    </>
  )
}

export default SkeletonGraph