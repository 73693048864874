import React, { useEffect, useState } from 'react'

//styles
import "./CreateClient.css"
import { Button, Form, Input, Select, Switch } from 'antd'
import { postValidateSensor } from '../../Api/Reseller';
import { Apikey } from '../../Models/Apikey';

//transalate
import { useTranslation, Trans } from 'react-i18next';


export type FieldType = {
    nombre?: string;
    descripcion?: number;
    token?:string
    correo?:string
    cantidad_sensores?:number
  };

interface Props{
  onFinishMaster: (values: any) => void
  defaultValues?:FieldType
  apikeys:Apikey[]
}

// Estatus de validacion 
//validating
//warning 
//success

const CreateOrganization = ({onFinishMaster, defaultValues,apikeys}:Props) => {
    const [status, setStatus] = useState<"warning" | "success" | "validating"| "error" | "">("");
    const [inputValue, setInputValue] = useState('');
    const { t } = useTranslation();
    
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

    const validateApiKey = async () =>{
        try{
            setStatus("validating")
            const  res = await postValidateSensor({token:inputValue})

            if(res.message) setStatus("success")
            else setStatus("error")


        }
        catch
        {
            setStatus("error")
        }

    }

      useEffect(() => {
        setStatus("validating")
        const timer = setTimeout(() => {
          // Aquí puedes hacer la petición AJAX que necesites
          if(inputValue!=""){
            validateApiKey()

          }
          else{
            setStatus("")
          }
        }, 1000);
    
        return () => clearTimeout(timer);
      }, [inputValue]);

  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={defaultValues}
    >
    <div className='CreateClient-Form'>
      {/* <Form.Item  style={{width:"100%"}} label="Ya tengo una franquicia creada" valuePropName="checked">
        <Switch checked={checked} onChange={(event)=>{setChecked(event)}} />
      </Form.Item> */}
        <Form.Item<FieldType>
        label={t('basico.nombre')}
        name="nombre"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.errorNombre') }, {max:20, message:t('basico.max20')}]}
        >
        <Input placeholder={t('organizacion.placeHolderNombre')} />
        </Form.Item>    

        <Form.Item<FieldType>
        label={t('basico.descripcion')}
        name="descripcion"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.errorDescripcion') }, {max:30, message:t('basico.max30')}]}
        >
        <Input placeholder={t('organizacion.placeHolderDescripcion')} />
        </Form.Item>

        {/* <Form.Item<FieldType>
        label="Token"
        name="token"
        style={{width:"100%"}}
        rules={[{ required: true, message: 'Debe especificar el token' }, {max:20, message:"Maximo 20 caracteres"}]}
        hasFeedback
        validateStatus={status}
        >
        <Input    
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}   
        />
        </Form.Item> */}

        <Form.Item<FieldType>
            name={"token"}
            label={t('basico.token')}
            rules={[
            {required: true,
            message: t('basico.placeHolderToken'),},
            ]} style={{width:"100%"}}>

            <Select style={{width:"100%"}} placeholder={t('basico.placeHolderToken')}>
      
              {apikeys.map((apikey)=> <Select.Option value={apikey.nombre || apikey.token}>{apikey.token}</Select.Option>)}

            </Select>
        </Form.Item>

        <Form.Item<FieldType>
        label={t('basico.numeroSensores')}
        name="cantidad_sensores"
        style={{width:"30%"}}
        rules={[{ required: true, message: t('basico.errorCantidad') }, {max:20, message:t('basico.max20')},{type:"number", min:1, message:t('basico.max1'), transform(value) {
          return Number(value)
        },}]}
        >
        <Input placeholder={t('basico.cantidad')}    type='number'/>
        </Form.Item>

        <Form.Item<FieldType>
        label={t('basico.correo')}
        name="correo"
        style={{width:"60%"}}

        rules={[{ required: true, message: t('basico.errorCorreo') }, {max:50, message:t('basico.max50')}, {type:'email', message:t('basico.formato')}]}
        >
        <Input placeholder={"email@dominio.com"}/>
        </Form.Item>

        {/* <Form.Item<FieldType>
        label="Dirección"
        name="direccion"
        style={{width:"100%"}}
        rules={[{ required: true, message: 'Debe escribir dirección' }]}
        >
            <TextArea rows={4} />
        </Form.Item> */}
    </div>

      {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button disabled={status!=="success"} type="primary" htmlType="submit">
          Siguiente
        </Button>
      </div> */}

        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button  type="primary" htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>
      

    </Form>
  )
}

export default CreateOrganization