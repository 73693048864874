import React from 'react'

// styles
import './ElementCard.css'
import { MenuItem } from '../../Models/MenuItem'
import Menu from '../Menu/Menu'
import { AlertOutlined, ArrowDownOutlined, ArrowUpOutlined, TagOutlined } from '@ant-design/icons'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import { Tag } from 'antd'

interface Props{
  id?: string | number,
  title: string,
  status?: string,
  statusColor?:string,
  image: string,
  description?: string,
  dontShowStatus?: boolean
  menuItems?: MenuItem[]
  onClick?: (id: string | number) => void   | undefined | null,
  propsStyles?: React.CSSProperties,

  tempLow?:number
  tempMax?:number
  elements?:string
  sensors?:number

  showAlarms?:boolean,
  alarms?:number
  elementClass?: string 


}

const ElementCard = ({id, title, status, statusColor, image, description, dontShowStatus, menuItems, onClick, propsStyles, tempLow, tempMax, elements, sensors, showAlarms, alarms, elementClass}:Props) => {

  const styles = {
    cursor: onClick ? "pointer" : "" 
    ,
    ...propsStyles
  }

  return (
    <div className={'ElementCard ' + (elementClass && elementClass)} style={styles} onClick={(e)=>{e.stopPropagation(); if(onClick && id)onClick(id)}}>
        <div className='ElementCard-Right'>
            <img src={image || `/image/reseller.png`} />
        </div>

        <div className='ElementCard-Left'>
            <span className='ElementCard-title'>{title}</span>
            {
              description && <span className='ElementCard-description'>{(description).slice(0, 28)+ (description.length > 28 ? "..." : "")} </span>
            }

          {(tempLow || tempMax)&&
          <div className='ElementCard-lineElements'>
            {tempLow ? <ButtonIcon icon={<ArrowDownOutlined />} qty={tempLow} text='Min' /> : <></>}  
            {tempMax ? <ButtonIcon icon={<ArrowUpOutlined />} qty={tempMax} text='Max'/> : <></>}
          </div>}

          {(status && statusColor)&&
          <div className='ElementCard-lineElements'>
            <Tag color={statusColor}> {status}</Tag>
          </div>}

          {
           <div style={{display:"flex", flexDirection:"row", gap:"8px"}}> <ButtonIcon icon={<TagOutlined />} qty={sensors|| 0} />  {showAlarms && <ButtonIcon icon={<AlertOutlined />} qty={alarms|| 0} />} </div>
          }
        </div>



       {(menuItems) && <Menu menuItems={menuItems}></Menu>}

        {!dontShowStatus && <div className='ElementCard-Status' style={{background:"#77D975"}}></div>}
    </div>
  )
}

export default ElementCard