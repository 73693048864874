

export const ENSENSOR = {
    sensor: {
        title:"Sensor management",
        crear:"Load sensors",
        buscarPorSerial:"Search by serial",
        serialSensor:"Sensor serial",
        sensorActualizado:"Updated Sensor",
        errorSensorActualizar:"Error updating the sensor",
        errorSerial:"The sensor serial is mandatory",
        errorSerial2:"At least one sensor",
        cambiarNombre:"Change sensor name",
        ubicacion:"Sensor location",
        sinDatos:"The sensor has no records",
        detenerSensor:"Stop sensor",

        errorDetener:"Error stopping the sensor",

        exitoDetenido:"The sensor has been stopped",
        mensajeDetener:"Are you sure you want to stop the sensor?",

        sensorCard1:"Assigned sensors",
        sensorCard2:"Unassigned sensors",
        sensorCard3:"Clients with more sensors",
        sensorCard4:"Clients with fewer sensors",

        errorObtener:"Error loading sensors",
        asignarSensores:"Assign sensor",
        validarSensor:"Validate sensor",

        mensaje1:"The sensor has been removed from the",
        mensaje2:"Sensors assigned successfully",
        mensaje3:"15 seconds minimum",

        actualizarUbicacion:"Update location",


      }
}

export const ESSENSOR = {
    sensor: {
        title:"Gestión de sensores",
        crear:"Cargar sensores",
        buscarPorSerial:"Buscar por serial",
        serialSensor:"Serial del sensor",
        sensorActualizado:"Sensor Actualizado",
        errorSensorActualizar:"Error al actualizar el sensor",
        errorSerial:"El serial del sensor es obligatorio",
        errorSerial2:"Por lo menos un sensor",
        cambiarNombre:"Cambiar de nombre del sensor",
        ubicacion:"Ubicación del sensor",
        sinDatos:"El sensor no tiene registros",
        detenerSensor:"Detener sensor",
        asignarSensores:"Asignar sensor",
        errorDetener:"Error al detener el sensor",

        exitoDetenido:"El sensor se detuvo",
        mensajeDetener:"¿Seguro que desea parar el sensor?",
        validarSensor:"Validar sensor",
        sensorCard1:"Sensores asignados",
        sensorCard2:"Sensores sin asignar",
        sensorCard3:"Clientes con más sensores",
        sensorCard4:"Clientes con menos sensores",

        errorObtener:"Error al cargar los sensores",

        mensaje1:"El sensor fue removido de la",
        mensaje2:"Sensores asignados correctamente",
        mensaje3:"15 segundos mínimos",

        actualizarUbicacion:"Actualizar localización",


      }
}