import React, { useContext, useEffect, useState } from 'react'
import StopTask from './StopTask'
import axios from 'axios'
import Spiner from '../Spiner/Spiner'
import { Sensor } from '../../Models/Sensor'
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess'
import { stopTask } from '../../Api/Subsidiary'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { useParams } from 'react-router-dom'
import { postStopTask } from '../../Api/Organization'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    idTask?:string
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  }

const StopTaskComponent = ({idTask, setOpen}:Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sensors, setSensors] = useState<Sensor[]>([])
  const [complete, setComplete] = useState<boolean> (false)

  const { error, sendError, sesion } = useContext(PromiseSesionContext);

  const params = useParams();


    const getSensors = () => {
        setLoading(true)
        let config = {
          headers: {
            'apikey': process.env.REACT_APP_KEY
          }
        }
    
        axios.get(`https://api.zebra.com/v2/devices/environmental-sensors?page=0&size=20&sort_field=SORT_FIELD_NAME&sort_order=SORT_ORDER_ASC&exclude_low_battery=false&task_id=${idTask}`, config)
        .then(res => {
          const persons = res.data;
          setSensors(res.data.sensors)
          setLoading(false)
        }).catch(err => {
          setLoading(false)
    
        })
      }

    useEffect(() => {
        // getSensors();
    }, []);

    const closeModal =() =>{
      setOpen!(false)
    }

    // const stopTask = ()=>{
    //   setLoading(true)
    //   setComplete(true)
    //   let config = {
    //     headers: {
    //       'apikey': process.env.REACT_APP_KEY
    //     }
    //   }


    //   axios.post(`https://api.zebra.com/v2/environmental/tasks/${idTask}/stop`, {}, config)
    //   .then(res => {
    //     const persons = res.data;
    //     setLoading!(false)
        
    //   }).catch(err => {
    //     setLoading(false)
    //     setComplete(false)
    //      setErrorMessage(err.response.data.message)
    //   })
    // }

    const newStopTask = async() => {
      try{
        setLoading(true)
        const res= sesion.context.rol==="Sucursal" ? await stopTask(idTask!) : await postStopTask(params.idSucursal || 0,idTask || 0, {})
        
        setLoading(false)
        setComplete(true)

      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('sensor.errorDetener')}})
        setLoading(false)
        setComplete(false)
      }

    }


  return (
    <div>
      <Spiner loading={loading}>
        {complete ? <FinishedSuccess titulo={t('sensor.exitoDetenido')}  onFunction={closeModal} /> : <StopTask sensors={sensors} onCancel={closeModal} onFinish={newStopTask} />}
      </Spiner>
    </div>
  )
}

export default StopTaskComponent