import React from 'react';
import { BrowserRouter as Router, Route , Routes} from 'react-router-dom';

import './App.css';
import Layout from './Layout/Layout';

//Pages
import Clients from './Pages/Clients/Clients';
import Sites from './Pages/Sites/Sites';
import Assets from './Pages/Assets/Assets';
import Tasks from './Pages/Tasks/Tasks';
import Sensors from './Pages/Sensors/Sensors';
import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Login/Login';

//Machines
import { useMachine } from '@xstate/react';
import { sesionMachine, PromiseSesionContext } from './Machines/SesionMachine';
import { errorMachine } from './Machines/ErrorMachine';
import Users from './Pages/Users/Users';
import Organizations from './Pages/Organizations/Organizations';
import Resellers from './Pages/Resellers/Resellers';
import ErrorHandler from './ErrorHandler/ErrorHandler';
import RolGuard from './Guards/RolGuard';
import ResellerDetail from './Pages/ResellerDetail/ResellerDetail';
import DashboardReseller from './Pages/Dashboard/DashboardReseller';
import AssetDetail from './Pages/AssetDetail/AssetDetail';
import OrganizationDetail from './Pages/OrganizationDetail/OrganizationDetail';
import ReportDetail from './Reportes/ReportDetail';
import Test from './Reportes/test';
import ReportView from './Reportes/ReportView';
import Requests from './Pages/Requests/Requests';
import UserManageForm from './Components/UserManageForm/UserManageForm';
import ConfirmAccount from './Pages/ConfirmAccount/ConfirmAccount';
import Apikeys from './Pages/Apikeys/Apikeys';
import SensorDetail from './Pages/SensorDetail/SensorDetail';
import Historic from './Pages/Historic/Historic';
import Setting from './Pages/Setting/Setting';
import AllNotification from './Pages/AllNotifications/AllNotification';
import Recovery from './Pages/Recovery/Recovery';

function App() {
  const [sesion, sendSesion] = useMachine(sesionMachine)
  const [error, sendError] = useMachine(errorMachine)

  return (
    <Router>
      <PromiseSesionContext.Provider value={{ sesion, sendSesion, error, sendError }}>
        <ErrorHandler>
          <RolGuard>
          <Layout>
            <Routes>
              <Route path="/Home" element={<Dashboard />}/>
              <Route path="/Panel-Reseller" element={<DashboardReseller />}/>

              <Route path="/Login" element={<Login />}/>
              <Route path="/verify/:user/:code" element={<ConfirmAccount />}/>
              <Route path="/recovery/:user/:code" element={<Recovery />}/>
              <Route path="/Panel" element={<h1>   </h1>}/>
              <Route path="/Organizations" element={<Organizations />}/>
              <Route path="/Requests" element={<Requests />}/>
              <Route path="/Apikeys" element={<Apikeys />}/>


              <Route path="/Organization/:id" element={<OrganizationDetail />}/>
              <Route path="/Resellers" element={<Resellers />}/>
              <Route path="/Reseller/:id" element={<ResellerDetail />}/>
              <Route path="/Users" element={<Users />}/>
              <Route path="/settings" element={<Setting />}/>
              <Route path="/Sites" element={<Sites />}/>
              <Route path="/Assets" element={<Assets />}/>
              <Route path="/Assets/:idSucursal" element={<Assets />}/>
              <Route  path="/Asset/:idAsset/:idSucursal" element={<AssetDetail />}/>
              <Route path="/Asset/:id" element={<AssetDetail />}/>
              <Route path="/Tasks" element={<Tasks />}/>
              <Route path="/Sensor" element={<Sensors />}/>
              <Route path="/Historic/:id" element={<Historic />}/>
              <Route path="/Historic/:id/:idSucursal" element={<Historic />}/>
              <Route path="/Notifications" element={<AllNotification />}/>

              <Route path="/" element={<Dashboard />}/>


            </Routes>
          </Layout>
          </RolGuard>
        </ErrorHandler>
      </PromiseSesionContext.Provider>
    </Router>
  );
}

export default App;
