

export const ENORGANIZACION = {
    organizacion: {
        titulo:"Reseller management",
        validadorSensores:"Sensor validator",
        validarSensores:"Validate sensors",

        crearOrganizacion:"Create organization",
        definirSensores:"Define sensors",

        errorCrear:"Error creating organization",

        placeHolderNombre:"Organization name",
        placeHolderDescripcion:"Description of the organization",
      }
}

export const ESORGANIZACION = {
    organizacion: {
        titulo:"Gestión de revendedores",
        validadorSensores:"Validador de sensores",
        validarSensores:"Validar sensores",

        crearOrganizacion:"Crear organización",
        definirSensores:"Definir sensores",

        errorCrear:"Error al crear la organización",

        placeHolderNombre:"Nombre de organización",
        placeHolderDescripcion:"Descripcion de la organización",

      }
}