import React from 'react'

import {APIProvider, Map, Marker, AdvancedMarker, Pin} from '@vis.gl/react-google-maps';
import { Site } from '../../Models/Site';
import MapHandler from '../MapInputSelect/MapHandler';

//transalate
import { useTranslation, Trans } from 'react-i18next';
import LocationSection from '../LocationSection/LocationSection'

//style 
import './MapApp.css'
import { stringToDate } from '../../Functions/date';


interface Props{
    location?:{lat: number, lng: number} | null
    locations?:{lat: number, lng: number, timestamp:string}[] | null

}

const MapAppMultiple = ({location, locations}:Props) => {
  const position1 = {lat: 4.626282790917398, lng: -74.1105804658393};
  const { t } = useTranslation();
  
  return (
    <div style={{ height: '100%', width: '100%' }}>
    <APIProvider apiKey={'AIzaSyDRsisJvgO8bZyiFvtX0_CxrntT_mPQMUY'}>
      <Map  mapId={'49ae42fed52588c3'} defaultCenter={locations && locations?.length>0 ? locations[0] :position1} defaultZoom={10}  disableDefaultUI gestureHandling={'greedy'}  >


        { locations &&
          locations.map((site, index)=>{
            let haveAlarm = false
            const colorBackground = index===0 ? "#38b000" : index+1===locations.length ? "" : "#008dc05b"
            const colorBorder = index===0 ? "#006400" : index+1===locations.length ? "" : "#006DB2"

            const size = index===0 ? 1.2 : index+1===locations.length ? 1.2 : 0.8

            return <AdvancedMarker
            title={t('basico.visto')+": " + stringToDate(site.timestamp) }
            

            onClick={()=>{}}
            position={{lat:Number(site.lat), lng:Number(site.lng)}}>
            {(index===0 || index+1===locations.length) ?
                <Pin glyph={(index +1).toString()}  background={colorBackground} borderColor={colorBorder} glyphColor={"#000"} scale={size}>
            </Pin>
            :
            <div className='mapMarker'> <span> {(index +1).toString()}</span></div>
            
            }
          </AdvancedMarker>
          })
        }


      </Map>
    </APIProvider>

    </div>
  )
}

export default MapAppMultiple