import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react'

//styles
import './Spiner.css'

interface Props{
    loading?:boolean
    children?:React.ReactNode
    minHeight?:number
}

const Spiner = ({loading, children, minHeight}:Props) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  return (
  <>
  {loading ? <div className='Spiner-Component' style={{minHeight:(`${minHeight ? minHeight : 200}px`)}}> <Spin indicator={antIcon} /></div> : <>{children}</>}
  
  </>
  )
}

export default Spiner