import React from 'react'
import { Sensor } from '../../Models/Sensor'
import { Button, Form, Select } from 'antd'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    sensors:Sensor[]
    onClose:()=>void
    errorMessages:string
    onFinishMaster: (values: any) => void

}

const AssignSingleSensor = ({sensors, onClose, errorMessages, onFinishMaster}:Props) => {
    const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };

  return (
    <Form
    name="dynamic_form_item"
    onFinish={onFinish}
    layout="horizontal" 
    style={{ maxWidth: 600 }}
    >
        <Form.Item
            name={"sensor_id"}
            label={t('basico.sensor')}
            rules={[
            {
            required: true,
            whitespace: true,
            message: t('basico.errorSensor'),
            },
            ]}>

            <Select style={{width:"100%"}} placeholder={t('basico.sensores')}>
                { sensors.map((sensor:Sensor)=>{return <Select.Option value={sensor.id_sensor_zebra}>{`${sensor.numero_de_serie} ${sensor.nombre ? "-" : ""} ${sensor.nombre || ""}` }</Select.Option>}) }
            </Select>
        </Form.Item>

        <Form.Item>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"space-between"}}>
        <Button type="default" onClick={()=>{onClose()}} >
          {t('basico.cancelar')}
        </Button>
        
        <Button type="primary" htmlType="submit">
          {t('basico.aceptar')}
        </Button>
        </div>

      </Form.Item>
    </Form>
  )
}

export default AssignSingleSensor