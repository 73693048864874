
export const taskStatus =(status:string) =>{
    if(status==="SENSOR_TASK_STATUS_ACTIVE"){
        return "ACTIVO"
    }

    if(status==="SENSOR_TASK_STATUS_ACTIVE_WITH_ALARM"){
        return "ACTIVO / ALARMA"
    }

    if(status==="SENSOR_TASK_STATUS_REQUIRED"){
        return "SIN TAREA"
    }

    if(status==="SENSOR_TASK_STATUS_COMPLETED"){
        return "COMPLETADO"
    }

    if(status==="SENSOR_TASK_STATUS_START_PENDING"){
        return "PENDIENTE POR ENCENDER"
    }

    if(status==="SENSOR_TASK_STATUS_STOP_PENDING"){
        return "DETENIDO"
    }

    return "N/A"
}

export const taskStatus2 =(status:string) =>{
    if(status==="TASK_STATUS_ACTIVE"){
        return "ACTIVO"
    }

    if(status==="TASK_STATUS_ACTIVE_WITH_ALARM"){
        return "ACTIVO / ALARMA"
    }

    if(status==="TASK_STATUS_SENSOR_REQUIRED"){
        return "SIN SENSOR"
    }

    if(status==="TASK_STATUS_SENSOR_COMPLETED"){
        return "COMPLETADO"
    }

    if(status==="TASK_STATUS_START_PENDING"){
        return "PENDIENTE"
    }

    if(status==="TASK_STATUS_STOP_PENDING"){
        return "DETENIDO PENDIENTE"
    }

    return "N/A"
}

export const sensorStatus =(status:string) =>{
    if(status==="SENSOR_STATUS_ACTIVE"){
        return "ACTIVO"
    }

    if(status==="SENSOR_STATUS_STOPPED"){
        return "DETENIDO"
    }

    if(status==="SENSOR_TASK_STATUS_REQUIRED"){
        return "geekblue"
    }

    if(status==="SENSOR_TASK_STATUS_COMPLETED"){
        return "blue"
    }

    if(status==="SENSOR_STATUS_ACTIVE_WITH_ALARM"){
        return "ACTIVO / ALARMA"
    }

    if(status==="SENSOR_TASK_STATUS_STOP_PENDING"){
        return "orange"
    }

    return ""
}