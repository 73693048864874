import { Skeleton } from 'antd'
import React from 'react'

interface Props {
    loading:boolean
    children?: React.ReactNode
}

const SkeletonAlert = ({loading, children}:Props) => {
  return (
    <>
        {loading ? [1,2,3].map(()=> <Skeleton paragraph={false} title  style={{width:"100%"}} active></Skeleton>)  : children}
    </>
  )
}

export default SkeletonAlert