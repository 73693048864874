import React, { useContext, useState } from 'react'
import { Asset } from '../../Models/Asset'
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import UpdateFormAsset from './UpdateFormAsset';
import { patchAsset } from '../../Api/Subsidiary';
import { patchAsset as patchAssetOrganization} from '../../Api/Organization';

//transalate
import { useTranslation } from 'react-i18next';

interface Props {
    asset?: Asset, 
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
    idSite?: number
}

const UpdateAsset = ({setOpen, asset, idSite}:Props) => { 
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState<boolean> (false)

    const {sendError, sesion} = useContext(PromiseSesionContext);

    const closeModal =() =>{
        setOpen!(false)
    }

    const onFinishMaster = async (values:any) => {
        try{
            setLoading(true)
            let res=sesion.context.rol==="Sucursal" ? await patchAsset(asset?.id|| 0, values) : await patchAssetOrganization (asset?.id|| 0, idSite|| 0, values)
            
            
            setLoading(false)
            setComplete(true)
        }
        catch(err){
            sendError({type: "ERROR", data:{message:t('activo.errorActualizar')}})
            setLoading(false)
        }
    }

  return (
    <div>
        <Spiner loading={loading}>
            {complete ? <FinishedSuccess titulo={t('basico.actualizacionCorrecta')}  onFunction={closeModal} /> : <UpdateFormAsset defaultValues={asset} onFinishMaster={onFinishMaster} />}
        </Spiner>
    </div>
  )
}

export default UpdateAsset