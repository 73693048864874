import { LeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    text:string
}

const BackButton = ({text}:Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

  return (
    <div style={{width:"100%"}}>
        <Button type="text" onClick={()=>{navigate(-1)}}><LeftOutlined />{t('basico.regresar')}</Button>
    </div>
  )
}

export default BackButton