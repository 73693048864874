import React, { useContext, useEffect, useState } from 'react'
import CardClient from '../../Components/CardClient/CardClient'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import CreateClientComponent from '../../Components/CreateClient/CreateClientComponent'
import { MenuItem } from '../../Models/MenuItem'
import TableOrganization from '../../Components/TableOrganization/TableOrganization'
import { Card, Col, Pagination, Row } from 'antd'
import { Organization } from '../../Models/Organization'

//api
import { getOrganizations } from '../../Api/Reseller'
import SkeletonOrganizationTable from '../../Components/SkeletonComponents/SkeletonOrganizationTable'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import ElementCard from '../../Components/ElementCard/ElementCard'

//style
import './Organizations.css'
import ElementCardOrganization from '../../Components/ElementCard/ElementCardOrganization'

const Organizations = () => {
    const [loading, setLoading] = useState(false);
    const [organizations, setOrganizations] = useState<Organization[]>([]);

    const { sendError } = useContext(PromiseSesionContext);


    const items=[
        {
          title: 'Informacion',
        },
        {
          title: 'Sensores',
        },
        {
          title: 'Usuario',
        },
      ]
    
      const menuItem:MenuItem ={
        label: <span>Crear</span>,
        key: '0',
        title: 'Crear Cliente',
        icon: <></>,
        component: <CreateClientComponent  />,
        type: 'MULTICONTENT',
        items: items,
        size: 'small',
        onConfirm: () => {getData()},
      }

      const getData = async () => {
        try{
        setLoading(true)
        const res = await getOrganizations()


        setOrganizations(res)
        setLoading(false)
        // setResellers(res)
        }
        catch(err){
          sendError({type: "ERROR", data:{message:"Error al obtener las organizaciones"}})
          setLoading(false)
        }
    
      }

      useEffect(() => {
        getData();
      }, []);

  return (
    <div >
      <Card title={"Gestión de organizaciones"} bodyStyle={{padding:"12px"}} extra={<FilterSimple menuItem={menuItem} />}>
        {/* <SkeletonOrganizationTable loading={loading}>
          <TableOrganization getFunction={getData} organizations={organizations}></TableOrganization>
        </SkeletonOrganizationTable> */}

        <div className='OrganizationsPage-list'>        
          
            {organizations.map((organization, index) => {
              return (
                 <ElementCardOrganization organization={organization} key={index} />

              )
            })}



        </div>
      </Card>

    </div>
  )
}

export default Organizations