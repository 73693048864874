import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';

interface Props{
    onFinishMaster: (values: any) => void

  }

const AddNotificationForm = ({onFinishMaster}:Props) => {

    const { t } = useTranslation();

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };

      const onFinish = (values: any) => {
        onFinishMaster(values)
        // onFinishMaster(values.dragger[0].originFileObj as File)
    };

  return (
    <div>
        <Form
        name="dynamic_form_item"
        {...formItemLayoutWithOutLabel}
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
        >
        <Form.List
        name="correo"
        rules={[
            {
            validator: async (_, names) => {
                if (!names || names.length < 1) {
                return Promise.reject(new Error(t('notificacion.correo1')));
                }
            },
            },
        ]}
        >
        {(fields, { add, remove }, { errors }) => (
            <>
            {fields.map((field, index) => (
                <Form.Item
                {...( formItemLayoutWithOutLabel)}
                label={''}
                required={false}
                key={field.key}

                >
                <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('notificacion.correoObligatorio'),
                    },
                    ]}
                    noStyle
                >
                    <Input placeholder={t('notificacion.correoDeNotificacion')} style={{ width: '80%' }} />
                </Form.Item>
                {fields.length > 1 ? (
                    <MinusCircleOutlined
                        style={{marginLeft:"5px"}}
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    />
                ) : null}
                </Form.Item>
            ))}
            <Form.Item>
                <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '80%' }}
                icon={<PlusOutlined />}
                >
                {t('basico.agregar')}
                </Button>
                <Form.ErrorList errors={errors} />
            </Form.Item>
            </>
        )}
        </Form.List>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
                    <Button type="primary" htmlType="submit">
                        {t('basico.enviar')}
                    </Button>
                </div>
        </Form>
    </div>
  )
}

export default AddNotificationForm