import { createContext } from 'react';
import { createMachine } from "xstate";


//models
import { User } from '../Models/User'


export const errorMachine = createMachine({
    id: "errorDataFetch",
    initial: "wating",
    context: { message: null, type:"error"} as {message: string | null, type:'info' | 'success' | 'error' | 'warning' | 'loading'},
    states: {
      wating: {
        on: {
            ERROR: { target: "error"  },
        },
        entry: "emptyMessage",

      },
      error: {
        after: { 100: "wating" },
        entry: "errorMessage",
      },

    },
  }, 
      /* actions */
  { actions: {
      // action implementations
      errorMessage:(context, event) => {
        context.message = event.data.message;
        context.type = event.data.type || "error"
        // context.user = event.data.user;
      },

      emptyMessage:(context, event) => {
        context.message = null;
        context.message = "error"
      }

    }
  });
