import { Table } from 'antd'
import React from 'react'
import { NotificationContact } from '../../Models/NotificationContact';
import { ColumnsType } from 'antd/es/table';
import ModalButton from '../ModalButton/ModalButton';
import { BsXLg } from 'react-icons/bs';
import { TagOutlined } from '@ant-design/icons';
import { MenuItem } from '../../Models/MenuItem';
import RemoveNotificationContact from '../RemoveNotificationContact/RemoveNotificationContact';
import { useTranslation } from 'react-i18next';

interface Props{
    notificationContact:NotificationContact[]
    getData: () => Promise<void>
  }

const TableNotificationsMovil = ({notificationContact, getData}:Props) => {
  const { t } = useTranslation();

    const columns: ColumnsType<NotificationContact> = [
        {
            title: t('basico.telefonos'),
            dataIndex: 'telefono',
            key: 'telefono',
            // responsive:["sm"]
        },    
        {
          title: t('basico.acciones'),
          key: 'acciones',
          dataIndex: 'Acciones',
          render: (_, {id}) =>{ 
            
            let menuItem:MenuItem =   {
              label: <span>Remover sensor</span>,
              title: t('notificacion.remover'),
              key: '1',
              icon: <TagOutlined />,
              component: <RemoveNotificationContact id={id}  />,
              type: 'CONTENT',
              items: [],
              size: 'small',
              onConfirm: () => {getData()},
        
            }
    
            return <ModalButton menuItem={menuItem} text={""}  icon={<BsXLg />}></ModalButton>},
        },

      ];

  return (
    <Table  scroll={{x:100}} pagination={false} columns={columns} dataSource={notificationContact} />
  )
}

export default TableNotificationsMovil