import React, { useState } from 'react'
import Card from '../Card/Card'
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary'
import { Button, Input } from 'antd'

//transalate
import { useTranslation, Trans } from 'react-i18next';

//styles
import "./FilterSections.css"
import ModalContent from '../ModalsContent/ModalContent'
import { MenuItem } from '../../Models/MenuItem'
import CreateClient from '../CreateClient/CreateClient'
import CreateClientComponent from '../CreateClient/CreateClientComponent'
import Title from 'antd/es/typography/Title'

interface Props {
  menuItem: MenuItem,
  title?: string,
  dontHaveCreate?: boolean
}


const FilterSimple = ({menuItem, title, dontHaveCreate}:Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();



  return (
    <>
      {/* <Card havePadding>
          <div className='FilterSimple-Component'>
              <h2>{title}</h2>
              {!dontHaveCreate && <Button type='primary' onClick={()=>{setIsModalOpen(true)}}>Crear</Button> }
          </div>
      </Card> */}
      <Button type='primary' onClick={()=>{setIsModalOpen(true)}}>{title || t('basico.crear')}</Button> 
      <ModalContent menuItem={menuItem} open={isModalOpen} setOpen={setIsModalOpen} />

    </>
  )
}

export default FilterSimple