import React, { useContext } from 'react'
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import EditNameForm from './EditNameForm';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { patchSensor } from '../../Api/Subsidiary';
import { patchSensor as patchSensorOrganization } from '../../Api/Organization';

//transalate
import { useTranslation, Trans } from 'react-i18next';

export type FieldType = {
    nombre?: string;
  };

interface Props{
    serial:string
    closeModal?:()=>void
}

const EditNameSensor = ({serial, closeModal}:Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<number>(0);
  const [messageResponse, setMessageResponse] = React.useState<string>("");
  const { t } = useTranslation();


  const {sendError, sesion} = useContext(PromiseSesionContext);

  const onFinish= async (values:any)=>{
    try{
        setLoading(true)
        const res = sesion.context.rol==="Sucursal" ? await patchSensor(serial,values) : patchSensorOrganization(serial,values)
        setMessageResponse(t('sensor.sensorActualizado'))
        setStep(1)
        setLoading(false)
  
      }
      catch(err){
        sendError({type: "ERROR", data:{message:t('sensor.errorSensorActualizar')}})
        setLoading(false)
      }
  }
    
  return (
    <div>
        <Spiner loading={loading}>
            {step === 0 ?
            <EditNameForm onFinishMaster={onFinish}/>
            :
            <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{closeModal!()}} />
            }
        </Spiner>
    </div>
  )
}

export default EditNameSensor