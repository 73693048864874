import React from 'react'

//styles
import "./ImageSelect.css"

interface Props{
    icon:string,
    setIcon: React.Dispatch<React.SetStateAction<string>>

}

const ImageSelect = ({icon, setIcon}:Props) => {


  return (
    <div className='imageSelect-component'>
        <div className={icon==="Nevera" ? 'imageSelect-itemSelected' : "imageSelect-item"} onClick={()=>{setIcon("Nevera")}}>
            <img src='/image/nevera.png' title="Nevera" />
        </div>

        <div className={icon==="Canastilla" ? 'imageSelect-itemSelected' : "imageSelect-item"} onClick={()=>{setIcon("Canastilla")}}>
            <img src='/image/canastilla.png' title="Canastilla" />
        </div>
    </div>
  )
}

export default ImageSelect