import React, { useState } from 'react'
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import DeleteSensor from './DeleteSensor';
import { deleteSensorToAsset } from '../../Api/Subsidiary';

interface Props{
  id:string
  serial:string
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteSensorByTask = ({id, serial, setOpen}:Props) => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState<boolean> (false)

  const closeModal =() =>{
    setOpen!(false)
  }

  const finish = async() => {
    try{
      setLoading(true)
      const res= await deleteSensorToAsset(id, serial)
      
      setLoading(false)
      setComplete(true)

    }
    catch(err){
      setLoading(false)
      setComplete(false)
    }

  }

  return (
    <div>
      <Spiner loading={loading}>
        {complete ? <FinishedSuccess titulo='La ubcacion fue actualizada correctamente'  onFunction={closeModal} /> : <DeleteSensor onCancel={closeModal} onFinish={finish} />}
      </Spiner>
    </div>
  )
}

export default DeleteSensorByTask