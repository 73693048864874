import { Button, Form, Input } from 'antd';
import React from 'react'
import { FieldType } from './EditNameSensor';

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    onFinishMaster: (values: any) => void
    // defaultValues?:FieldType
}

const EditNameForm = ({onFinishMaster}:Props) => {
  const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    style={{padding:"20px 0 0 0"}}
    // initialValues={defaultValues}
    >
        <Form.Item<FieldType>
        label={t('basico.nombre')}
        name="nombre"
        style={{width:"100%"}}
        rules={[{ required: true, message: t('basico.nombreError') }, {max:20, message:t('basico.max20')}]}
        >
        <Input />
        </Form.Item> 

        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.enviar')}
        </Button>
      </div> 

    </Form>
  )
}

export default EditNameForm