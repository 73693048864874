import { Button, Result } from 'antd'
import React, { useState } from 'react'


interface Props{
    onCancel: ()=>void
    onFinish:()=>void
  }

const DeleteSensor = ({onCancel, onFinish} :Props) => {
  const [text, setText]= useState<string>("Sensores asociados: ")

  return (
    <div>
        <Result
        status="info"
        title="¿Seguro que desea quitar el sensor?"
        />

        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Button type='default' onClick={()=>{onCancel()}}>
            Cancelar
          </Button>

          <Button type='primary' onClick={()=>{onFinish()}}>
            Aceptar
          </Button>
        </div>


    </div>
  )
}

export default DeleteSensor