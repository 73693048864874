import React, { useEffect, useRef, useState } from 'react'

import {APIProvider, Map, Marker, ControlPosition  } from '@vis.gl/react-google-maps';
import { CustomMapControl } from '../MapInputSelect/MapControl';
import MapHandler from '../MapInputSelect/MapHandler';
import { Button } from 'antd';

//transalate
import { useTranslation, Trans } from 'react-i18next';

const inputStyle= {
    boxShadow: 'inset 0 0 10px #eee !important',
    border: '2px solid #eee',
    width: '456px',
    height: '40px',
    marginLeft: '16px',
    borderRadius: '20px',
    fontWeight: '300 !important',
    outline: 'none',
    padding: '10px 20px',
    marginBottom: '10px',
  }

interface Props{
    onFinish:(values: {lat: number, lng: number}) => void
}

const SelectMap = ({onFinish}:Props) => {
    const { t } = useTranslation();
    const inputRef = useRef(null)
    const position1 = {lat: 4.626282790917398, lng: -74.1105804658393};
    const position2 = {lat: 4.716841164714643, lng: -74.2121252657813};

    const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

    const [mark, setMark] =
    useState<{lat: number, lng: number} | null>(null);

    useEffect(() => {
        if(selectedPlace){
            setMark({lat:selectedPlace?.geometry?.location?.lat()||0, lng:selectedPlace?.geometry?.location?.lng()||0})
        }
    }, [selectedPlace]);

  return (
    <>
    <div style={{ height: '350px', width: '100%' }}>
    <APIProvider apiKey={'AIzaSyDRsisJvgO8bZyiFvtX0_CxrntT_mPQMUY'}>
      <Map  mapId={'bf51a910020fa25a'} defaultCenter={position1} defaultZoom={10} disableDefaultUI onClick={(event) =>{if(event.detail){setMark({lat:event?.detail?.latLng?.lat||0, lng:event?.detail?.latLng?.lng||0})} }}  >
        <CustomMapControl
            controlPosition={ControlPosition.TOP_LEFT}
            onPlaceSelect={setSelectedPlace}
        />
        {/* <Marker position={position1} title={"Postobon 1"} icon={""} /> */}
        {mark && <Marker position={mark} title={"Postobon 2"} />}

        {/* <AdvancedMarker
          
          position={position1}
          title={'AdvancedMarker with customized pin.'}>
          <Pin background={'#22ccff'} borderColor={'#1e89a1'} scale={1.4}>
            👀
          </Pin>
        </AdvancedMarker> */}

        <MapHandler place={selectedPlace} />
      </Map>
    </APIProvider>



    </div>

    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" disabled={!mark} onClick={(e)=>{ if(mark)onFinish(mark) }} htmlType="submit">
          {t('basico.siguiente')}
        </Button>
    </div>
    </>

  )
}

export default SelectMap