
import React, { useContext, useEffect, useState } from 'react'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import { MenuItem } from '../../Models/MenuItem'
import CreateTaskComponent from '../../Components/CreateTask/CreateTaskComponent'
import TableTask from '../../Components/TableTask/TableTask'
import axios from 'axios'
import { Task } from '../../Models/Task'
import SkeletonTaskTable from '../../Components/SkeletonComponents/SkeletonTaskTable'
import { Card, Divider, Pagination } from 'antd'
import TaskFilters from '../../Components/TaskFilters/TaskFilters'

//api
import { getTasks as getTask } from '../../Api/Subsidiary'
import Spiner from '../../Components/Spiner/Spiner'
import ElementCardTask from '../../Components/ElementCard/ElementCardTask'
import NoData from '../../Components/NoData/NoData'
import ResellerFilters from '../../Components/ResellerFilters/ResellerFilters'
import { PromiseSesionContext } from '../../Machines/SesionMachine'

const Tasks = () => {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [tasksFilters, setTasksFilters] = useState<Task[]>([]);
    const [filters, setFilters] = useState<{nombre:string}>({nombre:""}); //filters

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [size, setSize] = useState(10);

    //paginate
    const [page, setPage] = useState<number>(1)
    const sizePages = 12

    //loading
    const [loading, setLoading] = useState(false);

    const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);


    const items=[
      {
        title: 'Informacion de la tarea',
      },
      {
        title: 'Detalle',
      },
    ]
  
    const menuItem:MenuItem ={
      label: <span>Crear</span>,
      key: '0',
      title: 'Crear Cliente',
      icon: <></>,
      component: <CreateTaskComponent />,
      type: 'MULTICONTENT',
      items: items,
      size: 'small',
      onConfirm: () => {},
    }

    const getTasks = (page?:number, size?:number) => {
      setLoading(true)
      let RequestPage = page ? page -1 : currentPage -1 
      let RequestSize = size ? size : 10
      let config = {
        headers: {
          'apikey': process.env.REACT_APP_KEY
        }
      }
  
      // axios.get(`https://api.zebra.com/v2/environmental/tasks?page=${RequestPage}&size=${RequestSize}&sort_field=SORT_FIELD_NAME&sort_order=SORT_ORDER_ASC&${((filters.status!==""&& filters.status!==undefined)&&"statuses=")}${filters.status}`, config)
      // .then(res => {
      //   const persons = res.data;
      //   setTotalElements(res.data.page_response.total_elements)
      //   setTotalPages(res.data.page_response.total_pages)
      //   setTasks(res.data.tasks)
      //   setLoading(false)
      // }).catch(err => {
      //   setLoading(false)
      // })
    }

    const getNewTasks = async() => {
      try{
        setLoading(true)
        const res= await getTask()

        let testArray = []
        testArray.push(... res)
        testArray.push(... res)
        testArray.push(... res)
        testArray.push(... res)
        testArray.push(... res)


        setTasks(testArray)
        setTasksFilters(testArray)
        if(res) setLoading(false)

      }
      catch(err){
        sendError({type: "ERROR", data:{message:"Error al obtener los usuarios"}})
        setLoading(false)

      }

    }

    const onChangePaginate = (page:number, size:number) => {
      // setTasks([])
      // setCurrentPage(page)
      // setSize(size)
      // getTasks(page, size)
    }
  
    const filterByName = ()=>{
      setTasksFilters(tasks.filter((t:Task) => t.taskDetails.name.toUpperCase().includes(filters.nombre.toUpperCase())))
  
    }

    // useEffect(() => {
    //   getTasks();
    // }, [filters]);

    useEffect(() => {
      if(filters.nombre!=""){
        filterByName()
      }
      else{
        setTasksFilters(tasks)
      }
    }, [filters]);

    useEffect(() => {
      getNewTasks();
    }, []);

  return (
    //extra={<FilterSimple menuItem={menuItem} title='Gestión de tareas'  />}
    <div>
        <Card title={"Gestión de tareas"} style={{height:"calc(100vh - 2rem)"}} bodyStyle={{padding:"12px", height:"92%"}}  >
            {/* <SkeletonTaskTable loading={loading}>

              <TaskFilters setFilters={setFilters} filters={filters}></TaskFilters>
              <Divider />
              <TableTask currentPage={currentPage}  onChange={onChangePaginate} totalElements={totalElements} tasks={tasks} size={size} setSize={setSize}></TableTask>
            </SkeletonTaskTable> */}

                <Spiner loading={loading}>
                  <NoData data={tasks} text='No hay tareas'>
                  <div className='Layout-Card-Paginate'>
                  <ResellerFilters filters={filters} setFilters={setFilters} />
                    
                    <div className='ResellerPage-list'>        
                        
                        {tasksFilters?.slice((page - 1) * sizePages, page * sizePages).map((task, index) => {
                          return (
                            <ElementCardTask propsStyles={{width:"24%"}}  task={task} />
                          )
                        })}

                    </div>

                    <div className='centerPaginate'>
                      <Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={tasksFilters.length} pageSize={sizePages} showSizeChanger={false} />
                    </div>
                  </div>

                  </NoData>
                </Spiner>
        </Card>
    </div>
  )
}

export default Tasks