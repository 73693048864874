import React from 'react'

//styles
import "./ButtonIcon.css"

interface Props{
    icon?:React.ReactElement
    qty?:number
    text?:string
}

const ButtonIcon = ({icon, qty, text}:Props) => {
  return (
    <button className='ButtonIcon-Component'> 
        {icon}
        <span>{text} {qty}</span>
    </button>
  )
}

export default ButtonIcon