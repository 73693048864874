import { Graph } from "../Models/Graph";

export const dataGraphAdmin:Graph = {
  isEmpty: ()=>(false),
nodes: [{
  id: 1,
  name: "Revendedor 1",
  type: "revendedor",
  color: "green",
  relations: [
      {id: 2,
      name: "Cliente 1",
      type: "cliente",
      relations: [],
      color: "green",
      priority: 0},
      {id: 3,
        name: "Cliente 2",
        type: "cliente",
        relations: [],
        color: "green",
        priority: 0},
      {id: 4,
      name: "Cliente 4",
      color: "green",
      type: "cliente",
      relations: [],
      priority: 0},

  ],
  priority: 0
},

{
  id: 5,
  name: "Revendedor 2",
  type: "revendedor",
  color: "green",
  relations: [
      {id: 6,
      name: "Cliente 5",
      type: "cliente",
      relations: [],
      color: "green",
      priority: 0},
      {id: 7,
        name: "Cliente 6",
        type: "cliente",
        color: "green",
        relations: [],
        priority: 0},
      {id: 8,
      name: "Cliente 7",
      type: "cliente",
      color: "green",
      relations: [],
      priority: 0},

  ],
  priority: 0
},

{
  id: 9,
  name: "Revendedor 3",
  type: "revendedor",
  color: "green",
  relations: [
      {id: 10,
      name: "Cliente 8",
      type: "cliente",
      relations: [],
      color: "green",
      priority: 0},
      {id: 11,
        name: "Cliente 9",
        type: "cliente",
        relations: [],
        color: "green",
        priority: 0},
      {id: 12,
      name: "Cliente 10",
      type: "cliente",
      color: "green",
      relations: [],
      priority: 0},

  ],
  priority: 0
},


]}




export const dataGraphClient:Graph = {
    isEmpty: ()=>(false),
  nodes: [{
    id: 13,
    name: "Castellana",
    type: "sede",
    color: "green",
    relations: [
        {id: 14,
        name: "Activo 1",
        type: "activo",
        relations: [],
        color: "green",
        priority: 0},
        {id: 15,
            name: "Activo 2",
            type: "activo",
            relations: [],
            color: "green",
            priority: 0},
        {id: 16,
        name: "Activo 3",
        color: "green",
        type: "activo",
        relations: [],
        priority: 0}
    ],
    priority: 0
  },

  {
    id: 17,
    name: "Candelaria",
    type: "sede",
    color: "green",
    relations: [
        {id: 18,
        name: "Activo 4",
        type: "activo",
        relations: [],
        color: "green",
        priority: 0},
        {id: 19,
            name: "Activo 5",
            type: "activo",
            relations: [],
            color: "green",
            priority: 0},
        {id: 20,
        name: "Activo 6",
        type: "activo",
        color: "green",
        relations: [],
        priority: 0}
    ],
    priority: 0
  }


]}

export const dataGraphAsset:Graph = {
  isEmpty: ()=>(false),
nodes: [{
  id: 21,
  name: "Nevera Helados",
  type: "activo",
  color: "green",
  relations: [
      {id: 22,
      name: "Sensor 1",
      type: "sensor",
      relations: [],
      color: "green",
      priority: 0},
      {id: 23,
        name: "Sensor 2",
        type: "sensor",
        relations: [],
        color: "green",
        priority: 0},
      {id: 24,
      name: "Sensor 3",
      type: "sensor",
      relations: [],
      color: "green",
      priority: 0},
      {id: 25,
        name: "Sensor 4",
        type: "sensor",
        color: "red",
        relations: [],
        priority: 0},

  ],
  priority: 0
},


]}



export const dataGraphSucursal:Graph = {
  isEmpty: ()=>(false),
nodes: [{
  id: 1,
  name: "Revendedor 1",
  type: "revendedor",
  color: "green",
  relations: [
      {id: 2,
      name: "Cliente 1",
      type: "cliente",
      relations: [],
      color: "green",
      priority: 0},
      {id: 3,
        name: "Cliente 2",
        type: "cliente",
        relations: [],
        color: "green",
        priority: 0},
      {id: 4,
      name: "Cliente 4",
      color: "green",
      type: "cliente",
      relations: [],
      priority: 0},

  ],
  priority: 0
},

{
  id: 5,
  name: "Revendedor 2",
  type: "revendedor",
  color: "green",
  relations: [
      {id: 6,
      name: "Cliente 5",
      type: "cliente",
      relations: [],
      color: "green",
      priority: 0},
      {id: 7,
        name: "Cliente 6",
        type: "cliente",
        color: "green",
        relations: [],
        priority: 0},
      {id: 8,
      name: "Cliente 7",
      type: "cliente",
      color: "green",
      relations: [],
      priority: 0},

  ],
  priority: 0
},

{
  id: 9,
  name: "Revendedor 3",
  type: "revendedor",
  color: "green",
  relations: [
      {id: 10,
      name: "Cliente 8",
      type: "cliente",
      relations: [],
      color: "green",
      priority: 0},
      {id: 11,
        name: "Cliente 9",
        type: "cliente",
        relations: [],
        color: "green",
        priority: 0},
      {id: 12,
      name: "Cliente 10",
      type: "cliente",
      color: "green",
      relations: [],
      priority: 0},

  ],
  priority: 0
},


]}



