import React from 'react'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    organization:string,
    subsidiary:string,
}

const ReportHeader = ({organization, subsidiary}:Props) => {
    const { t } = useTranslation();
  return (
    <div className='ReportHeader'>

        <div className='ReportHeader-left'>

            <img className='ReportLogo' src='/image/logo2.png'></img>

            <div className='ReportHeader-Text'>
                <div className='ReportHeader-titleText'>
                    <span></span>
                    <span>{organization} {t('basico.sucursal')} {subsidiary}</span>
                </div>

                {/* <div className='ReportHeader-titleText'>
                    <span>Subsidiary:</span>
                    <span>{subsidiary}</span>
                </div> */}
            </div>

        </div>

        {/* <div className='ReportHeader-right'>

            <div className='ReportHeader-titleText'>
                <span>Fecha de inicio del reporte:</span>
                <span>10/12/2023</span>
            </div>

            <div className='ReportHeader-titleText'>
                <span>Fecha Final del reporte:</span>
                <span>10/12/2023</span>
            </div>

        </div> */}

    </div>
  )
}

export default ReportHeader