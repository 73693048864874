import React, { useContext, useEffect, useState } from 'react'
import Spiner from '../Spiner/Spiner';
import { getResellers, postAssignApiKey } from '../../Api/Supplier';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { Reseller } from '../../Models/Reseller';
import AsignApiKeyForm from './AsignApiKeyForm';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    step?:number
    next?: () => void,
    prev?: () => void,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  }

const AsignApiKeyReseller = ({step, next, prev, setOpen}:Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [resellers, setResellers] = useState<Reseller[]>([]);
    const [messageResponse, setMessageResponse] = React.useState<string>("");
    const { t } = useTranslation();


    const {error, sendError} = useContext(PromiseSesionContext);

    const getData = async () => {
        try{
        setLoading(true)
        const res = await getResellers()
    
        setResellers(res)
        if(res) setLoading(false)
        }
        catch(err){
          sendError({type: "ERROR", data:{message:t('apikey.errorCarga')}})
          setLoading(false)
        }
      }

    useEffect(() => {
        getData();
    }, []);


    const onFinishClient = (values: any) => {
        try{
            setLoading(true)
            values.exp_date = `${values.date?.year()}-${values.date?.month()+1 < 10 ?"0": ""}${values.date?.month()+1}-${values.date?.date()<10 ?"0": ""}${values.date?.date()}T00:00:00Z`
            delete values.date
            sendRequest(values)
        }
        catch(err){
            sendError({type: "ERROR", data:{message:t('apikey.errorDatos')}})
            setLoading(false)
        }
      };

      const sendRequest = async (values: any) => {
        try{
            const id= values.reseller_id
            delete values.reseller_id
            const res = await postAssignApiKey(values, id)

            setMessageResponse(t('apikey.exito'))
            next!()

            setLoading(false)
        }
        catch(err:any){
            sendError({type: "ERROR", data:{message: err.response.data.message || t('apikey.error')}})
            setLoading(false)
        }
      };


  return (
    <div>
        <Spiner loading={loading}>
            {step===0 ? <AsignApiKeyForm onFinishMaster={onFinishClient} resellers={resellers} /> : <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{setOpen!(false)}} />}
        </Spiner>
    </div>
  )
}

export default AsignApiKeyReseller