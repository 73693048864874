import { Sensor } from "../Models/Sensor"

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Elements {
    title: string;
    content: string;
  
  }

export const red =1

export const createSimpleCardInfoElements = (key: "reseller" | "organizacion" | "asignado", elements:Sensor[], asignados?:string, sinAsignar?:string) => {
    let asignedSensors = elements.filter((sensor) => sensor[key] !== null ).length
    let unassignedSensors = elements.length - asignedSensors

    
    let newElements:Elements[] = []
    newElements.push({title:asignados || "Sensores asignados", content:asignedSensors.toString()})
    newElements.push({title:sinAsignar ||"Sensores sin asignar", content:unassignedSensors.toString()})

    return newElements
  }

  // export const createSimpleCardInfoElements = (key: "reseller" | "organizacion" | "asignado", elements:Sensor[]) => {
  //   let asignedSensors = elements.filter((sensor) => sensor[key] !== null ).length
  //   let unassignedSensors = elements.length - asignedSensors

    
  //   let newElements:Elements[] = []
  //   newElements.push({title:"Sensores asignados", content:asignedSensors.toString()})
  //   newElements.push({title:"Sensores sin asignar", content:unassignedSensors.toString()})

  //   return newElements
  // }

// const createCardInfoElements = (key: "reseller" | "organizacion" | "asignado", elements:Sensor[]) => {
//     let asignedSensors = elements.filter((sensor) => sensor[key] !== null).length
//     let unassignedSensors = elements.length - asignedSensors

    
//     let childs : {name:string, value:number}[] = []
//     elements.forEach((sensor) => {
//       if (sensor[key] !== null){
//         if(childs.find((c)=> sensor[key].nombre ===c.name)  ){
//           childs.find((c)=> sensor[key].nombre ===c.name)!.value += 1
//         }
//         else{
//           childs.push({name:sensor[key].nombre, value:1})
//         }
//       }
//     })

//     childs = childs.sort((a,b) => b.value - a.value)
//     let newElements:Elements[] = []
//     newElements.push({title:"Sensores asignados", content:asignedSensors.toString()})
//     newElements.push({title:"Sensores sin asignar", content:unassignedSensors.toString()})
//     newElements.push({title:"Clientes con mas sensores", content:childs[0].name || ""})
//     newElements.push({title:"Clientes con menos sensores", content:childs[childs.length-1].name || ""})

//     return newElements

  
//   }