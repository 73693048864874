import { Button, Result } from 'antd'
import React, { useContext, useState } from 'react'
import Spiner from '../Spiner/Spiner'
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess'
import { patchSiteDelete } from '../../Api/Organization'
import { useTranslation } from 'react-i18next'
import { PromiseSesionContext } from '../../Machines/SesionMachine'


interface Props{
    id:number
    idSite?:number
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  }

const DeleteSite = ({id, setOpen, idSite}:Props) => {
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState<boolean> (false)
    const { t } = useTranslation();
    const {sendError} = useContext(PromiseSesionContext);

    const closeModal =() =>{
        setOpen!(false)
      }
    
      const finish = async() => {
        try{
          setLoading(true)
          const res= await patchSiteDelete(id)
          
          setLoading(false)
          setComplete(true)
    
        }
        catch(err:any){
            sendError({type: "ERROR", data:{message:err.response.data.message || ""}})
            
          setLoading(false)
          setComplete(false)
        }
    
      }
    
  return (
    <div>
      <Spiner loading={loading}>
        {complete ? <FinishedSuccess titulo={t('site.deletedSite')}  onFunction={closeModal} /> :
        <div>
        <Result
        status="info"
        title={t('site.confirmDelete')}
        />

        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <Button type='default' onClick={()=>{closeModal()}}>
        {t('basico.cancelar')}
        </Button>

        <Button type='primary' onClick={()=>{finish()}}>
        {t('basico.aceptar')}
        </Button>
        </div>


        </div>}
    </Spiner>
    </div>
  )
}

export default DeleteSite