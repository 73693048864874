import React, { useContext } from 'react'
import Spiner from '../Spiner/Spiner';
import CreateSensorFile from './CreateSensorFile';

//transalate
import { useTranslation, Trans } from 'react-i18next';

//styles
import './CreateSensor.css'
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import { postSensorsFile } from '../../Api/Supplier';
import { postCreateArrayRequest, postSensorsFileReport } from '../../Api/Reseller';

interface Props{
    write?:boolean
    step?:number
    next?: () => void,
    prev?: () => void,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  }

const CreateSensors = ({step, next, prev, setOpen, write}:Props) => {
    const { t } = useTranslation();
    const {sendError} = useContext(PromiseSesionContext);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [messageResponse, setMessageResponse] = React.useState<string>("");

    const onFinish = async (file: any) => {
        try{
            setLoading(true)
            const formData = new FormData();
            formData.append('file', file)
            if(write){
              const res = await postSensorsFileReport(formData)
              setMessageResponse(t('basico.solicitudEnviada'))
  
              next!()
              setLoading(false)
            }
            else{
              const res = await postSensorsFile(formData)
              setMessageResponse(t('basico.solicitudEnviada'))
  
              next!()
              setLoading(false)
            }
        }
        catch(e:any){
            sendError({type: "ERROR", data:{message: e?.response?.data?.message  || t('basico.errorArchivo')}})
            setLoading(false)

        }
      };

      const onFinishWrite = async (sensors:any) => {
        try{
            setLoading(true)
            const res = await postCreateArrayRequest(sensors)
            setMessageResponse(t('basico.solicitudEnviada'))

            next!()
            setLoading(false)


        }
        catch(e){
            sendError({type: "ERROR", data:{message:t('basico.errorSolicitud')}})
            setLoading(false)

        }
      };



  return (
    <div>
      <Spiner loading={loading}>

        {step===0 ? <CreateSensorFile write={write} onFinishMaster={onFinish} onWrite={onFinishWrite} /> : <FinishedSuccess titulo={messageResponse} loading={loading} onFunction={()=>{setOpen!(false)}} />} 

      </Spiner>
    </div>
  )
}

export default CreateSensors