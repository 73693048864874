import { Button, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { Sensor } from '../../Models/Sensor'
import { sensorName } from '../../DataTest/Sensors'
import { sensorStatus } from '../../Functions/status'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
  sensors:Sensor[]
  onCancel: ()=>void
  onFinish:()=>void
}

const StopTask = ({sensors, onCancel, onFinish} :Props) => {
  const { t } = useTranslation();
  const [text, setText]= useState<string>("Sensores asociados: ")

  const createText = () => {
    let newText: string = text
    for (let index = 0; index < sensors.length; index++) {
      const element = sensors[index];
      newText = newText + `${element.serial_number} - ${sensorName(element.serial_number)} - ${sensorStatus(element.status)} ${index+1===sensors.length ? "." : ","} `
      
    }
    setText(newText)
  }

  useEffect(() => {
    createText()
  }, []);

  return (
    <div>
        <Result
        status="info"
        title={t('sensor.detenerSensor')}
        />

        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Button type='default' onClick={()=>{onCancel()}}>
          {t('basico.cancelar')}
          </Button>

          <Button type='primary' onClick={()=>{onFinish()}}>
            {t('basico.aceptar')}
          </Button>
        </div>


    </div>
  )
}

export default StopTask