import { Skeleton } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React from 'react'

interface Props {
    loading:boolean
    children?: React.ReactNode
}

const SkeletonSensorsTable = ({loading, children}:Props) => {
   const data=[1,2,3]

    const columns: ColumnsType<any> = [
      {
        title: 'Nombre',
        render: (_, record) => (
          <Skeleton.Input active></Skeleton.Input>

          ),
      },
      {
        title: 'Asignado',
        render: (_, record) => (
          <Skeleton.Input active></Skeleton.Input>

          ),
      },
        {
          title: 'Serial',
          render: (_, record) => (
            <Skeleton.Input active></Skeleton.Input>

            ),
        },

        {
          title: 'Bateria',
          render: (_, record) => (
            <Skeleton.Button active  />
            
            ),
        },
        {
          title: 'Estatus',
          render: (_, record) => (
            <Skeleton.Input active></Skeleton.Input>
            
            ),
      },  
      {
        title: 'Action',
        render: (_, record) => (
          <Skeleton.Button active  />
          
        ),
      },
      
        // {
        //     title: 'Ultima Tarea',
        //     key: 'taskDetails.name',
        //     render: (_, record) => (
        //         <Skeleton.Input active></Skeleton.Input>

            
        //         ),
        // },    
    

      ];
    
  return (
    <>
    {loading ?<Table scroll={{x:100}} columns={columns} dataSource={data} pagination={false} /> :
    children
    }
    </>

  )
}

export default SkeletonSensorsTable