import React from 'react'
import { Site } from '../../Models/Site'
import { Button, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
    onFinishMaster: (values: any) => void
    defaultValues?:Site
  }

const UpdateFormSubsidiary = ({onFinishMaster, defaultValues}:Props) => {
  const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={defaultValues}
    >
    <div className='CreateClient-Form'>
      {/* <Form.Item  style={{width:"100%"}} label="Ya tengo una franquicia creada" valuePropName="checked">
        <Switch checked={checked} onChange={(event)=>{setChecked(event)}} />
      </Form.Item> */}
        <Form.Item<Site>
        label={t('basico.nombre')}
        name="nombre"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.errorNombre') }, {max:20, message:t('basico.max20')}]}
        >
        <Input placeholder={t('site.placeHolderNombre')} />
        </Form.Item>    

        <Form.Item<Site>
        label={t('basico.descripcion')}
        name="descripcion"
        style={{width:"45%"}}
        
        rules={[{ required: true, message: t('basico.descripcionError') }, {max:30, message:t('basico.max30')}]}
        >
        <Input placeholder={t('site.placeHolderDescripcion')} />
        </Form.Item>

        <Form.Item<Site>
          label={t('basico.direccion')}
          name="direccion"
          style={{width:"100%"}}
          rules={[{ required: true, message: 'Debe escribir dirección' }, {max:50, message:t('basico.max50')}]}
          >
              <TextArea placeholder={t('site.placeHolderDireccion')} rows={4} />
          </Form.Item>


    </div>

      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.guardar')}
        </Button>
      </div>

    </Form>
  )
}

export default UpdateFormSubsidiary