import React, { useContext, useEffect, useState } from 'react'
import { Card, Pagination } from 'antd'
import { MenuItem } from '../../Models/MenuItem'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import AddNotifications from '../../Components/AddNotifications/AddNotifications'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { getNotifications } from '../../Api/Subsidiary'
import { NotificationContact } from '../../Models/NotificationContact'
import TableNotifications from '../../Components/TableNotifications/TableNotifications'
import { useTranslation } from 'react-i18next'

const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [notificationContact, setNotificationContact] = useState<NotificationContact[]>([]);
  const { t } = useTranslation();

  const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);

    const items=[
        {
          title:t('basico.correos'),
        },
        {
          title: t('basico.detalle'),
        },
      ]
    
      const menuItem:MenuItem ={
        label: <span>Crear</span>,
        key: '0',
        title: 'Crear Cliente',
        icon: <></>,
        component: <AddNotifications />,
        type: 'MULTICONTENT',
        items: items,
        size: 'small',
        onConfirm: () => {getData()},
      }

  const getData = async () =>{
    try{
      setLoading(true)
      // let newUsers:User[] = []
      let res = await getNotifications()

      res= res.filter( x => x.correo!==null)

      setNotificationContact(res)
      setLoading(false)

    // setUsers(newUsers)
    // setUsersFilter(newUsers)
    setLoading(false)

  }
  catch(err){
    setLoading(false)
    sendError({type: "ERROR", data:{message:t('notificacion.errorObtener')}})
  }
  }

  useEffect(() => {
    getData();
  }, []);
      
      
  return (
    <div>
        <Card title={t('notificacion.titulo')} style={{height:"calc(100vh - 2rem)", borderRadius:"0 8px 8px 0"}} bodyStyle={{padding:0, height:"90%", boxSizing:"border-box"}} extra={<FilterSimple title={t('basico.agregar')} menuItem={menuItem}/>}>
            {/* <SkeletonUsersTable loading={loading}> */}
            <div className='Layout-Card-Paginate'>
                {/* <UsersFilters filters={filters} setFilters={setFilters} /> */}

            <div className='users-table'>
                <TableNotifications getData={getData} notificationContact={notificationContact} />
                {/* <TableUsers users={usersFilter?.slice((page - 1) * sizePages, page * sizePages)}></TableUsers> */}
            </div>

            {/* <div className='centerPaginate'>
                {usersFilter.length>sizePages &&  <Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={usersFilter.length} pageSize={sizePages} showSizeChanger={false} />}
            </div> */}

            </div>
            {/* </SkeletonUsersTable> */}
        </Card>

    </div>
  )
}

export default Notification