import React from 'react'
import { FieldType } from './CreateSiteComponent'
import { Button, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props{
  onFinishMaster: (values: any) => void
  defaultValues?:FieldType
}

const CreateSite = ({onFinishMaster, defaultValues}:Props) => {
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    onFinishMaster(values)
  };
  
  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={defaultValues}
    >
      <div className='CreateClient-Form'>
        {/* <Form.Item  style={{width:"100%"}} label="Ya tengo una franquicia creada" valuePropName="checked">
          <Switch checked={checked} onChange={(event)=>{setChecked(event)}} />
        </Form.Item> */}
          <Form.Item<FieldType>
          label={t('basico.nombre')}
          name="nombre"
          style={{width:"45%"}}
          rules={[{ required: true, message: t('basico.errorNombre') }, {max:20, message:t('basico.max20')}]}
          >
          <Input placeholder={t('site.placeHolderNombre')} />
          </Form.Item>    

          <Form.Item<FieldType>
          label={t('basico.descripcion')}
          name="descripcion"
          style={{width:"45%"}}
          rules={[{ required: true, message: t('basico.descripcionError') }, {max:30, message:t('basico.max30')}]}
          >
          <Input placeholder={t('site.placeHolderDescripcion')} />
          </Form.Item>

          <Form.Item<FieldType>
          label={t('basico.direccion')}
          name="direccion"
          style={{width:"100%"}}
          rules={[{ required: true, message: t('basico.errorDireccion') }, {max:50, message:t('basico.max50')}]}
          >
              <TextArea placeholder={t('site.placeHolderDireccion')} rows={4} />
          </Form.Item>

          {/* <Form.Item<FieldType>
          label="Latitud"
          name="latitud"
          style={{width:"45%"}}
          rules={[{ required: true, message: 'Debe escribir una latitud' }, {max:20, message:"Maximo 20 caracteres"}]}
          >
          <Input />
          </Form.Item>     */}

          {/* <Form.Item<FieldType>
          label="Longitud"
          name="longitud"
          style={{width:"45%"}}
          rules={[{ required: true, message: 'Debe escribir una longitud' }, {max:20, message:"Maximo 20 caracteres"}]}
          >
          <Input />
          </Form.Item> */}

          <Form.Item<FieldType>
          label={t('basico.correo')}
          name="correo"
          style={{width:"100%"}}

          rules={[{max:50, message:t('basico.max50')}, {type:'email', message:t('basico.formato')}]}
          >
          <Input placeholder={"email@dominio.com"} />
          </Form.Item>
      </div>


      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>
    </Form>
  )
}

export default CreateSite