import { Skeleton, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

interface Props {
    loading:boolean
    children?: React.ReactNode
}

const SkeletonUsersTable = ({loading, children}:Props) => {
   const data=[1,2,3]

   const columns: ColumnsType<any> = [
    {
      title: 'Nombre',
      render: (_, record) => (
        <Skeleton.Input active></Skeleton.Input>

        ),
    },
    {
        title: 'Apellido',
        render: (_, record) => (
          <Skeleton.Input active></Skeleton.Input>
  
          ),
      },
      {
        title: 'Usuario',
        render: (_, record) => (
          <Skeleton.Input active></Skeleton.Input>

          
          ),
      },

      {
        title: 'Tipo',
        render: (_, record) => (
          <Skeleton.Button active  />
          
          ),
      },
    ];

  return (
    <>
    {loading ?<Table scroll={{x:100}} columns={columns} dataSource={data} pagination={false} /> :
    children
    }
    </>
  )
}

export default SkeletonUsersTable