import { FieldType } from "../Components/CreateReseller/CreateResellerComponent";
import { Reseller } from "../Models/Reseller";
import { ResponseContent } from "../Models/ResponseContent";
import { Sensor } from "../Models/Sensor";
import { User } from "../Models/User";
import { Request } from '../Models/Request';

import { getApi, patchApi, postApi, postApiFiles } from "./Api";
import { Apikey } from "../Models/Apikey";

const generateKey = async <T>(objectArray: Promise<Sensor[]>): Promise<Sensor[]> => {
    // Código de la función
    let response = await objectArray;
    for (let index = 0; index < response.length; index++) {
        response[index].key = (index+1).toString()
    }
    return response;
  };

export const getResellers = async () => {
    try{
        return await getApi<Reseller[]>('thermotrack/supplier/resellers')
    }
    catch(error){
        throw error;
    }
}

export const getSensors = async () => {
    try{
        return await generateKey( getApi<Sensor[]>('thermotrack/supplier/sensors'))
    }
    catch(error){
        throw error;
    }
}

export const getAvailableSensors = async () => {
    try{
        let sensors = await getApi<Sensor[]>('thermotrack/supplier/sensors/unassigned')
        for (let index = 0; index < sensors.length; index++) {
            sensors[index].key = (index+1).toString()
            
        }
        return await sensors
    }
    catch(error){
        throw error;
    }
}

export const getRequests = async () => {
    try{
        return await getApi<Request[]>('thermotrack/requests')
    }
    catch(error){
        throw error;
    }
}

export const getApiKeys = async () => {
    try{
        return await getApi<Apikey[]>('thermotrack/supplier/getApikeys')
    }
    catch(error){
        throw error;
    }
}

export const postReseller = async (createResellerDTO:FieldType, sensors:Sensor[], correo:string) => {
    try{
        return await postApi<ResponseContent>('thermotrack/supplier/addResellerAndSensors', {createResellerDTO, sensors, correo})

    }
    catch(error){
        throw error;
    }
}

export const postAddSensors = async (id:number, sensors:Sensor[]) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/supplier/${id}/addSensors`, {sensors})

    }
    catch(error){
        throw error;
    }
}

export const postAddUser = async (obj: object) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/supplier/addUsers`, obj)
    }
    catch(error){
        throw error;
    }
}

export const postSensorsFile = async (obj: FormData) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/supplier/addSensors`, obj)
    }
    catch(error){
        throw error;
    }
}

export const postAssignApiKey = async (obj: any, idReseller:number) => {
    try{
        return await postApi<ResponseContent>(`thermotrack/supplier/${idReseller}/assignApikey`, obj)
    }
    catch(error){
        throw error;
    }
}

export const getUsers= async () => {
    try{
        return await getApi<User[]>('thermotrack/supplier/users')
    }
    catch(error){
        throw error;
    }
}

export const getResellerDetail= async (id:number | string) => {
    try{
        return await getApi<Reseller>('thermotrack/supplier/resellerDetails/'+id)
    }
    catch(error){
        throw error;
    }
}

export const patchConfirmRequest= async (idRequest:number, idReseller:number) => {
    try{
        return await patchApi<Reseller>(`thermotrack/requests/${idRequest}/confirmRequest`, {})
    }
    catch(error){
        throw error;
    }
}

export const patchRejectRequest= async (idRequest:number, body:object) => {
    try{
        return await patchApi<Reseller>(`thermotrack/requests/${idRequest}/rejectRequest`, body)
    }
    catch(error){
        throw error;
    }
}

export const patchBlockUser= async ( userId:number) => {
    try{
        return await patchApi<any>(`thermotrack/supplier/user/${userId}/blocked`, {})
    }
    catch(error){
        throw error;
    }
}



