import { CloseOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import React, { useState } from 'react'
import { StepItem } from '../../Models/StepItem';

interface Props{
  step:number
  setStep: React.Dispatch<React.SetStateAction<number>>
  items?: StepItem[]
}

const ModalContentStepHeader = ({step, setStep, items}:Props) => {

      const onChange = (value: number) => {
        setStep((value> step || step===((items?.length||0)-1)) ? step : value);
      };

  return (
    <div className='ModalContentStepHeader'>
        <div className='ModalContentStepHeader-step'>
            <Steps size="small" current={step} items={items} onChange={onChange} />
        </div>
        

        {/* <div className='ModalContentStepHeader-CloseButton'>
            <CloseOutlined />
        </div> */}
    </div>
  )
}

export default ModalContentStepHeader