import { Button, Form, Input, Select } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { FieldType } from './ChangeRol';

interface Props{
    onFinishMaster: (values: any) => void
    defaultValues?:FieldType
}

const ChangeRolForm = ({onFinishMaster, defaultValues}:Props) => {
    const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

    
  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    style={{padding:"20px 0 0 0"}}
    initialValues={defaultValues}
    >
      <Form.Item<FieldType> label={t('basico.rol')}  style={{width:"100%"}} name="rol_id"  rules={[{ required: true, message: t('basico.errorRol') }]}>
          
          <Select style={{ width: "100%" }}>
            <Select.Option value={1}>Admin</Select.Option>
            <Select.Option value={2}>Usuario</Select.Option>

            
          </Select>
      </Form.Item>

        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.enviar')}
        </Button>
      </div> 

    </Form>
  )
}

export default ChangeRolForm