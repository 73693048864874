import React from 'react'
import { Apikey } from '../../Models/Apikey';
import Table, { ColumnsType } from 'antd/es/table';

//transalate
import { useTranslation, Trans } from 'react-i18next';



interface Props{
  apikeys:Apikey[]
}


const TableApikey = ({apikeys}:Props) => {
  const { t } = useTranslation();
  const columns: ColumnsType<Apikey> = [
    {
        title: t('basico.token'),
        dataIndex: 'token',
        key: 'token',
        responsive:["sm"]
    },    
    {
        title: t('basico.apikey'),
        dataIndex: 'apikey',
        key: 'apikey',
        responsive:["sm"]

    },
    {
      title: t('basico.fechaVencimiento'),
      dataIndex: 'exp_date',
      key: 'exp_date',

    },
    {
      title: t('basico.revendedor'),
      dataIndex: 'reseller_nombre',
      key: 'reseller_nombre',

    },



  ];

  return (
    <Table   pagination={false} columns={columns} dataSource={apikeys} />

  )
}

export default TableApikey