import React from 'react'
import ReportListAlarms from './ReportListAlarms'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  alarms: any
}

const ReportAlarmDetail = ({alarms}:Props) => {
  const { t } = useTranslation();
  return (
    <div className='ReportAlarmDetail ReportLayot'>
      <div className='ReportSub-title'>
        <span>{t('activo.detalleAlarma')}</span>
      </div>  

        {alarms.map((a:any) =>  <ReportListAlarms alarm={a} />)}

    </div>
  )
}

export default ReportAlarmDetail