import React, { useState } from 'react'
import { Asset } from '../../Models/Asset'
import { Task } from '../../Models/Task'
import ElementCard from './ElementCard'
import { MenuItem } from '../../Models/MenuItem'
import { ExceptionOutlined } from '@ant-design/icons'
import TaskCompleteDetail from '../TaskDetail/TaskCompleteDetail'
import { taskStatus2 } from '../../Functions/status'
import { taskColors2 } from '../../Functions/colors'

interface Props{
    task: Task, 
    propsStyles?: React.CSSProperties 
    action?: (id: string | number) => void
    notShowMenu?: boolean
    menuItems?:MenuItem[]
}

const ElementCardTask = ({task, propsStyles, action, notShowMenu}:Props) => {
    const [menuItems] = useState<MenuItem[]>([
        {
            label: <span>Detalle</span>,
            title: 'Detalle tarea',
            key: '3',
            icon: <ExceptionOutlined />,
            component: <TaskCompleteDetail taskID={task.id} />,
            type: 'CONTENT',
            items: [],
            size: 'large',
            onConfirm: () => {},
          }
    ]);

  return (
    <ElementCard  id={task.id}
                  propsStyles={propsStyles}
                  onClick={action ? action : undefined} 
                  menuItems={notShowMenu ? undefined :menuItems} 
                  title={task.taskDetails.name} 
                  tempLow={task.taskDetails?.alarm_low_temp}
                  tempMax={task.taskDetails?.alarm_high_temp}

                  description={""} 
                  image='' 
                  status={taskStatus2(task.status)} 
                  statusColor={taskColors2(task.status)}
                  dontShowStatus/>

  )
}

export default ElementCardTask