import React, { useContext, useEffect, useState } from 'react'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import { MenuItem } from '../../Models/MenuItem'
import TableSensor from '../../Components/TableSensor/TableSensor'
import { Sensor } from '../../Models/Sensor'
import SkeletonSensorsTable from '../../Components/SkeletonComponents/SkeletonSensorsTable'
import {Button, Card, Pagination } from 'antd'

//transalate
import { useTranslation, Trans } from 'react-i18next';

//api
import { getSensors } from '../../Api/Supplier'
import { getSensors as getsensorsReseller } from '../../Api/Reseller'
import { getSensors as getsensorsOrganization, getUpdateLocation } from '../../Api/Organization'
import { getSensors as getsensorsSubsidiary , getUpdateLocation as getUpdateLocationSubsidiary} from '../../Api/Subsidiary'



import TableSensorBasic from '../../Components/TableSensor/TableSensorBasic'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import SkeletonSensorBasicTable from '../../Components/SkeletonComponents/SkeletonSensorBasicTable'
import SingleCardInformation from '../../Components/SingleCardInformation/SingleCardInformation'
import SensorFilters from '../../Components/SensorFilters/SensorFilters'
import CreateSensors from '../../Components/CreateSensors/CreateSensors'
import TableSensorSite from '../../Components/TableSensor/TableSensorSite'
import SkeletonSensorSiteTable from '../../Components/SkeletonComponents/SkeletonSensorSiteTable'

interface Elements {
  title: string;
  content: string;

}

const Sensors = () => {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [sensorsFilters, setSensorsFilters] = useState<Sensor[]>([]);
  const [rol, setRol] = useState<string>("supplier") //supplier, reseller, organization, site
  const {sesion, sendSesion } = useContext(PromiseSesionContext);

  const [filters, setFilters] = useState<{status:string, nombre:string }>({status:"", nombre:""}); //filters

  const [cardInfoElements, setCardInfoElements] = useState<Elements[]>([]); //filters

  const { sendError } = useContext(PromiseSesionContext);
  const { t } = useTranslation();

  //paginate
  const [page, setPage] = useState<number>(1)
  const sizePages = 6

  //loading
  const [loading, setLoading] = useState(false);

  const items=[
    {
      title: t('basico.cargarArchivo'),
    },
    {
      title: t('basico.detalle'),
    },
  ]

  const menuItem:MenuItem ={
    label: <span>Cargar sensores</span>,
    key: '0',
    title: 'Agregar sensores',
    icon: <></>,
    component: <CreateSensors />,
    type: 'MULTICONTENT',
    items: items,
    size: 'small',
    onConfirm: () => {getSensorsByRole()},
  }

  const menuItemUpdateLocation:MenuItem ={
    label: <span>Cargar sensores</span>,
    key: '0',
    title: 'Agregar sensores',
    icon: <></>,
    component: <CreateSensors />,
    type: 'MULTICONTENT',
    items: items,
    size: 'small',
    onConfirm: () => {getSensorsByRole()},
  }


  const createCardInfoElements = (key: "reseller" | "organizacion", elements:Sensor[]) => {
    let asignedSensors = elements.filter((sensor) => sensor[key] !== null).length
    let unassignedSensors = elements.length - asignedSensors

    
    let childs : {name:string, value:number}[] = []
    elements.forEach((sensor) => {
      if (sensor[key] !== null){
        if(childs.find((c)=> sensor[key].nombre ===c.name)  ){
          childs.find((c)=> sensor[key].nombre ===c.name)!.value += 1
        }
        else{
          childs.push({name:sensor[key].nombre, value:1})
        }
      }
    })

    childs = childs.sort((a,b) => b.value - a.value)
    let newElements:Elements[] = []
    newElements.push({title:t('sensor.sensorCard1'), content:asignedSensors.toString()})
    newElements.push({title:t('sensor.sensorCard2'), content:unassignedSensors.toString()})
    newElements.push({title:t('sensor.sensorCard3'), content:childs[0]?.name || "N / A"})
    newElements.push({title:t('sensor.sensorCard4'), content:childs[childs.length-1]?.name || "N / A"})

    setCardInfoElements(newElements)
  }
  
  const createEmptyCards = () =>{
    let newElements:Elements[] = []
    newElements.push({title:t('sensor.sensorCard1'), content:"0"})
    newElements.push({title:t('sensor.sensorCard2'), content:"0"})
    newElements.push({title:t('sensor.sensorCard3'), content:"0"})
    newElements.push({title:t('sensor.sensorCard4'), content:"0"})

    setCardInfoElements(newElements)
  }

  const getSensorsByRole = async () => {
    try{
      setLoading(true)
      let newSensor:Sensor[] = []
      switch (sesion.context.rol) {
        case "Proveedor":
          newSensor = await getSensors()
          if(newSensor.length>0){createCardInfoElements("reseller", newSensor)}
          else {createEmptyCards()}
          break;
        case "Reseller":
          newSensor = await getsensorsReseller()
          break;
        case "Organizacion":
          newSensor = await getsensorsOrganization()
          break;
        case "Sucursal":
          newSensor = await getsensorsSubsidiary()
          break;
        default:
          break;
    }

    setSensorsFilters(newSensor)
    setSensors(newSensor)
    setLoading(false)

  }
  catch(err){

    setLoading(false)
    sendError({type: "ERROR", data:{message:t('sensor.errorObtener')}})

  }

  }

  const updateLocations = async () => {
    try{
      setLoading(true)
      let res = sesion.context.rol==="Organizacion" ? await getUpdateLocation() : await getUpdateLocationSubsidiary()
      getSensorsByRole()
     // setLoading(false)

  }
  catch(err){

    setLoading(false)
    sendError({type: "ERROR", data:{message:t('sensor.errorObtener')}})
    getSensorsByRole()


  }

  }

  const filterBySerial = ()=>{
    if(filters.nombre!=""){
      setSensorsFilters(filterByStatus(sensors).filter((x:Sensor) => x.numero_de_serie.includes(filters.nombre)))
    }
    else{
      setSensorsFilters(filterByStatus(sensors))
    }
  }

  const filterByStatus = (sensorArray:Sensor[]):Sensor[] =>{
    if(filters.status=="Asignados"){

      return sensorArray.filter((sensor:Sensor) => sensor.reseller || sensor.organizacion || sensor.sucursal )
    }
    else if(filters.status=="Sin asignar"){

      return sensorArray.filter((sensor:Sensor) => !sensor.reseller && !sensor.organizacion && !sensor.sucursal)
    }
    else{
      return sensors
    }
  }

  useEffect(() => {
    getSensorsByRole();
  }, []);

  let styleCard ={
    width: "24%",
  }

  useEffect(() => {
    if(sensors.length>0){
      filterBySerial()
    }
  }, [filters]);

  return (
    <div>
        {/* <FilterSimple menuItem={menuItem} dontHaveCreate title='Gestion de sensores' /> */}

        {(sesion.context.rol==="Proveedor" || sesion.context.rol==="Reseller" ) &&
        <div className='fullListCardInformation' style={{height:"15vh"}}>

          {!loading && cardInfoElements.map((element, index) => {
            return <SingleCardInformation elementClass='Card-f-cards' title={element.title} value={element.content} />

          } )}

          {loading &&
          ( <>
                <SingleCardInformation elementClass='Card-f-cards' title={t('sensor.sensorCard1')} value={""} loading />
                <SingleCardInformation elementClass='Card-f-cards' title={t('sensor.sensorCard2')} value={""} loading />
                <SingleCardInformation elementClass='Card-f-cards' title={t('sensor.sensorCard3')} value={""} loading />
                <SingleCardInformation elementClass='Card-f-cards' title={t('sensor.sensorCard4')} value={""} loading />
            </>)
          }

        </div>}

        <Card extra={ (sesion.context.rol==="Proveedor" ) ?  <FilterSimple menuItem={menuItem} title={t('sensor.crear') } /> : (sesion.context.rol==="Organizacion" || sesion.context.rol==="Sucursal" ) ? <Button type='primary' onClick={()=>{updateLocations()}}>{ t('sensor.actualizarUbicacion')}</Button>  : <></>} title={t('sensor.title')} style={{height:(sesion.context.rol==="Organizacion" || sesion.context.rol==="Sucursal") ? "calc(100vh - 2rem)" : "calc(100vh - 15vh - 3rem)"}} bodyStyle={{padding:0, height:(sesion.context.rol==="Organizacion" || sesion.context.rol==="Sucursal") ? "82%" :"72%"}}  >
            <SensorFilters notShowAsign={(sesion.context.rol==="Organizacion" || sesion.context.rol==="Sucursal") ? true : false} filters={filters} setFilters={setFilters} />
            <div className='Layout-Card-Paginate'>
            {(sesion.context.rol==="Sucursal") && <SkeletonSensorSiteTable loading={loading}> <TableSensorSite sensors={sensorsFilters?.slice((page - 1) * sizePages, page * sizePages)} ></TableSensorSite></SkeletonSensorSiteTable>}
            {(sesion.context.rol==="Organizacion") && <SkeletonSensorsTable loading={loading}> <TableSensor getData={getSensorsByRole} sensors={sensorsFilters?.slice((page - 1) * sizePages, page * sizePages)} ></TableSensor></SkeletonSensorsTable>}
            { (sesion.context.rol==="Proveedor" || sesion.context.rol==="Reseller" ) && <SkeletonSensorBasicTable loading={loading} > <TableSensorBasic sensors={sensorsFilters?.slice((page - 1) * sizePages, page * sizePages)} rol={rol} /> </SkeletonSensorBasicTable>}
          
            <div className='centerPaginate'>
              {sensorsFilters.length > sizePages &&<Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={sensors.length} pageSize={sizePages} showSizeChanger={false} />}
            </div>

            </div>
        </Card>
    </div>
  )
}

export default Sensors