import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ENLOGIN, ESLOGIN } from './Translate/Login';
import { ENRESELLER, ESRESELLER } from './Translate/Reseller';
import { ENBASIC, ESBASIC } from './Translate/Basic';
import { ENSOLICITUD, ESSOLICITUD } from './Translate/Request';
import { ENSENSOR, ESSENSOR } from './Translate/Sensor';
import { ENAPIKEY, ESAPIKEY } from './Translate/Apikey';
import { ENORGANIZACION, ESORGANIZACION } from './Translate/Organizacion';
import { ENSITE, ESSITE } from './Translate/Sites';
import { ENACTIVO, ESACTIVO } from './Translate/Activo';
import { ENUSUARIO, ESUSUARIO } from './Translate/User';
import { ENNOTIFICACION, ESNOTIFICACION } from './Translate/Notificaciones';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            ...ENLOGIN,
            ...ENRESELLER,
            ...ENBASIC,
            ...ENSOLICITUD,
            ...ENSENSOR,
            ...ENAPIKEY,
            ...ENORGANIZACION,
            ...ENSITE,
            ...ENACTIVO,
            ...ENUSUARIO,
            ...ENNOTIFICACION
        }
      },
      es: {
        translation: {
            ...ESLOGIN,
            ...ESRESELLER,
            ...ESBASIC,
            ...ESSOLICITUD,
            ...ESSENSOR,
            ...ESAPIKEY,
            ...ESORGANIZACION,
            ...ESSITE,
            ...ESACTIVO,
            ...ESUSUARIO,
            ...ESNOTIFICACION

        }
      }
    }
  });

export default i18n;