import { Alert, Button, Form, Input, Select } from 'antd'
import Slider, { SliderMarks } from 'antd/es/slider';
import React from 'react'
import { energyClassification } from '../../Models/EnergyClassification';

//transalate
import { useTranslation, Trans } from 'react-i18next';

export type FieldType = {
    min?: string;
    max?: number;
    range?:number[];
    category?:string;
  };

interface Props{
    onFinishMaster: (values: any) => void
    categories:energyClassification[]
    initialValues?: FieldType}

const CreateAssetEnergy = ({onFinishMaster, initialValues, categories}:Props) => {
    const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

      const marks: SliderMarks = {
        [-40]: '-40°C',
        [-20]: '-20°C',
        0: '0°C',
        20: '20°C',
        37: '37°C',
        50: '50°C',
      };
      
  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={initialValues}
    >
    <div className='CreateClient-Form'>
      {/* <Form.Item  style={{width:"100%"}} label="Ya tengo una franquicia creada" valuePropName="checked">
        <Switch checked={checked} onChange={(event)=>{setChecked(event)}} />
      </Form.Item> */}
        <Form.Item<FieldType>
        label={t('activo.temperaturaActivo')}
        name="range"
        style={{width:"100%"}}
        rules={[{ required: true, message: t('activo.errorTemperatura') }]}
        className='sliderForm'
        hasFeedback
        >
            <Slider  range min={-40} max={50} marks={marks}  />
        </Form.Item>

   

        <Form.Item<FieldType>
        label={t('activo.categoria')}
        name="category"
        style={{width:"100%"}}
        rules={[{ required: true, message: t('activo.errorCategoria') }]}
        >
        <Select placeholder={t('activo.placeHolderCategoria')}>
            {categories.map((x)=>{
                return <option value={x.id} >{x.categoria_consumo}: {x.min_consumo} - {x.max_consumo}</option>
            })}
          
        </Select>
        </Form.Item> 

        <Alert style={{margin:"0 0 1rem 0", boxSizing:"border-box", width:"100%"}} message={t('activo.mensajeEnergeticoActivo')} type="info" showIcon />

    </div>

      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>

    </Form>
  )
}

export default CreateAssetEnergy