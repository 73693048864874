

export const ENNOTIFICACION = {
    notificacion: {
        titulo:"Notification Management",
        remover:"Remove email",
        romoverTelefono:"Remove phone",

        mensajeRemover:"Are you sure you want to delete this email?",

        errorObtener:"Error retrieving emails",
        eliminado:"Email deleted",
        correoDeNotificacion:"Notification email",
        correoObligatorio:"Email is mandatory",
        correo1:"At least one email"

      }
}

export const ESNOTIFICACION = {
    notificacion: {
        titulo:"Gestión de notificaciones",
        remover:"Remover correo",
        romoverTelefono:"Remover telefono",

        mensajeRemover:"¿Seguro que desea eliminar este correo?",

        errorObtener:"Error al obtener los correos",
        eliminado:"Correo eliminado",
        correoDeNotificacion:"Correo de notificacion",
        correoObligatorio:"El correo es obligatorio",
        correo1:"Por lo menos un correo"

      }
}