import React, { useContext, useState } from 'react'
import { Site } from '../../Models/Site'
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import Spiner from '../Spiner/Spiner';
import FinishedSuccess from '../FinishedSuccess/FinishedSuccess';
import UpdateFormSubsidiary from './UpdateFormSubsidiary';
import { patchSubsidiary } from '../../Api/Organization';

//transalate
import { useTranslation } from 'react-i18next';

interface Props {
    site?: Site, 
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const UpdateSubsidiary = ({setOpen, site}:Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState<boolean> (false)

    const {sendError} = useContext(PromiseSesionContext);

    const closeModal =() =>{
        setOpen!(false)
    }

    const onFinishMaster = async (values:any) => {
        try{
            setLoading(true)
            let res= await patchSubsidiary(site?.id||0,values)
            
            setLoading(false)
            setComplete(true)
        }
        catch(err){
            sendError({type: "ERROR", data:{message:t('site.errorActualizacion')}})
            setLoading(false)
        }
    }

  return (
    <div>
        <Spiner loading={loading}>
            {complete ? <FinishedSuccess titulo={t('site.mensajeActualizacion')}  onFunction={closeModal} /> : <UpdateFormSubsidiary defaultValues={site} onFinishMaster={onFinishMaster} />}
        </Spiner>
    </div>
  )
}

export default UpdateSubsidiary