

export const generateTempFormat = (temp:number) =>{
    try{
        return `${temp.toFixed(2)} C°`
    }
    catch{
        return `Temperatura no valida`
    }
}
