import React from 'react'

import {APIProvider, Map, Marker, AdvancedMarker, Pin} from '@vis.gl/react-google-maps';
import { Site } from '../../Models/Site';
import MapHandler from '../MapInputSelect/MapHandler';


interface Props{
  findAction:(id: string | number) => void
    sites:Site[]
    location?:{lat: number, lng: number} | null
}

const MapApp = ({sites, findAction, location}:Props) => {
  const position1 = {lat: 4.626282790917398, lng: -74.1105804658393};
  
  return (
    <div style={{ height: '100%', width: '100%' }}>
    <APIProvider apiKey={'AIzaSyDRsisJvgO8bZyiFvtX0_CxrntT_mPQMUY'}>
      <Map  mapId={'bf51a910020fa25a'} defaultCenter={sites.length>0 ? {lat:Number(sites[0].latitud), lng:Number(sites[0].longitud)} : location ? location :position1} defaultZoom={13} disableDefaultUI  >
        {/* <Marker position={position1} title={"Postobon 1"} icon={""} /> */}

        {
          sites.map((site)=>{
            let haveAlarm = false

            for (let index = 0; index < site.sensor.length; index++) {
              const element = site.sensor[index];

              haveAlarm = element.tarea[0]?.alarm_count ? true : false

              if(haveAlarm){
                break
              }
              
            }

            return <AdvancedMarker
            onClick={()=>findAction(site.id)}
            position={{lat:Number(site.latitud), lng:Number(site.longitud)}}>
            <Pin background={haveAlarm? "" :'#38b000'} borderColor={haveAlarm? "" : '#006400'} glyphColor={haveAlarm? "" : '#006400'} scale={1.4}>
            </Pin>
          </AdvancedMarker>
          })
        }


        {location &&
        <AdvancedMarker
        position={location}
        title={'AdvancedMarker with customized pin.'}>
        <Pin  scale={1.4}>
        </Pin>
      </AdvancedMarker>}
        
      </Map>
    </APIProvider>

    </div>
  )
}

export default MapApp