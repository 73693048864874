import React from 'react'

import { Button, Form, Input, Select } from 'antd'

//transalate
import { useTranslation} from 'react-i18next';

import "./CreateUser.css"
import { FieldType } from './CreateUserComponent'
import { PHONECODE } from '../../statics/PhoneCode';

interface Props{
    onFinishMaster: (values: any) => void
    initialValues: FieldType

  }

const CreateUser = ({onFinishMaster, initialValues}:Props) => {
  const { t } = useTranslation();
    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

      function compareFn(a:any, b:any) {
        return Number(a.dial_code) - Number(b.dial_code)
      }

      const prefixSelector = (
        <Form.Item name="prefix" noStyle rules={[{ required: true, message: t('basico.errorPrefijo') }]}>
          
          <Select style={{ width: 120 }}>
            {PHONECODE.sort(compareFn).map((x)=>{
              return <Select.Option value={"+"+x.dial_code}>+{x.dial_code} - {x.code}</Select.Option>
            })}
            
          </Select>
        </Form.Item>
      );

  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={initialValues}
    >
    <div className='CreateUser-Form'>
      <Form.Item<FieldType>
      label={t('basico.nombre')}
      name="nombres"
      style={{width:"45%"}}
      rules={[{ required: true, message: t('basico.errorNombre') }, {max:20, message:t('basico.max20')}]}
      >
      <Input placeholder={t('usuario.placeHolderNombre')} />
      </Form.Item>    

      <Form.Item<FieldType>
      label={t('basico.apellido')}
      name="apellidos"
      style={{width:"45%"}}
      rules={[{ required: true, message: t('basico.errorApellido') }, {max:20, message:t('basico.max20')}]}
      >
      <Input placeholder={t('usuario.placeHolderApellido')} />
      </Form.Item>

      <Form.Item<FieldType>
      label={t('basico.correo')}
      name="correo"
      style={{width:"100%"}}

      rules={[{ required: true, message: t('basico.errorCorreo') }, {max:50, message:t('basico.max50')}, {type:'email', message:t('basico.formato')}]}
      >
      <Input placeholder='email@dominio.com' />
      </Form.Item>

      <Form.Item<FieldType>
        name="telefono"
        label={t('basico.telefono')}
        style={{width:"100%"}}
        rules={[{ required: true, message: t('basico.errorTelefono') }, {type:"number", message:t('basico.formato'), transform(value) {
          return Number(value)
        }} , {max:12, message:t('basico.max12')}]}
      >
        <Input placeholder='000-000-000-' addonBefore={prefixSelector} style={{ width: '100%' }} />
      </Form.Item>


      <Form.Item<FieldType>
      label={t('basico.usuario')}
      name="usuario"
      style={{width:"45%"}}
      rules={[{ required: true, message: t('basico.errorUsuario') }, {max:20, message:t('basico.max20')}]}
      >
      <Input placeholder={t('usuario.placeHolderUsuario')} />
      </Form.Item>

      <Form.Item<FieldType>
      label={t('basico.clave')}
      name="contrasena"
      style={{width:"45%"}}
      rules={[{ required: true, message: t('basico.errorClave') }, {max:20, message:t('basico.max20')}]}
      >
      <Input placeholder={t('usuario.placeHolderClave')} />
      </Form.Item>

      <Form.Item<FieldType> label={t('basico.rol')}  style={{width:"100%"}} name="rol_id"  rules={[{ required: true, message: t('basico.errorRol') }]}>
          
          <Select style={{ width: "100%" }}>
            <Select.Option value={1}>Admin</Select.Option>
            <Select.Option value={2}>Usuario</Select.Option>

            
          </Select>
      </Form.Item>



      

    </div>


      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          Crear
        </Button>
      </div>
    </Form>
  )
}

export default CreateUser