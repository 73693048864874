import React, { useContext, useEffect } from 'react'
import UserManageForm, { FieldType } from '../../Components/UserManageForm/UserManageForm'
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { authRegister, newUserRegister } from '../../Api/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ConfirmAccount = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const params = useParams();
    const [saveData, setSaveData] = React.useState<FieldType>();
  
    const navigate = useNavigate();

    const { sendError } = useContext(PromiseSesionContext);


  const validateToken = async ()=>{
    try{
      setLoading(true)
      const res = await authRegister(params.user || "", params.code || "")
      setLoading(false)
      }
      catch(err){

        sendError({type: "ERROR", data:{message:t('login.errorComprobar')}})
        navigate("/Login")
  
      }
  }

  const onFinishMaster = (values:string)=>{
    createUser(values)
  }

  const createUser = async(values:any) =>{
    try{
      setLoading(true)
      setSaveData(values)
      values.codigo = params.code || ""
      const res = await newUserRegister(params.user || "" ,values)
      navigate("/Login")

      }
      catch(err:any){
        sendError({type: "ERROR", data:{message:err?.response?.data?.message ||t('login.errorCrear')}})
        setLoading(false)
  
      }

  }

  useEffect(() => {
    validateToken()
  }, []);

    
  return (
    <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%"}}>
        
        <UserManageForm defaultValues={saveData} onFinishMaster={onFinishMaster} masterLoading={loading} />
    </div>
  )
}

export default ConfirmAccount