import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Form, InputNumber, Radio, Select } from 'antd'
import React, { useState } from 'react'

//transalate
import { useTranslation, Trans } from 'react-i18next';

import "./CreateAddSensorQty.css"
import Table, { ColumnsType } from 'antd/es/table'
import { Sensor } from '../../Models/Sensor'

interface Props{
  back:()=>void
  onFinishMaster: (values: any) => void,
  autoAsign?:boolean
  sensors:Sensor[]
  selectedSensors?:Sensor[]
  notRequiredSensors?:boolean
  maxSensors?:number
}





const CreateAddSensorQty = ({back, onFinishMaster, autoAsign, sensors, selectedSensors, notRequiredSensors, maxSensors}:Props) => {
  const [qty, setQty] = useState<number>(0)
  const [selectedRows, setSelectedRows] = useState<Sensor[]>(selectedSensors || []);
  const { t } = useTranslation();


  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Sensor[]) => {

      if(maxSensors && selectedRowKeys.length > maxSensors) return
      setSelectedRows(selectedRows)
      
    },
    getCheckboxProps: (record: Sensor) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };

  const onFinish = (values: any) => {
    onFinishMaster(selectedRows)
  };

  const asignSensors = () => {
    let newAsign:Sensor[] = []
    if(qty>sensors.length){
      setSelectedRows(sensors)

      return
    }
    for (let index = 0; index < qty; index++) {
      newAsign.push(sensors[index])
      
    }
    setSelectedRows(newAsign)
  }

  const columns: ColumnsType<Sensor> = [
    {
      title: t('revendedor.numeroSerie'),
      dataIndex: 'numero_de_serie',
      render: (text: string) => <>{text}</>,
    },
  
  ];
  
  return (
    <div >

    <Form
      name="dynamic_form_item"
      layout="vertical" 
      onFinish={onFinish}
      style={{ maxWidth: 600 }}
    >

      <div className='CreateAddSensorQty-Table  scrollbar-component'>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
            selectedRowKeys: selectedRows.map((row)=>{return row.key}),
          }}

          columns={columns}
          dataSource={sensors}
          pagination={false}
        />
      </div>

      {autoAsign && 
      <div className='CreateAddSensorQty-Generate'>

        <Form.Item
          label=""
          
          style={{width:"18%"}}
          
        >
          <InputNumber max={sensors.length} value={qty} onChange={(value)=>{setQty(value!)}} style={{width:"100%"}} />
        </Form.Item>

        <Form.Item style={{ width: '80%' }}>
          <Button
            type="dashed"

            onClick={() => {
              if(qty>0)asignSensors()
            }}
            style={{ width: '100%' }}
          >
            {t('basico.asignar')} {qty || 0} {t('basico.sensores')}
          </Button>
          <Form.ErrorList />
        </Form.Item>
        </div>}
     



      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" disabled={selectedRows.length===0 && !notRequiredSensors} onClick={(e)=>{ e.stopPropagation() ;onFinishMaster(selectedRows)}} htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>


    </Form>
    </div>
  )
}

export default CreateAddSensorQty