import React from 'react'

//transalate
import { useTranslation, Trans } from 'react-i18next';

//styles
import "./CreateClient.css"
import { Button, Form, Input, Select, Switch } from 'antd'
import { FieldType } from './CreateClientComponent'

interface Props{
  onFinishMaster: (values: any) => void
  defaultValues?:FieldType
}

const CreateClient = ({onFinishMaster, defaultValues}:Props) => {
    const { t } = useTranslation();

    const onFinish = (values: any) => {
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };



  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={defaultValues}
    >
    <div className='CreateClient-Form'>
      {/* <Form.Item  style={{width:"100%"}} label="Ya tengo una franquicia creada" valuePropName="checked">
        <Switch checked={checked} onChange={(event)=>{setChecked(event)}} />
      </Form.Item> */}
        <Form.Item<FieldType>
        label={t('basico.nombre')}
        name="nombre"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.nombreError') }, {max:20, message:t('basico.max20')}]}
        >
        <Input placeholder={t('revendedor.placeHolderNombre')} />
        </Form.Item>    

        <Form.Item<FieldType>
        label={t('basico.descripcion')}
        name="descripcion"
        style={{width:"45%"}}
        
        rules={[{ required: true, message: t('basico.descripcionError') }, {max:30, message:t('basico.max30')}]}
        >
        <Input placeholder={t('revendedor.placeHolderDescripcion')} />
        </Form.Item>

        <Form.Item<FieldType>
        label={t('basico.numeroSensores')}
        name="cantidad_sensores"
        style={{width:"30%"}}
        rules={[{ required: true, message: t('basico.errorCantidad') },{type:"number", min:1, message:t('basico.max1'), transform(value) {
          return Number(value)
        },}]}
        >
        <Input placeholder={t('revendedor.placeHolderCantidad')}   type='number'/>
        </Form.Item>

        <Form.Item<FieldType>
        label={t('basico.correo')}
        name="correo"
        style={{width:"60%"}}

        rules={[{ required: true, message: t('basico.correoError') }, {max:50, message:t('basico.max50')}, {type:'email', message:t('basico.formato')}]}
        >
        <Input placeholder={t('revendedor.placeHolderCorreo')} />
        </Form.Item>
        {/* <Form.Item<FieldType>
        label="Dirección"
        name="direccion"
        style={{width:"100%"}}
        rules={[{ required: true, message: 'Debe escribir dirección' }]}
        >
            <TextArea rows={4} />
        </Form.Item> */}
    </div>

      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>

    </Form>
  )
}

export default CreateClient