import { Skeleton } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import React from 'react'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    loading:boolean
    children?: React.ReactNode
}

const SkeletonSensorBasicTable = ({loading, children}:Props) => {
    const { t } = useTranslation();
    const data=[1,2,3]

    const columns: ColumnsType<any> = [
        {
          title: t('basico.serial'),
          render: (_, record) => (
            <Skeleton.Input active></Skeleton.Input>
  
            ),
        },
        {
            title: t('basico.estatus'),
            render: (_, record) => (
              <Skeleton.Input active></Skeleton.Input>
    
              ),
          },


        ];


  return (
    <>
    {loading ?<Table scroll={{x:100}} columns={columns} dataSource={data} pagination={false} /> :
    children
    }
    </>
  )
}

export default SkeletonSensorBasicTable