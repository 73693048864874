import React, { useContext, useEffect, useState } from 'react'
import { User } from '../../Models/User';

import jwt from 'jwt-decode' 
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { getDataGraph } from '../../Api/Subsidiary';
import { DataGraph } from '../../Models/DataGraph';
import { Card, Tooltip } from 'antd';
import { CartesianGrid, Legend, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';

//transalate
import { useTranslation, Trans } from 'react-i18next';

//styles
import "./SubsidiaryGraph.css"

//colors
import {COLORS} from "../../statics/Colores"
import Spiner from '../Spiner/Spiner';
import { generateTempFormat } from '../../Functions/temp';

//echarts
import ECharts from "./Echarts";

import {
    getBarOptions,
    getPieOptions,
    PieToolbox,
    WordCloudOptions
  } from "./ChartsOptions";

const initialBar= {
    data: [],
    error: false,
    loading: true,
    vertical: false
  };



interface Props{
  enterData?:DataGraph[]
  maxRef?:number
  minRef?:number
}

const SubsidiaryGraph = ({enterData, maxRef, minRef}:Props) => {
    const { t } = useTranslation();
    const {sesion, sendSesion, sendError } = useContext(PromiseSesionContext);
    const [barData, setBarData] = useState(initialBar);
    const [data, setData] = useState<DataGraph[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [dataGraph, setDataGraph] = useState<any>([]);
    const [tabGraph, setTabGraph] = useState<string>("1")
    const [alarmsGraph, setAlarmsGraph] = useState<string[]>([])
    const [sensorsSerials, setSensorsSerials] = useState<string[]>([])

    //recharts

    useEffect(() => {
        if(data.length > 0){
            getBarChart(); // API
        }
      }, [data]);

    function getBarChart() {
          const barOptions = getBarOptions(data[0], [], maxRef, minRef);
          setBarData({
            data: barOptions as any,
            error: false,
            loading: false,
            vertical: false
          });
      }

    const generateData = (inData:DataGraph[],indexNumber?:number ,nameResult?:string,  returnData?:boolean) => {
        const selectedData = indexNumber ? inData[indexNumber] : inData[(Number(tabGraph)-1)]
        
        let result = selectedData.sensorData.map((objeto, index)=>{
            const date = new Date(objeto.timestamp)
            return {name: date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear() + " " + (date.getHours() < 10 ? ("0"+date.getHours()) :date.getHours())+":" + date.getMinutes(), 
                [indexNumber?.toString() ? ("Sensor "+((indexNumber|| 0)+1)) :"Temperatura"]: objeto.temperature}
          })

        let alarms = selectedData.sensorData.filter((objeto)=> objeto.isAlert).map((objeto, index)=>{
            const date = new Date(objeto.timestamp)
            return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear() + " " + date.getHours()+":" + date.getMinutes()})

        if(returnData){
            return {
                alarms : alarms[0],
                result: result
            }
        }
        else{
            setAlarmsGraph(alarms)
            setDataGraph(result)


        }

        
    }

    const generateData2 = (inData:DataGraph[],indexNumber?:number ,  returnData?:boolean, serial?:string) => {
        const selectedData = indexNumber ? inData[indexNumber] : inData[(Number(tabGraph)-1)]
        
        let result = selectedData.sensorData.map((objeto, index)=>{
            const date = new Date(objeto.timestamp)
            return {serial: serial || "sensor",name: date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear() + " " + (date.getHours() < 10 ? ("0"+date.getHours()) :date.getHours())+":" + (date.getMinutes()<10 ? ("0"+date.getMinutes()) :  date.getMinutes()), 
                [indexNumber?.toString() ? (serial || "sensor") :"Temperatura"]: objeto.temperature}
          })

        let alarms = selectedData.sensorData.filter((objeto)=> objeto.isAlert).map((objeto, index)=>{
            const date = new Date(objeto.timestamp)
            return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear() + " " + (date.getHours() < 10 ? ("0"+date.getHours()) :date.getHours())+":" + (date.getMinutes()<10 ? ("0"+date.getMinutes()) :  date.getMinutes())})

        if(returnData){
            return {
                alarms : alarms[0] || null,
                result: result
            }
        }
        else{
            setAlarmsGraph(alarms)
            setDataGraph(result)


        }

        
    }

    const filterData = (inArray:any[], newRegisters:any[], index:number) => {
        let resultArray:any[] = []

        for(let registers of inArray){
            let name = registers.name
            let register = registers

            let element = newRegisters.find( x => x.name == name)
            if(element){
                register[element.serial] = element[element.serial]
            }

            resultArray.push(register)

        }

        return resultArray
    }

    const initGenerateData = async (inData:DataGraph[]) =>{
        let res = await newGenerateData(inData)
        if(res){
            setLoading(false)
        }

    }

    const newGenerateData = async (inData:DataGraph[]) =>{
        let index = 0
        let result = []
        let alarms = []
        setLoading(true)

        let sensorsSerial = []
        for (let d of inData){
            sensorsSerial.push(d.sensorName||d.sensorSerial)
            let generatedData =generateData2(inData, index , true, d.sensorName||d.sensorSerial)

            if(result.length === 0) result.push(... (generatedData?.result || []))
            else result = filterData(result, (generatedData?.result || []), index)

            if(generatedData?.alarms)alarms.push(generatedData?.alarms)
            index=+1
        }
        setSensorsSerials(sensorsSerial)
        setLoading(false)
        setAlarmsGraph(alarms)
        setDataGraph(result)

        return result
        

    }
    

    const getData = async () => {
        try{
            if(enterData){
              
                setData(enterData)
                return
            }
            if (localStorage.getItem("token")) {
              // sendSesion("AUTHORIZED");
              const user:User = jwt(localStorage.getItem("token") || "")
              const res = await getDataGraph(user?.id_entidad)
              if(res.data)setData(res.data)
              
            }
        }
        catch(err){
            sendError({type: "ERROR", data:{message:t('activo.errorGrafico')}})

        }

    }

    const flipDataSensor =  (data:any[])=>{
        let newData = []
    
        for (let index = 0; index < data.length; index++) {
          let element = data[index];
          element.sensorData = element.sensorData.reverse()
    
          newData.push(element)
          
        }
    
        return newData
    
      }

    useEffect(() => {
        if(sesion.context.rol==="Sucursal"){
            getData()
        }
        if(enterData){
          
            setData(enterData)
        }
      }, [sesion.context.rol, enterData]);

    useEffect(() => {
        if(data.length>0){

            // if(enterData) newGenerateData(data)
            if(enterData) initGenerateData(data)

            else generateData(data)
        }
        else{
            setDataGraph([])
        }
    }, [tabGraph, data]);

  const onChangeGraph = (key: string) => {
    setTabGraph(key)
  };

  const customAlarm = (props:any) =>{
    const { cx, cy, stroke, payload, value, key } = props;
    if(alarmsGraph.find((alarm)=> alarm === payload.name)){
        if(cx!== null && cy!== null)
        return<> <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="#4096ff" viewBox="0 0 1024 1024" >
         {/* <circle className='circle-graph'  id="aquiiii" cx={500} cy={470} fill='red'  r={350}  /> */}


         
        {/* <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" /> */}
      </svg>

      <g  transform={`translate(${cx },${cy})`}  >
            <circle className='circle-graph'  id="aquiiii" cx={0} cy={0} fill='red'  r={6}  />

                <rect x={0} className='g-element-graph display-hover-graph' rx={8}></rect>
                <text className='display-hover-graph' x="25" y="15"  fill={stroke} fontSize={10} textAnchor="middle">
                {generateTempFormat(value)}
                </text>
                <text className='display-hover-graph' x="43" y="30"  fill={stroke} fontSize={10} textAnchor="middle">
                {payload.name}
                </text>
            </g>
      </>
    }


    return <></>
}

  return (
        <Spiner loading={loading}>
            {/* <ResponsiveContainer width="100%" height="100%">
                <LineChart
                width={500}
                height={600}
                data={dataGraph}
                margin={{
                    top: 10,
                    right: 5,
                    bottom: 5,}}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[-40,50]} />
                <Tooltip />
                <Legend  />
                {(maxRef||maxRef===0) && <ReferenceArea y1={maxRef} y2={50} label="Maximo" fill='#e99b9b' />}
                {(minRef||minRef===0) && <ReferenceArea y1={minRef} y2={-40} label="Minimo" fill='#aad3e9' />}
                {((minRef && maxRef)||(minRef===0 || maxRef===0)) && <ReferenceArea y1={minRef} y2={maxRef}  fill='#4bd86d' />}
                {

                    data && data.map((d, index) => {
                        return <Line type="monotone" dataKey={sensorsSerials[index]} stroke={COLORS[index]} dot={customAlarm } activeDot={{ r: 8 }} />

                    })
                }
                </LineChart>
            </ResponsiveContainer> */}

        <ECharts
            className={"subsidiaryGraph"}
            loading={barData.loading}
            options={barData.data}
          />
        </Spiner>
        

  )
}

export default SubsidiaryGraph