import React, { useContext, useEffect } from 'react'
import { Sensor } from '../../Models/Sensor'
import Table, { ColumnsType } from 'antd/es/table';
import { PromiseSesionContext } from '../../Machines/SesionMachine';

//transalate
import { useTranslation, Trans } from 'react-i18next';


interface Props {
    sensors: Sensor[]
    rol: string,
    notShowActions?: boolean,
    asignedFuction?: (sensor: Sensor) => string
  }

const TableSensorBasic = ({sensors, rol, asignedFuction, notShowActions}:Props) => {
  const { t } = useTranslation();
  const {sesion, sendSesion } = useContext(PromiseSesionContext);


    const getOwner = ():"reseller" | "organizacion" => {
        switch (sesion.context.rol) {
            case "Proveedor":
                return "reseller"
            case "Reseller":
              return "organizacion"
            case "organization":
              return "reseller"
            case "site":
              return "reseller"
            default:
              return "reseller"
        }
    }

    useEffect(() => {

    }, [sensors]);

    const columns: ColumnsType<Sensor> = [
          {
            title: t('basico.serial'),
            dataIndex: 'numero_de_serie',
            key: 'numero_de_serie',
          },
          {
            title: t('basico.estatus'),
            key: 'asignado',
            render: (_, record) =>{ 
              if(asignedFuction) return <>{asignedFuction(record)}</>
              const owner = record[getOwner()]
              return <>{owner ? (owner.nombre||t('basico.sinAsignar')) : t('basico.sinAsignar')}</>},
          }
        ];

        
  return (
    <Table columns={columns} dataSource={sensors} pagination={false} />
  )
}

export default TableSensorBasic