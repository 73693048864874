import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { MenuItem } from '../../Models/MenuItem'
import ModalMenuItem from '../ModalMenuItem/ModalMenuItem'

interface Props {
    text?: string
    icon?: JSX.Element
    action?: () => void
    menuItem:MenuItem
    updateSize?: boolean
}

const ModalButton = ({text, icon, action, menuItem, updateSize}:Props) => {
  const [open, setOpen] = useState<boolean>(false)
    
  return (
    <>
    <div>
        {text && <span>{text}</span>}

        {icon &&
        <Button type='text' onClick={()=>{setOpen(true)}}>
            {icon}
        </Button>}
    </div>

    <ModalMenuItem updateSize={updateSize} open={open} setOpen={setOpen} menuItem={menuItem} />
    </>
  )
}

export default ModalButton