

export const ENSOLICITUD = {
    solicitud: {
        titulo:"Requests",
        tituloModal1:"Application Sensors",
        tituloModal2:"Reply request",


        errorSolicitudes:"Error loading requests",
        errorAceptarSolicitud:"The request was successfully accepted",

        mensajeAceptado:"Error accepting request",

      }
}

export const ESSOLICITUD = {
    solicitud: {
        titulo:"Solicitudes",
        tituloModal1:"Sensores de la solicitud",
        tituloModal2:"Responder solicitud",


        errorSolicitudes:"Error al cargar las solicitudes",
        errorAceptarSolicitud:"La solicitud fue aceptada correctamente",

        mensajeAceptado:"Error al aceptar la solicitud",
      }
}