import { createContext } from 'react';
import { createMachine } from "xstate";


//models
import { User } from '../Models/User'


export const sesionMachine = createMachine({
    id: "sesionDataFetch",
    initial: "unauthorized",
    context: { user: null, rol:""} as {user: User | null, rol: string},
    states: {
      unauthorized: {
        entry: "deleteUser",
        on: {
            VERIFYYING: { target: "verifying"  },
        },
      },
      verifying: {
        on: {
            AUTHORIZED: { target: "authorized"  },
            UNAUTHORIZED: { target: "unauthorized"  },
        },
      },
      authorized: {
        entry: "changeUser",
        on: {
            UNAUTHORIZED: { target: "unauthorized"  },
        },
      },
    },
  }, 
      /* actions */
  { actions: {
      // action implementations
      changeUser:(context, event) => {
        context.user = event.data.user;
        context.rol = event.data.rol;
      },

      deleteUser:(context, event) => {
        context.user = null;
        context.rol = "";
      }

    }
  });

export const PromiseSesionContext = createContext<any>({});