import React from 'react'
import { Historical } from '../../Models/Historical'
import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import ReportView from '../../Reportes/ReportView'
import { FileAddOutlined } from '@ant-design/icons'
import { MenuItem } from '../../Models/MenuItem'
import Menu from '../Menu/Menu'
import { stringToDate } from '../../Functions/date'

//transalate
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    historical: Historical[]
  }

const TableHistorical = ({historical}:Props) => {
    const { t } = useTranslation();
    const columns: ColumnsType<Historical> = [
        {
          title: t('basico.nombreTarea'),
          dataIndex: 'nombre',
          key: 'nombre',
  
        },
        {
            title: t('basico.estatus'),
            dataIndex: 'estatus',
            key: 'estatus',
            render: (_, record) =>{ 
                
            return <>{record.status ? t('basico.activa') : t('basico.tareaCompletada')}</>},
        },
        {
            title: t('basico.sensor'),
            dataIndex: 'tipo',
            key: 'tipo',
            render: (_, record) =>{ 
                
            return <>{record.sensor_numero_de_serie}</>},

        },

        {
            title: t('basico.alarma'),
            dataIndex: 'tipo',
            key: 'tipo',
            render: (_, record) =>{ 
                
            return <>{record.taskDetails.alarm_count> 0 ? t('basico.conAlarma'): t('basico.sinAlarma')}</>},
            
        },

        {
            title: t('basico.fechaInicio'),
            dataIndex: 'fecha',
            key: 'fecha',
            render: (_, record) =>{ 
                
            return <>{stringToDate(record.taskDetails.started)}</>},
            
        },
        {
            title: t('basico.acciones'),
            dataIndex: 'action',
            key: 'action',
            render: (_, record) =>{ 

                const menuItems:MenuItem[] = [
                    {
                      label: <span>{t('basico.generarReporte')}</span>,
                      key: '0',
                      title: `${t('basico.reporteSensor')}`,
                      icon: <FileAddOutlined />,
                      component: <ReportView serialSensor={"na"} idTask={record?.tarea_id_zebra.toString()} />,
                      type: 'CONTENT',
                      items: [],
                      size: "small",
                      onConfirm: () => {},
                
                    },
                    
                  ]
                
            return <Button type='text'> <Menu noLocation menuItems={menuItems}></Menu></Button>},
        },
        ];

  return (
    <Table scroll={{x:100}} columns={columns} dataSource={historical} pagination={false} />
  )
}

export default TableHistorical