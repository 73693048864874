import React from 'react'
import ReportTempCard from './ReportTempCard'
import { dateToString } from '../Functions/date'
import { generateTempFormat } from '../Functions/temp'
import { useTranslation } from 'react-i18next'

interface Props {
  registers:any
}

const ReportTempList = ({registers}:Props) => {
  const { t } = useTranslation();
  return (
    <div className='ReportTempList ReportLayot'>

      <div className='ReportSub-title'>
        <span>{t('activo.reporteTemperatura')}</span>
      </div> 

      <div className='ReportTempList-list'>
        { registers.map((r:any) =>{ 
        
        var date = new Date(r.timestamp);
        
        return <ReportTempCard text={`${generateTempFormat(r.temperature)} el ${dateToString(date)}`} />})}

      </div>


    </div>
  )
}

export default ReportTempList