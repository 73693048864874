import React, { useContext, useEffect } from 'react'
import { Sensor } from '../../Models/Sensor'
import Table, { ColumnsType } from 'antd/es/table';
import { PromiseSesionContext } from '../../Machines/SesionMachine';
import { TagOutlined } from '@ant-design/icons';
import { MenuItem } from '../../Models/MenuItem';
import ModalButton from '../ModalButton/ModalButton';
import { BsXLg } from 'react-icons/bs';
import RemoveSensor from '../RemoveSensor/RemoveSensor';

//transalate
import { useTranslation, Trans } from 'react-i18next';


interface Props {
    sensors: Sensor[]
    rol: string,
    notShowActions?: boolean,
    asignedFuction?: (sensor: Sensor) => string
  }

const TableSensorReseller = ({sensors, rol, asignedFuction, notShowActions}:Props) => {
  const { t } = useTranslation();
  const {sesion, sendSesion } = useContext(PromiseSesionContext);


    const getOwner = ():"reseller" | "organizacion" => {
        switch (sesion.context.rol) {
            case "Proveedor":
                return "reseller"
            case "Reseller":
              return "organizacion"
            case "organization":
              return "reseller"
            case "site":
              return "reseller"
            default:
              return "reseller"
        }
    }

    useEffect(() => {

    }, [sensors]);

    const columns: ColumnsType<Sensor> = [
          {
            title: t('basico.serial'),
            dataIndex: 'numero_de_serie',
            key: 'numero_de_serie',
          },
          {
            title: t('basico.estatus'),
            key: 'asignado',
            render: (_, record) =>{ 
              if(asignedFuction) return <>{asignedFuction(record)}</>
              const owner = record[getOwner()]
              return <>{owner ? (owner.nombre||t('basico.sinAsignar')) :  t('basico.sinAsignar')}</>},
          },
          {
            title: t('basico.remover'),
            key: 'action',
            
            render: (_, {numero_de_serie, sucursal, organizacion}) =>{ 
              if(!organizacion) return <></>
              
              let menuItem:MenuItem =   {
                label: <span>Remover sensor</span>,
                title: t('basico.removerSensor'),
                key: '1',
                icon: <TagOutlined />,
                component: <RemoveSensor serialSensor={numero_de_serie}  />,
                type: 'CONTENT',
                items: [],
                size: 'small',
                onConfirm: () => {},
          
              }
      
              return <ModalButton menuItem={menuItem} text={""}  icon={<BsXLg />}></ModalButton>},}
        ];

        
  return (
    <Table columns={columns} dataSource={sensors} pagination={false} />
  )
}

export default TableSensorReseller