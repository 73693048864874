import { Button, Form, Input, Select } from 'antd';
import React from 'react'

//transalate
import { useTranslation } from 'react-i18next';
import ImageSelect from '../ImagesSelect/ImageSelect';

interface Props{
    onFinishMaster: (values: any) => void
    initialValues?: FieldType
}

export type FieldType = {
  nombre?: string;
  descripcion?: number;
  tipo?:string;
  modo?:string
  icono?:string
};

const CreateAsset = ({onFinishMaster, initialValues}:Props) => {
    const { t } = useTranslation();
    const [icon, setIcon] = React.useState<string>("Nevera");

    const onFinish = (values: any) => {
        values.tipo = icon
        onFinishMaster(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

  return (
    <Form name="form_item_path" 
    layout="vertical" 
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    initialValues={initialValues}
    >
    <div className='CreateClient-Form'>
      {/* <Form.Item  style={{width:"100%"}} label="Ya tengo una franquicia creada" valuePropName="checked">
        <Switch checked={checked} onChange={(event)=>{setChecked(event)}} />
      </Form.Item> */}

        <Form.Item<FieldType>
        label={t('basico.nombre')}
        name="nombre"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.errorNombre') }, {max:20, message:t('basico.max20')}]}
        >
        <Input placeholder={t('activo.placeHolderNombre')} />
        </Form.Item>    

        <Form.Item<FieldType>
        label={t('basico.descripcion')}
        name="descripcion"
        style={{width:"45%"}}
        rules={[{ required: true, message: t('basico.descripcionError') }, {max:30, message:t('basico.max30')}]}
        >
        <Input placeholder={t('activo.placeHolderDescripcion')} />
        </Form.Item> 

        <Form.Item<FieldType>
        label={t('basico.modo')}
        name="modo"
        style={{width:"100%"}}
        rules={[{ required: true, message: t('basico.errorModo') }]}
        >
        <Select placeholder={t('activo.placeHolderModo')}>
          <option value={"Fijo"} >{t('basico.fijo')}</option>
          <option value={"Movil"} >{t('basico.movil')}</option>
        </Select>
        </Form.Item> 

    </div>

    <Form.Item<FieldType>
        label={t('basico.icono')}
        initialValue={"nevera"}
        name="icono"
        style={{width:"45%"}}
        >
        <ImageSelect icon={icon} setIcon={setIcon}></ImageSelect>

      </Form.Item> 

      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button type="primary" htmlType="submit">
          {t('basico.siguiente')}
        </Button>
      </div>

    </Form>
  )
}

export default CreateAsset