import { Card, Pagination } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import FilterSimple from '../../Components/FilterSection/FilterSimple'
import { MenuItem } from '../../Models/MenuItem'
import TableApikey from '../../Components/TableApikey/TableApikey'
import AsignApiKeyReseller from '../../Components/AsignApiKeyReseller/AsignApiKeyReseller'
import { getApiKeys } from '../../Api/Supplier'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import { Apikey } from '../../Models/Apikey'
import { getApiKeysReseller } from '../../Api/Reseller'
import TableResellerApikey from '../../Components/TableApikey/TableResellerApikey'
import ApikeyFilters from '../../Components/ApikeyFilters/ApikeyFilters'

//transalate
import { useTranslation, Trans } from 'react-i18next';

import "./Apikeys.css"

const Apikeys = () => {
  const [loading, setLoading] = useState(false);
  const {error, sendError} = useContext(PromiseSesionContext);
  const {sesion, sendSesion } = useContext(PromiseSesionContext);
  const [filters, setFilters] = useState<{ nombre:string }>({nombre:""}); //filters

  const [apikey, setApikey] = useState<Apikey[]>([]);
  const [apikeyFilter, setApikeyFilter] = useState<Apikey[]>([]);

  const { t } = useTranslation();

  //paginate
  const [page, setPage] = useState<number>(1)
  const sizePages = 10

  const getData = async () => {
    try{
    setLoading(true)

    let newApikeys:Apikey[] = []
    switch (sesion.context.rol) {
      case "Proveedor":
        newApikeys = await getApiKeys()
        break;
      case "Reseller":
        newApikeys = await getApiKeysReseller()
        break;
      default:
        break;
  }

      setApikeyFilter(newApikeys)
      setApikey(newApikeys)
    // setResellers(res)
    // setResellersFilters(res)
    setLoading(false)

    }
    catch(err){
      sendError({type: "ERROR", data:{message:t('apikey.errorCarga')}})
      setLoading(false)
    }

  }

  useEffect(() => {
    getData();
  }, []);

  const items=[
    {
      title: t('basico.informacion'),
    },
    {
      title: t('basico.detalle'),
    },
  ]

    const menuItem:MenuItem ={
        label: <span>Crear</span>,
        key: '0',
        title: 'Crear Cliente',
        icon: <></>,
        component: <AsignApiKeyReseller />,
        type: 'MULTICONTENT',
        items: items,
        size: 'small',
        onConfirm: () => {},
      }

  const filterApikeys = ()=>{
    if(filters.nombre!=""){
      setApikeyFilter(apikey.filter((x:Apikey) => {
        if(x.reseller_nombre){
          return x.reseller_nombre.toUpperCase().includes(filters.nombre.toUpperCase())
        }
        if(x?.org_nombre){
          return x?.org_nombre.toUpperCase().includes(filters.nombre.toUpperCase())
        }
         }))
    }
    else{
      setApikeyFilter(apikey)
    }
  }
  useEffect(() => {
    if(apikey.length>0){
      filterApikeys()
    }
  }, [filters]);
      
  return (
    <div>
        <Card title={t('apikey.titulo')} style={{height:"calc(100vh - 2rem)"}} bodyStyle={{padding:0, height:"82%", boxSizing:"border-box"}} extra={sesion.context.rol==="Proveedor" && <FilterSimple  menuItem={menuItem} title={t('basico.asignar')}/>}>
          {/* <SensorFilters notShowAsign={(sesion.context.rol==="Organizacion" || sesion.context.rol==="Sucursal") ? true : false} filters={filters} setFilters={setFilters} /> */}
           <ApikeyFilters filters={filters} setFilters={setFilters} />
            <div className='Layout-Card-Paginate'>

              {/* <UsersFilters filters={filters} setFilters={setFilters} /> */}
              {/* users={usersFilter?.slice((page - 1) * sizePages, page * sizePages)} */}
              <div className='apikey-table'>
                {sesion.context.rol==="Proveedor" && <TableApikey apikeys={apikeyFilter?.slice((page - 1) * sizePages, page * sizePages)} />}

                {sesion.context.rol==="Reseller" && <TableResellerApikey apikeys={apikeyFilter?.slice((page - 1) * sizePages, page * sizePages)} />}
              </div>
                <div className='centerPaginate'>
                    {apikeyFilter.length>sizePages &&  <Pagination defaultCurrent={1} onChange={(newPage, newPageSize)=>{setPage(newPage)}} current={page} total={apikeyFilter.length} pageSize={sizePages} showSizeChanger={false} />}
                </div> 

            </div>

        </Card>
    </div>
  )
}

export default Apikeys