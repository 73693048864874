import { Button, Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useContext } from 'react'
import { PromiseSesionContext } from '../../Machines/SesionMachine'
import Spiner from '../Spiner/Spiner'
import { patchConfirmRequest, patchRejectRequest } from '../../Api/Supplier'

interface Props {
    response:{ status: boolean, id:number|null, idReseller:number|null}
    onClose: () => void
}

const ResponseRequest = ({response, onClose}:Props) => {
    const {sendError} = useContext(PromiseSesionContext);
    const [loading, setLoading] = React.useState<boolean>(false);


    const confirmRequestMethod = async(values:any) =>{
        try{
            setLoading(true)
            const res = await patchRejectRequest(response.id || 0, values)
            setLoading(false)
            sendError({type: "ERROR", data:{message:"La solicitud fue rechazada correctamente", type:"success"}})
            onClose()

          }
          catch(err){
            sendError({type: "ERROR", data:{message:"Error al aceptar la solicitud"}})
            setLoading(false)
          }
    }

    const onFinish = (values: any) => {
        confirmRequestMethod(values)
      };
      
    const onFinishFailed = (errorInfo: any) => {
      };

    type FieldType = {
        comentarios?: string;
    };

  return (
    <div>
        <Spiner>
            <Form name="form_item_path" 
            layout="vertical" 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            >
                <Form.Item<FieldType>
                name="comentarios"
                rules={[{ required: true, message: 'Debe escribir una descripción' }]} 
                label={`Razón para ${response.status===true ? "aceptar" : "rechazar"} la solicitud`}>
                    <TextArea rows={4} />
                </Form.Item>

                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop:"10px"}}>
                    <Button type="primary" htmlType="submit">
                    Siguiente
                    </Button>
                </div>
            </Form>
        </Spiner>
    </div>
  )
}

export default ResponseRequest