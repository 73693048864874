import {Node} from './Node'

export class Graph {
    nodes!: Node[]

    constructor(nodes:Node[] ){
        this.nodes = nodes
    }

    isEmpty(){
      return (this.nodes.length == 0)
    }
}